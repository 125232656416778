import React, { useState, useRef, useEffect } from 'react'
import { Form, Button, Image } from 'react-bootstrap';
import Sidebar from '../../../sidebar/Sidebar';
import './CreateProfile.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate,Link } from 'react-router-dom';
import Camera from '../../../../assets/Images/Camera.svg'
import Upload_icon from '../../../../assets/Images/Upload_icon.svg'
import { useSelector, useDispatch } from "react-redux"
import { getUserProfile } from '../../../../redux/authSlice';

export default function CreateProfile() {
    const dispatch = useDispatch()

    const [username, setusername] = useState('');
    const [email, setemail] = useState('');
    const [firstname, setfirstname] = useState('');
    const [dob, setdob] = useState('');
    const [gender, setgender] = useState('');
    const [address, setaddress] = useState('');
    const [city, setcity] = useState('');
    const [zipcode, setzipcode] = useState('');
    const [state, setstate] = useState('');
    const [country, setcountry] = useState('');
    const userdata = useSelector((state) => state.user.userdata);
    useEffect(() => {
        dispatch(getUserProfile())
        setusername(userdata.username)
        setemail(userdata.email)
        setfirstname(userdata.first_name)
        setdob(userdata.dob)
        setgender(userdata.gender)
        setaddress(userdata.address)
        setcity(userdata.city)
        setstate(userdata.state)
        setcountry(userdata.country)
        setzipcode(userdata.zipcode)
    }, [userdata]);

    let navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {

        const file = event.target.files[0];
        if (file) {
            ;
            setSelectedImage(URL.createObjectURL(file));
        } else {
            setSelectedImage(null);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className='profileclass'>
                <div className="profile-container">
                    <span className='profiletext'>Profile</span>
                </div>

                <div className="table-container">
                    <div className='form1'>
                        <div className=" col-sm-8">
                            <div className="row ">
                                <div className='header-profile'>
                                    <div >
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleImageUpload}
                                        />
                                        {!selectedImage && <Image src={Upload_icon} alt="Image" style={{ width: '70px', height: '70px', cursor: 'pointer' }} onClick={handleButtonClick} />}

                                        {selectedImage && <Image src={selectedImage} alt="User Photo" roundedCircle style={{ width: '70px', height: '70px', cursor: 'pointer' }} />}
                                        {selectedImage && <span className="e-badge e-badge-info e-badge-notification e-badge-overlap cameraimage" ><Image src={Camera} alt="Image" style={{ width: '26px', height: '30px', cursor: 'pointer' }} onClick={handleButtonClick} /></span>}
                                        <span className='profiletextcss'>{username}</span>
                                    </div>

                                    <div style={{ marginLeft: 'auto' }}>
                                        <Button className='quotebtncss px-4 mx-2' type="submit" onClick={() => navigate('/changepassword')}>CHANGE PASSWORD</Button>
                                        <Link  to={`/editprofile`} state={{ userdata }}>

                                        <Button className='quotebtncss px-4' type="submit" onClick={() => navigate('/editprofile')}>EDIT</Button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Full Name"
                                            value={firstname}
                                            disabled
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Street Address</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={address}
                                            placeholder="Enter Street Address"
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Username"
                                            disabled
                                            value={username}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={city}
                                            placeholder="Enter City"
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Email"
                                            disabled
                                            value={email}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={zipcode}
                                            placeholder="Enter Zip Code"
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={dob}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={state}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            disabled
                                            value={gender}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            value={country}
                                            disabled
                                            placeholder="Enter Country"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>



        </div>
    )
}
