import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import facebook from '../../assets/Images/facebook.svg'
import { Card, Button, Form, Image } from "react-bootstrap";
const FacebookLoginButton = ({ onFacebookLogin }) => {
  const responseFacebook = (response) => {
    onFacebookLogin(response.accessToken);
  };

  return (

    <FacebookLogin
      appId="934716960973028"
      autoLoad={false}
      fields="name,picture"
      scope="email"
      callback={responseFacebook}

      render={(renderProps) => (
        <Image src={facebook} onClick={renderProps.onClick} alt="Image" className='lgimage' />

      )}
    />
  );
};

export default FacebookLoginButton;

// import React, { useEffect, useState } from 'react';
// import facebook from '../../assets/Images/facebook.svg'
// import { Card, Button, Form, Image } from "react-bootstrap";
// import { signinuser, facebookLogin, googleLogin } from '../../redux/authSlice';
// import { useSelector, useDispatch } from "react-redux"
// function FacebookLoginButton() {
//   const dispatch = useDispatch()
//   const [accessToken, setaccessToken] = useState("");
//   useEffect(() => {
//     // Load the Facebook SDK asynchronously
//     window.fbAsyncInit = function () {
//       window.FB.init({
//         appId: '934716960973028',
//         cookie: true,
//         xfbml: true,
//         version: 'v14.0'
//       });
//     };

//     (function (d, s, id) {
//       var js, fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) return;
//       js = d.createElement(s); js.id = id;
//       js.src = "https://connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     }(document, 'script', 'facebook-jssdk'));
//   }, []);

//   const handleLogin = () => {
//     window.FB.login(
//       response => {
//         if (response.authResponse) {

//           setaccessToken(response.authResponse.accessToken);
//           // User is logged in, you can make a request to your backend API here
//           ;
//           const body = {
//             access_token: accessToken
//           }

//           let data1 = JSON.stringify(body)
//           dispatch(facebookLogin(data1))
//             .then(response => response.json())
//             .then(data => {
//               // Handle the response from the backend
//               ;
//             })
//             .catch(error => {
//               // Handle any errors
//               console.error('Error:', error);
//             });
//         } else {
//           // User cancelled the login process
//           ;
//         }
//       },
//       { scope: 'public_profile,email' } // Request email permission
//     )
//   };



//   return (
//     <>
//       {/* <button onClick={handleLogin}>Login with Facebook</button>
      
//       <button onClick={logout}>logout</button> */}

//       <Image src={facebook} alt="Image" style={{ height: '24px', cursor: 'pointer' }} onClick={() => {
//         handleLogin()
//       }} />
//     </>

//   );
// }

// export default FacebookLoginButton;
