import React, { useState, useEffect } from 'react'
import Sidebar from '../../sidebar/Sidebar';
import { Form, Button, Image } from 'react-bootstrap';
import './CreateContracts.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import plusicon from '../../../assets/Images/plus-icon.svg'
import { Link, useNavigate, useParams } from 'react-router-dom';
import minus from '../../../assets/Images/minus.svg';
import download from '../../../assets/Images/download.svg';
import { getProjectList } from '../../../redux/project';
import { useDispatch } from 'react-redux';
import { getContractor } from '../../../redux/quotation';
import { getContractById } from '../../../redux/contract';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
export default function CreateContracts() {
  const [projectListdata, setProjectList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [contractorlist, setcontractorlist] = useState([]);
  const [showComment, setComment] = useState(false);
  const [project, setproject] = useState('');
  const [contractor, setcontractor] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [fob, setfob] = useState('');
  const [loadnumber, setloadnumber] = useState('');
  const [errors, setErrors] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch()
  const [rows, setRows] = useState([{ id: 1, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD" }]);
  const [nextId, setNextId] = useState(2);
  useEffect(() => {

    dispatch(getProjectList())
      .then((result) => {
        setProjectList(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

    dispatch(getContractor())
      .then((result) => {

        setcontractorlist(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

    dispatch(getContractById(id))
      .then((result) => {
        const data = result.payload.data;
        ;
        if (data) {
          setcontractor(data.contractor.id)
          setproject(data.project.id)
          setRows(data.products)
          setStartDate(data.start_date);
          setEndDate(data.end_date);
          // setfob(data.fob);
          // setloadnumber(data.load_number)

        }
      })



  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let navigate = useNavigate();
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    ;
  };




  const handleAddRow = () => {
    const newRow = { id: nextId, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD" };
    setRows([...rows, newRow]);
    setNextId(nextId + 1);
  };

  const handleRemoveRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };


  const [validated, setValidated] = useState(false);

  const handleunitChange = (id, unit) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, unit } : row
    );

    setRows(updatedRows);
  };

  const handleRowChange = (id, fieldName, value) => {

    const updatedRows = rows.map((record) => {
      if (record.id === id) {
        return { ...record, [fieldName]: value };
      }
      return record;
    });

    setRows(updatedRows);
    const validationErrors = { ...errors };
    if (validationErrors[id] && validationErrors[id][fieldName]) {
      delete validationErrors[id][fieldName];
      if (Object.keys(validationErrors[id]).length === 0) {
        delete validationErrors[id];
      }
      setErrors(validationErrors);
    }
  };

  const addcomment = () => {
    setComment(true);
  }

  const handlestartDateChange = (date) => {
    setStartDate(date.target.value);
  };
  const handleendDateChange = (date) => {
    setEndDate(date.target.value);
  };
  const contractorSelect = (event) => {
    setcontractor(event.target.value);
  };
  const projectSelect = (event) => {
    setproject(event.target.value);
  };

  const handleloadnumChange = (event) => {
    setloadnumber(event.target.value)
    if (errors.loadnumber) {
      const updatedErrors = { ...errors };
      delete updatedErrors.loadnumber;
      setErrors(updatedErrors);
    }
  }

  const handlefobChange = (event) => {
    setfob(event.target.value)

    if (errors.fob) {
      const updatedErrors = { ...errors };
      delete updatedErrors.fob;
      setErrors(updatedErrors);
    }
  }


  let contractValue = {
    "quote": "",
    "fob": "",
    "load_number": ""

  }

  const handleFormSubmit = (event) => {

    event.preventDefault();
    const validationErrors = {};
    const pattern = /^[a-zA-Z0-9]+$/;
    if (!fob) {
      validationErrors.fob = 'Please enter fob';
    }

    if (!loadnumber) {
      validationErrors.loadnumber = 'Please enter load number';
    }else if (pattern.test(loadnumber)) {
      contractValue.load_number = loadnumber;
    } else {
      validationErrors.loadnumber = 'Only letters and numbers are allowed.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }





    contractValue.quote = id;
    contractValue.fob = fob;
    contractValue.load_number = loadnumber;


    let config = {
      method: "POST",
      url: `https://cold-hat-40370.botics.co/contracts/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
      data: contractValue
    }

    axios
      .request(config)
      .then(response => {

        toast.success('Contract Created successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
        navigate("/contractdashboard")
      })
      .catch(error => {
        toast.error('Error while saving data ', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
      })
  }


  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <ToastContainer />
      <div className='mainContainer'>

        <div className="header-container">
          <span className='headertext'>Create Contract</span>

          <button
            className="px-3 ms-5 mb-2"
            style={{
              border: "1px solid #FF731D",
              color: "#FFFF",
              backgroundColor: "#FF731D",
              borderRadius: "30px",
            }}

            onClick={() => navigate("/contractdashboard")}
          >
            All Contracts
          </button>
        </div>

        <div className="table-container">
          <div className='form1'>
            <div className="container">
              <div className="row col-sm-9 templatecss">
                <span className='headertext1'>Please upload the contract</span>

              </div>
              <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-sm">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Contractor</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        disabled
                        value={contractor}
                        onChange={contractorSelect}
                        className='textcontainer headertext2'
                      >
                        <option value="">Select Contractor</option>

                        {contractorlist.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.username}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-sm">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Project name</Form.Label>

                      <Form.Control
                        as="select"
                        required
                        disabled
                        value={project}
                        className='textcontainer headertext2'
                        onChange={projectSelect}
                      >
                        <option value="">Select Project Name</option>
                        {projectListdata.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>

                    </Form.Group>
                  </div>
                </div>

                {rows.map((row, index) => (
                  <div key={row.id} className='mt-3'>
                    <div className="row p-2" style={{ background: '#F2EEEE' }}>
                      <div className="col-sm">
                        <Form.Label className="text-start labelcss" >Products
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={row.name}
                          placeholder="Enter Products"
                          required
                          onChange={(e) => handleRowChange(row.id, 'name', e.target.value)}
                          maxLength={30}
                        />
                        <Form.Control.Feedback type="invalid">Please enter products</Form.Control.Feedback>

                      </div>
                      <div className="col-sm">
                        <Form.Label className="text-start labelcss" >Quantity
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={row.quantity}
                          placeholder="Enter Quantity"
                          required
                          onChange={(e) => handleRowChange(row.id, 'quantity', e.target.value)}
                          maxLength={30}
                        />
                        <Form.Control.Feedback type="invalid">Please enter quantity</Form.Control.Feedback>
                      </div>
                      <div className="col-sm">
                        <Form.Label className="text-start labelcss" >Price
                        </Form.Label>
                        <Form.Control
                          type='text'
                          disabled
                          value={row.price}
                          required
                          maxLength={30}
                          placeholder="Enter Price"
                          onChange={(e) => handleRowChange(row.id, 'price', e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">Please enter price</Form.Control.Feedback>
                      </div>
                      <div className="col-sm">
                        <Form.Group controlId="exampleForm.SelectCustom">
                          <Form.Label>Unit</Form.Label>

                          <Form.Control
                            as="select"
                            disabled
                            required
                            className='headertext2' value={row.unit}
                            onChange={(e) => handleunitChange(row.id, e.target.value)}
                          >
                            <option value="">Select Unit</option>
                            <option value="ton">ton</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">Please select unit</Form.Control.Feedback>
                        </Form.Group>

                      </div>
                      <Form.Label className="text-start labelcss" style={{ marginTop: '2%' }}>Terms
                      </Form.Label>
                      <div className="container ">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" value={row.terms} rows={2} required disabled />
                          <Form.Control.Feedback type="invalid">Please enter terms</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    {rows.length > 1 && (
                      <Image className="pb-1 ms-1" src={minus} onClick={() => { handleRemoveRow(row.id) }} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px' }} />
                    )}
                  </div>
                ))}

                <Image src={plusicon} onClick={() => { handleAddRow() }} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px' }}></Image>


                <div className='row col-md-6'>
                  <div className='col-sm-6 startdate' >
                    <Form.Label className="text-start labelcss" >Start Date
                    </Form.Label>
                    <Form.Control
                      type='date'
                      className='textcontainer'
                      placeholder="Enter Zip Code"
                      name='startdate'
                      selected={StartDate}
                      onChange={handlestartDateChange}
                      defaultValue={StartDate}
                      required
                      disabled
                    />
                  </div>
                  <div className='col-sm-6 startdate' >
                    <Form.Label className="text-start labelcss" >End Date
                    </Form.Label>
                    <Form.Control
                      type='date'
                      className='textcontainer'
                      onChange={handleendDateChange}
                      placeholder="Enter Zip Code"
                      name='enddate'
                      defaultValue={EndDate}
                      selected={EndDate}
                      required
                      disabled
                    />
                  </div>
                </div>

                <div className='row col-sm mt-3'>
                  <div className="col-sm">
                    <Form.Label className="text-start labelcss" >FOB
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter FOB"
                      className='textcontainer'
                      value={fob}
                      onChange={handlefobChange}
                      isInvalid={!!errors.fob}
                      required
                      maxLength={30}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fob}</Form.Control.Feedback>

                  </div>
                  <div className="col-sm">
                    <Form.Label className="text-start labelcss" >Load Number
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Load Number"
                      className='textcontainer'
                      required
                      isInvalid={!!errors.loadnumber}
                      onChange={handleloadnumChange}
                      value={loadnumber}
                      maxLength={30}
                    />
                    <Form.Control.Feedback type="invalid">{errors.loadnumber}</Form.Control.Feedback>
                  </div>
                </div>

                <div style={{ marginTop: '2%', marginBottom: '2%' }}>
                  <Button className='submitbtncss' type="submit" >Send Contract</Button>
                </div>
              </Form>

            </div>
          </div>

          <div className='form2'>
            <div className="container" style={{ height: '75vh' }}>

              <div className='textcontainer p-2 ' style={{ height: 'inherit' }}>
                <div className="container">
                  <span className='headertext1'>Contract history</span>
                  {/* <div className='textcontainer1 '>

                    <div class="container">
                      <div class="row">
                        <div class="col-sm-9">
                          Version1
                          <Image src={download} className="downloadimage" />


                        </div>
                        <div class="col-sm-3">
                          13-06-2023
                          11:32 am
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



