import React, {useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import {Button, Col, Form, Image, Modal, Row,} from "react-bootstrap";
import {getProjectList} from "../../redux/project";
import {useNavigate} from "react-router-dom";
import "./ContractorQuotesDashboard.css";
import plusicon from "../../assets/Images/plus-icon.svg";
import minus from "../../assets/Images/minus.svg";
import {getAllQuotation, searchByProject} from "../../redux/quotation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass,} from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import {createContractorQuotation, getSupplier,} from "../../redux/contractorQuotation";

import {useDispatch} from "react-redux";

const ContractorQuotesDashboard = () => {
 const [searchText, setSearchText] = useState("");
 const [searchName, setSearchName] = useState("");
 const [sortColumn, setSortColumn] = useState("");
 const [sortDirection, setSortDirection] = useState("asc");
 const [expandedRow, setExpandedRow] = useState(null);
 const [show, setShow] = useState(false);
 const [showProject, setShowProject] = useState(false);
 const [supplier, setSupplier] = useState([]);
 const [projectName, setProjectName] = useState("");
 const [startDate, setStartDate] = useState("");
 const [errors, setErrors] = useState("");
 const [endDate, setEndDate] = useState("");
 const [supplierList, setSupplierList] = useState([]);
 const [projectList, setProjectList] = useState([]);
 const [ProjectNameN, setProjectNameN] = useState('');

 const [rows, setRows] = useState([
  {
   id: "",
   name: "",
   quantity: "",
   price: "",
   unit: "",
   terms: "",
   currency: "",
  },
 ]);
 // const [nextId, setNextId] = useState(2);

 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 let navigate = useNavigate();
 const dispatch = useDispatch();
 const [quotationList, setquotationList] = useState([]);

 const [rowsProductData, setRowsProductData] = useState([
  {name: "", quantity: 0, unit: ""},
 ]);

 const formatDateToYYMMDD = (dateString) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
 };


 let acceptBody = {
  "accept_reject": true
 }
 const handleAcceptQuoteRequest = async (id, _event) => {
  _event.stopPropagation()
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/${id}/accept-reject`, {
   method: "PUT",
   headers: {
    'Content-Type': 'application/json',
    'Authorization': `token ${localStorage.getItem('token')}`
   },
   body: JSON.stringify({
    "accept_reject": true
   })
  })
  if (response.ok) {
   toast.success('Quote accepted successfully', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    hideProgressBar: true,
   });
   GetAllQuotation()
  }
  return await response.json()
 }
 let body =
  {
   "suppliers_id": supplier?.map((item) => item?.id),
   "project": projectName,
   "products_data": rowsProductData,
   "start_date": formatDateToYYMMDD(startDate),
   "end_date": formatDateToYYMMDD(endDate),
   "requested_by_contractor": true
  }

 const GetAllQuotation = () => {
  dispatch(getAllQuotation("https://cold-hat-40370.botics.co/quote/"))
   .then((result) => {
    setquotationList(result.payload.data.results);
   })
 }
 useEffect(() => {
  dispatch(getSupplier())
   .then((result) => {
    setSupplierList(result.payload.data);
   })
   .catch((error) => {
    console.log(error);
   });

  dispatch(getProjectList())
   .then((result) => {
    setProjectList(result.payload.data);
    // setnext1(result.payload.data.next)
    // setprevious(result.payload.data.previous)
   })
   .catch((error) => {
    console.log(error);
   });
  GetAllQuotation()

 }, []);

 const addProductRows = () => {
  const rowsInputProduct = {
   name: "",
   quantity: "",
   unit: "",
  };
  setRowsProductData([...rowsProductData, rowsInputProduct]);
 };
 const deleteTableRows = (index) => {
  const rows = [...rowsProductData];
  rows.splice(index, 1);
  setRowsProductData(rows);
 };
 const handleChange = (event, index) => {
  const {name, value} = event.target;
  const rowsInput = [...rowsProductData];
  rowsInput[index][name] = value;
  setRowsProductData(rowsInput);
 };

 const contractorRequestQuote = (e) => {
  e.preventDefault();
  dispatch(createContractorQuotation(body));
  setShow(false)
 };
 const isNameValid = (inputName) => {
  const nameRegex = /^(?=.*[a-zA-Z]).+$/;
  return nameRegex.test(inputName);
 };
 let projectValue = {
  "data": {
   "name": '',
  },
 }
 const handleNameChange = (event) => {
  const inputName = event.target.value;

  setProjectNameN(inputName);

  if (errors.ProjectNameN && isNameValid(inputName)) {
   const updatedErrors = {...errors};

   delete updatedErrors.ProjectNameN;

   setErrors(updatedErrors);
  }


 };

 const handleSearch = (e) => {
  setSearchText(e.target.value);

  dispatch(searchByProject(e.target.value))
   .then((result) => {

    const data = result.payload.data.results;

    setquotationList(result.payload.data.results);
   })
   .catch((errordata) => {

   });
 };

 const handleDropdownChange = (e) => {
  setSearchName(e.target.value);

  dispatch(searchByProject(e.target.value))
   .then((result) => {

    const data = result.payload.data.results;

    setquotationList(result.payload.data.results);
   })
   .catch((errordata) => {

   });
 };

 const handleSort = (column) => {
  if (sortColumn === column) {
   setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  } else {
   setSortColumn(column);
   setSortDirection("asc");
  }
 };

 const handleExpand = (id) => {
  if (expandedRow === id) {
   setExpandedRow(null);
  } else {
   setExpandedRow(id);
  }
 };

 // Add new product through request quote + icon start here

 const [addProduct, setAddProduct] = useState([
  {id: 1, project_name: "", name: "", quantity: "", unit: ""},
 ]);
 const [nextId, setNextId] = useState(2);

 const handleAddProduct = () => {
  const newProduct = {
   id: nextId,
   project_name: "",
   name: "",
   quantity: "",
   unit: "",
  };
  setAddProduct([...addProduct, newProduct]);
  setNextId(nextId + 1);
 };


 const handleRemoveProduct = (id) => {
  const updatedProducts = addProduct.filter((row) => row.id !== id);
  setAddProduct(updatedProducts);
 };

 //   check for error here with the handleproductchange or handle projectchange
 const handleProjectChange = (id, name) => {
  const updatedProducts = addProduct.map((row) =>
   row.id === id ? {...row, name} : row
  );
  setAddProduct(updatedProducts);
 };

 const handleQuantityChange = (id, quantity) => {
  const updatedProducts = addProduct.map((row) =>
   row.id === id ? {...row, quantity} : row
  );
  setAddProduct(updatedProducts);
 };

 const handleProductsChange = (id, products) => {
  const updatedProducts = addProduct.map((row) =>
   row.id === id ? {...row, products} : row
  );
  setAddProduct(updatedProducts);
 };

 const handleUnitChange = (id, unit) => {
  const updatedProducts = addProduct.map((row) =>
   row.id === id ? {...row, unit} : row
  );
  setAddProduct(updatedProducts);
 };

 // Add new product finishes here
 const handleFormSubmit = (event) => {

  event.preventDefault();

  const validationErrors = {};

  if (!isNameValid(ProjectNameN)) {

   validationErrors.ProjectNameN = "Please enter a valid project name.";

  }

  if (Object.keys(validationErrors).length > 0) {
   setErrors(validationErrors);

   return;
  }

  if (Object.keys(validationErrors).length === 0) {
   setProjectNameN("");
   setErrors("");

  }

  projectValue.data.name = ProjectNameN;
  let config = {
   method: "post",
   url: 'https://cold-hat-40370.botics.co/project/',
   headers: {
    'Content-Type': 'application/json',
    'Authorization': `token ${localStorage.getItem('token')}`
   },
   data: projectValue.data
  }

  axios
   .request(config)
   .then(response => {

    toast.success('Project created successfully', {
     position: toast.POSITION.TOP_RIGHT,
     autoClose: 2000,
     hideProgressBar: true,
    });

    dispatch(getProjectList())
     .then((result) => {
      setProjectList(result.payload.data);
     })
     .catch((error) => {
      console.log(error)
     });

    setShowProject(false)
   })
   .catch(error => {

    toast.error('Error while saving data', {
     position: toast.POSITION.TOP_RIGHT,
     autoClose: 2000,
     hideProgressBar: true,
    });
    console.log(error)
   })
 }
 const filteredData = quotationList.filter((item) => {
  if (searchName) {
   return item.project_name === searchName;
  } else {
   return (
    item.project_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
    item.project_id?.toString().includes(searchText?.toString())
   );
  }
 });

 const sortedData = filteredData.sort((a, b) => {
  if (sortColumn === "name") {
   return sortDirection === "asc"
    ? a.name.localeCompare(b.name)
    : b.name.localeCompare(a.name);
  } else if (sortColumn === "age") {
   return sortDirection === "asc" ? a.age - b.age : b.age - a.age;
  } else {
   return 0;
  }
 });

 return (
  <div style={{display: "flex"}}>
   <ToastContainer/>
   <Sidebar/>
   <div className="dashboardtable">
    <div className="d-flex justify-content-between">
     <h3>Quotes</h3>
     <button
      className=" px-3 "
      onClick={() => setShow(true)}
      style={{
       color: "#fff",
       width: "200px",
       backgroundColor: "#FF731D",
       border: "none",
       borderRadius: "30px",
      }}
     >
      Request Quote
     </button>
    </div>

    <hr></hr>
    <div
     className="modal fade bd-example-modal-lg"
     tabIndex="-1"
     role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true"
    >
     <div className="modal-dialog modal-lg">
      <div className="modal-content">...</div>
     </div>
    </div>
    <div className="d-flex">
     <button
      className="py-2 px-3 my-3 "
      style={{
       color: "#fff",
       width: "250px",
       backgroundColor: "#FF731D",
       border: "none",
      }}
     >
      {" "}
      Supplier
     </button>
    </div>
    <Modal show={showProject} onHide={() => setShowProject(false)} centered size="xl">
     <Modal.Header
      closeButton
      style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
     >
      <Modal.Title className="px-5 py-2">Create new project</Modal.Title>
     </Modal.Header>
     <Modal.Body>
      <Form onSubmit={handleFormSubmit}>
       <Row className="mt-2">
        <Col lg={6}>
         <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
           type='text'
           className='textcontainer'
           placeholder="Enter Project Name"
           isInvalid={!!errors.ProjectNameN}
           value={ProjectNameN}
           onChange={handleNameChange}
           maxLength={30}
          />
          <Form.Control.Feedback type="invalid">
           {errors.ProjectNameN}
          </Form.Control.Feedback>
         </Form.Group>
        </Col>
       </Row>
       <Button type="submit" className="px-4 py-1 mb-5 mt-3"
               style={{borderRadius: "20px", backgroundColor: "rgba(52, 168, 83, 1)", color: "#fff"}}
               variant="secondary">
        SUBMIT
       </Button>
      </Form>
     </Modal.Body>
    </Modal>
    <Row className="mb-3">
     <Col className="col-search" lg={2}>
      <label className="input-container">
       <Form.Control
        className="search-form"
        type="text"
        value={searchText}
        onChange={handleSearch}
        placeholder="Search..."
       />
       <FontAwesomeIcon
        className="fa-search"
        icon={faMagnifyingGlass}
       />
      </label>
     </Col>

     <Col lg={2}>
      <Form.Select value={searchName} onChange={handleDropdownChange}>
       <option value="">Project Name</option>
       {projectList.map((item) => (
        <option key={item.id} value={item.name}>
         {item.name}
        </option>
       ))}
      </Form.Select>
     </Col>

     <Col lg={{offset: 6, span: 2}}>
      <button
       className="px-3 ms-5"
       style={{
        border: "1px solid #FF731D",
        color: "#FF731D",
        backgroundColor: "transparent",
        borderRadius: "30px",
       }}
      >
       Expiring
      </button>
     </Col>
    </Row>
    <Modal
     className="my-5"
     show={show}
     onHide={handleClose}
     centered
     size="xl"
    >
     <form onSubmit={contractorRequestQuote}>
      <Modal.Header
       closeButton
       style={{
        color: "#fff",
        backgroundColor: "rgba(23, 70, 162, 1)",
       }}
      >
       <Modal.Title className="px-5 py-2">Request Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor: "#fff"}}>
       <Row className="mt-2">
        <Col lg={6}>
         <Form.Label className="m-0 p-0" htmlFor="inputPassword">
          <b>Supplier</b>
         </Form.Label>
         <Multiselect
          displayValue="key"
          onKeyPressFn={function noRefCheck() {
          }}
          onRemove={function noRefCheck() {
          }}
          onSearch={function noRefCheck() {
          }}
          onSelect={(e) => setSupplier(e)}
          options={supplierList.map((item) => ({
           id: item.id,
           key: item.username,
          }))}
          showCheckbox
         />
        </Col>
        <Col lg={6}>
         <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
          <b>Project name</b>
         </Form.Label>
         <Form.Select
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          required
         >
          <option value="">Select Project</option>
          {projectList.map((item) => (
           <option key={item.id} value={item.id}>
            {item.name}
           </option>
          ))}
         </Form.Select>
         <span
          className="textcss"
          style={{cursor: "pointer"}}
          l onClick={() => setShowProject(true)}
         >
                      {" "}
          create project
                    </span>
        </Col>
       </Row>
       <Row className="mt-2">
        {rowsProductData.map((row, index) => {
         const {name, quantity, unit} = row;
         return (
          <div className="d-flex" key={row.id}>
           <Col lg={11} className="row d-flex">
            {/* <Col lg={4}>
                      <Form.Label className="m-0 p-0" htmlFor="inputPassword">
                        <b>Project ID</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project ID"
                        value={project_name}
                        name="project_name"
                        onChange={(event) => handleChange(event,index)}
                      />
                    </Col> */}
            <Col lg={5}>
             <Form.Label
              className="m-0 p-0"
              htmlFor="inputPassword5"
             >
              <b>Products</b>
             </Form.Label>
             <Form.Control
              type="text"
              placeholder="Enter Product"
              value={name}
              name="name"
              onChange={(event) => handleChange(event, index)}
              autoComplete="off"
              required
             />
            </Col>
            <Col lg={4}>
             <Form.Label
              className="m-0 p-0"
              htmlFor="inputPassword5"
             >
              <b>Quantity</b>
             </Form.Label>
             <Form.Control
              type="text"
              placeholder="Enter Quantity"
              value={quantity}
              name="quantity"
              autoComplete="off"
              onChange={(event) => handleChange(event, index)}
              required
             />
            </Col>
            <Col lg={3}>
             <Form.Label htmlFor="inputPassword5"></Form.Label>
             <Form.Select
              // className="my-2"
              value={unit}
              name="unit"
              required
              onChange={(event) => handleChange(event, index)}
             >
              <option value={""}>Unit</option>
              <option value={"ton"}>ton</option>
             </Form.Select>
            </Col>
           </Col>
           <Col lg={1}>
            {rowsProductData.length > 1 && (
             <Image
              className=" pt-4 mt-2 ms-auto me-4 d-flex"
              src={minus}
              onClick={() => {
               deleteTableRows(index);
              }}
              style={{cursor: "pointer", width: "20px"}}
             />
            )}
           </Col>
          </div>
         );
        })}
        <Col lg={1}>
         <Image
          src={plusicon}
          className="ms-1"
          onClick={() => {
           addProductRows();
          }}
          style={{
           marginTop: "1%",
           marginBottom: "2%",
           cursor: "pointer",
           height: "20px",
          }}
         />
        </Col>
       </Row>
       <Row className="mt-2">
        <Col lg={3}>
         <Form.Label className="p-0 m-0" htmlFor="inputPassword">
          <b>Start Date</b>
         </Form.Label>
         <Form.Control
          type="date"
          placeholder="Enter Price"
          value={startDate}
          min={new Date().toISOString().split('T')[0]}
          onChange={(e) => setStartDate(e.target.value)}
          required
         />
        </Col>
        <Col lg={3}>
         <Form.Label className="p-0 m-0" htmlFor="inputPassword5">
          <b>End Date</b>
         </Form.Label>
         <Form.Control
          type="date"
          placeholder="Enter Price"
          value={endDate}
          min={new Date().toISOString().split('T')[0]}
          required
          onChange={(e) => setEndDate(e.target.value)}
         />
        </Col>
       </Row>
       <Button
        type="submit"
        className="px-4 py-1 mt-3"
        style={{
         borderRadius: "20px",
         backgroundColor: "rgba(52, 168, 83, 1)",
         color: "#fff",
        }}
        variant="secondary"
        // onClick={handleClose}
       >
        SUBMIT
       </Button>
      </Modal.Body>
     </form>
    </Modal>
    <div className="table-container">
     <table
      id="data-table"
      style={{border: "1px solid rgba(186, 186, 186, 1)"}}
     >
      <thead>
      <tr>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Project ID{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Project Name{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Products
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Supplier{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Quote Price{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Quote Date{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Qty {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
       >
        Status{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        colSpan={2}
       >
        Action
       </th>
      </tr>
      </thead>
      <tbody>
      {quotationList.map((item) => (
       <React.Fragment key={item.project_id}>
        <tr onClick={() => handleExpand(item.project.name)}>
         <td
          className="d-flex justify-content-between"
          style={{color: "rgba(35, 104, 238, 1)"}}
         >
          <span className="">{item.id}</span>
          <span className="expand-trigger ">
                          {expandedRow === item.project.name ? "^" : "˅"}
                        </span>{" "}
         </td>
         <td>{item.project.name}</td>
         <td>
          {item.products.map((item) => item.name).join(" , ")}
         </td>
         <td>{item.supplier.username}</td>

         {/* here do we need to change item,id? */}
         <td>
          {item.products.map((item) => item.id).length > 1
           ? "Multiple Products"
           : item.products.map((item) => (
            <div>
             {item.price} {item.currency}
            </div>
           ))}
         </td>
         <td>{item.end_date}</td>
         <td>
          {item.products.map((item) => item.id).length > 1
           ? "Multiple"
           : item.products.map((item) => (
            <div>
             {item.quantity} {item.unit}
            </div>
           ))}
         </td>
         <td>
          {expandedRow === item.project.name
           ||
           <button
            className="px-3"
            style={{
             border: "none",
             cursor: "pointer",
             backgroundColor: "rgba(255, 115, 29, 1)",
             color: "#fff",
             borderRadius: "30px",
            }}
           >
            {item.status.replace("_", " ")}
           </button>
           }
         </td>
         <td colSpan={2}>
          {expandedRow === item.project.name ? (
           ""
          ) : (
           <>
            <button
             className="mx-3 px-3"
             style={{
              border: "none",
              cursor: "pointer",
              backgroundColor: "#34A853",
              color: "#fff",
              borderRadius: "30px",
             }}

             onClick={(e) => handleAcceptQuoteRequest(item.id, e)}
            >
             Accept
            </button>

            <button
             className="mx-3 px-3"
             style={{
              border: "none",
              cursor: "pointer",
              backgroundColor: "#2F80ED",
              color: "#fff",
              borderRadius: "30px",
             }}

             //   onClick={() => handleNameClick(row)}
            >
             Chat
            </button>
           </>
          )}
         </td>
        </tr>
        {expandedRow === item.project.name && (
         <React.Fragment
          className="m-0 p-0"
          style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
         >
          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <td>Project ID</td>
           <td colSpan={2}>
            <b>{item.project.name}</b>
           </td>
           <td></td>
           <tr>
            {" "}
            <td>
             <b>Products</b>
            </td>
            <td style={{paddingLeft: 30}}>
             <b>Quantity</b>
            </td>
            <td style={{paddingLeft: 10}}>
             <b>Quote</b>
            </td>
            <td style={{paddingLeft: 20}}>
             <b>Terms</b>
            </td>
           </tr>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td>
            <button
             className="ms-a px-3 mt-3"
             style={{
              border: "none",
              cursor: "pointer",
              backgroundColor: "#2F80ED",
              color: "#fff",
              borderRadius: "30px",
             }}

             //  onClick={() => handleNameClick()}
            >
             Chat
            </button>
           </td>
          </tr>

          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <td>Project Name</td>

           <td colSpan={2}>
            <b>{item.project.name}</b>
           </td>
           <td></td>
           {item.products.map((productitem) => (
            <>
             <tr>
              <td>
               <b>{productitem.name}-</b>{" "}
              </td>
              <td>
               <b>
                {productitem.quantity}
                {productitem.unit}
               </b>
              </td>
              <td style={{paddingLeft: 30}}>
               <b>
                {productitem.price}
                {productitem.currency}
               </b>
              </td>
              <td>
               <b>{productitem.terms}</b>
              </td>
             </tr>
            </>
           ))}
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
          </tr>

          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <td>Supplier</td>

           <td colSpan={2}>
            <b>{item.supplier.username}</b>
           </td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
          </tr>

          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <td>Quote Date</td>

           <td colSpan={2}>
            <b>{item.start_date}</b>
           </td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
          </tr>

          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <td>Shipment Date</td>

           <td colSpan={2}>
            <b>09/26/2022-10:00 PM</b>
           </td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
          </tr>

          {/* </Table> */}
          <tr
           style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
          >
           <button
            className="mx-4 px-3 my-3"
            style={{
             border: "none",
             cursor: "pointer",
             backgroundColor: "#34A853",
             color: "#fff",
             borderRadius: "30px",
            }}

            onClick={(e) => handleAcceptQuoteRequest(item.id, e)}
           >
            Accept
           </button>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
          </tr>
         </React.Fragment>
        )}
       </React.Fragment>
      ))}
      </tbody>
     </table>
    </div>
   </div>
  </div>
 );
};

export default ContractorQuotesDashboard;
