import React, {useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import {Button, Col, Form, Modal, Row,} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import "../contractorDashboard/ContractorQuotesDashboard.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import "./project.css";
import {useDispatch} from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import axios from 'axios';
import {getProjectById, getProjectList, searchProject} from "../../redux/project";
import {fetchData} from "../../redux/quotation";
import {toast, ToastContainer} from 'react-toastify';
import StatusDropdown from "./StatusDropdown";

const Projects = () => {
    const [searchText, setSearchText] = useState("");
    const [searchName, setSearchName] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedRow, setExpandedRow] = useState(null);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [status, setStatus] = useState("pending");
    const [projectListdata, setProjectList] = useState([]);
    const [projectList, setProjectList1] = useState([]);
    const [next1, setnext1] = useState('');
    const [previous, setprevious] = useState('');
    const [errors1, setErrors1] = useState("");
    const [ProjectName1, setProjectName1] = useState("");
    const [Desciption1, setDesciption1] = useState("");
    const [StartDate1, setStartDate1] = useState('');
    const [EndDate1, setEndDate1] = useState('');
    const [editid, seteditid] = useState([]);
    const [CreatedBy, setCreatedBy] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState();
    const handleSelect = (selectedList, selectedItem) => {
        const idsArray = selectedList.map((item) => item.id);
        setSelectedOptions(idsArray);

    };

    const [errors, setErrors] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [Desciption, setDesciption] = useState("");
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const isNameValid = (inputName) => {
        const nameRegex = /^(?=.*[a-zA-Z]).+$/;
        return nameRegex.test(inputName);
    };

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false)
        setErrors('')
        setProjectName('')
        setDesciption("");
        setStartDate("");
        setEndDate("");
    };

    const handleCloseEdit = () => {
        setShowEdit(false)
        setErrors('')
    };

    const GetProjectsData = () => {
        dispatch(fetchData('https://cold-hat-40370.botics.co/project/'))
            .then((result) => {
                setProjectList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch(console.log);
    }
    useEffect(() => {
        GetProjectsData()
        dispatch(getProjectList())
         .then((result) => {
             setProjectList1(result.payload.data);
         })
         .catch((error) => {
             console.log(error)
         });
    }, []);

    function nextbuttonCLick() {

        dispatch(fetchData(next1))
            .then((result) => {
                setProjectList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    function previousbuttonCLick() {
        dispatch(fetchData(previous))
            .then((result) => {
                setProjectList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);

        dispatch(searchProject(e.target.value))
            .then((result) => {

                const data = result.payload.data.results;

                setProjectList(result.payload.data.results);

            })
            .catch((errordata) => {

            });
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    const [addProduct, setAddProduct] = useState([{id: 1, name: "", quantity: "", unit: ""},]);

    const [editProduct, setEditProduct] = useState([{id: 1, name: "", quantity: "", unit: ""}]);


    const handlestartDateChange1 = (date) => {
        setStartDate1(date.target.value);
    };
    const handleendDateChange1 = (date) => {
        setEndDate1(date.target.value);
    };

    const handleNameChange1 = (event) => {
        const inputName = event.target.value;
        setProjectName1(inputName);
        if (errors1.ProjectName1 && isNameValid(inputName)) {
            const updatedErrors = {...errors1};

            delete updatedErrors.ProjectName1;

            setErrors1(updatedErrors);
        }

    };

    const handleDescriptionChange1 = (event) => {
        const inputName = event.target.value;
        setDesciption1(inputName);
        if (errors1.Desciption1 && isNameValid(inputName)) {
            const updatedErrors = {...errors1};

            delete updatedErrors.Desciption;

            setErrors(updatedErrors);
        }

    };
    const [validated1, setValidated1] = useState(false);

    const onEditClick = (id) => {
        setShowEdit(true)
        seteditid(id)
        dispatch(getProjectById(id))
            .then((response) => {
                const data = response.payload.data;
                setProjectName1(data.name)
                const startdate = data.start_date;
                setStartDate1(startdate);
                const enddate = data.end_date;
                setEndDate1(enddate);
                setEditProduct(data.products)
                setDesciption1(data.description)
                setCreatedBy(data.created_by)
                setStatus(data.status)
            })
    }
    const handleFormSubmit1 = (event) => {

        event.preventDefault();

        const validationErrors = {};
        if (!ProjectName1) {
            validationErrors.ProjectName1 = "Please enter a project name.";
        } else if (/[^a-zA-Z\s]/g.test(ProjectName1)) {
            validationErrors.ProjectName1 = "Please enter a valid project name.";
        }

        if (!StartDate1) {
            validationErrors.StartDate1 = "Please select date.";
        }

        if (!EndDate1) {
            validationErrors.EndDate1 = "Please select date.";
        }

        if (!Desciption1) {
            validationErrors.Desciption1 = "Please select date.";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors1(validationErrors);

            return;
        }

        if (Object.keys(validationErrors).length === 0) {
            setShowEdit(false)
            setErrors1("");
        }

        projectValue.name = ProjectName1;
        projectValue.start_date = StartDate1;
        projectValue.end_date = formatDateToYYMMDD(EndDate1);
        projectValue.description = Desciption1;
        projectValue.status = status

        updateProjectApiCall(editid, projectValue, GetProjectsData)
    }

    const updateProjectApiCall = (id, data, cb) => {
        let config = {
            method: "PUT", url: `https://cold-hat-40370.botics.co/project/${id}`, headers: {
                'Content-Type': 'application/json', 'Authorization': `token ${localStorage.getItem('token')}`
            }, data
        }

        axios
            .request(config)
            .then(response => {
                toast.success('Data updated successfully', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true,
                })
                cb && cb()
            })
            .catch(error => {
                toast.error('Error while saving data ', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true,
                });
            })
    }

    const handlestartDateChange = (date) => {
        setStartDate(date.target.value);
    };
    const handleendDateChange = (date) => {
        setEndDate(date.target.value);
    };

    const handleNameChange = (event) => {
        const inputName = event.target.value;
        setProjectName(inputName);
        if (errors.ProjectName && isNameValid(inputName)) {
            const updatedErrors = {...errors};

            delete updatedErrors.ProjectName;

            setErrors(updatedErrors);
        }

    };

    const handleDescriptionChange = (event) => {
        const inputName = event.target.value;
        setDesciption(inputName);
        if (errors.Desciption && isNameValid(inputName)) {
            const updatedErrors = {...errors};

            delete updatedErrors.Desciption;

            setErrors(updatedErrors);
        }

    };

    const formatDateToYYMMDD = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    let projectValue = {
        "name": '', "project_ids": '', "products_data": '', "start_date": "", "end_date": "", "description": ""

    }
    const [validated, setValidated] = useState(false);

    const handleFormSubmit = (event) => {

        event.preventDefault();

        const validationErrors = {};
        if (!ProjectName) {
            validationErrors.ProjectName = "Please enter a project name.";
        } else if (/[^a-zA-Z\s]/g.test(ProjectName)) {
            validationErrors.ProjectName = "Please enter a valid project name.";
        }

        if (!StartDate) {
            validationErrors.StartDate = "Please select date.";
        }

        if (!EndDate) {
            validationErrors.EndDate = "Please select date.";
        }

        if (!Desciption) {
            validationErrors.Desciption = "Please select date.";
        }

        if (selectedOptions === 'undefined') {
            validationErrors.multiprojects = "Please enter a Desciption.";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            return;
        }

        if (Object.keys(validationErrors).length === 0) {
            setShow(false)
            setErrors("");
            setProjectName("")
            setDesciption("");
            setStartDate("");
            setEndDate("");

        }

        projectValue.name = ProjectName;
        projectValue.start_date = formatDateToYYMMDD(StartDate);
        projectValue.end_date = formatDateToYYMMDD(EndDate);
        projectValue.description = Desciption;
        projectValue.project_ids = selectedOptions
        projectValue.status = status

        let config = {
            method: "POST", url: 'https://cold-hat-40370.botics.co/project/combine', headers: {
                'Content-Type': 'application/json', 'Authorization': `token ${localStorage.getItem('token')}`
            }, data: projectValue
        }

        axios
            .request(config)
            .then(response => {
                toast.success('Project Combined successfully', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true,
                });
            })
            .catch(error => {
                toast.error('Error while saving data ', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true,
                });
            })
    }


    return (<div className="d-flex">
        <Sidebar/>
        <ToastContainer/>
        <div className="dashboardtable">
            <div className="d-flex justify-content-between">
                <h3>Projects</h3>
                <button
                    className=" px-3 "
                    onClick={() => setShow(true)}
                    style={{
                        color: "#fff", width: "200px", backgroundColor: "#FF731D", border: "none", borderRadius: "30px",
                    }}
                >
                    COMBINE PROJECTS
                </button>
            </div>

            <hr></hr>
            <Row className="mb-3 pb-3">
                <Col className="col-search" lg={2}>
                    <label className="input-container">
                        <Form.Control
                            className="search-form"
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search..."
                        />
                        <FontAwesomeIcon className="fa-search" icon={faMagnifyingGlass}/>
                    </label>
                </Col>
            </Row>
            <Modal className="my-5" show={show} onHide={handleClose} centered size="xl">
                <Modal.Header
                    closeButton
                    style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
                >
                    <Modal.Title className="px-3 py-2">Combine Projects</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#fff"}}>
                    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                        <Row className="mt-1">
                            <Col lg={5}>
                                <Form.Label className="m-0 p-0" htmlFor="inputPassword">
                                    <b>Select projects</b>
                                </Form.Label>

                                <Multiselect
                                    displayValue="name"
                                    onKeyPressFn={function noRefCheck() {
                                    }}
                                    onRemove={function noRefCheck() {
                                    }}
                                    onSearch={function noRefCheck() {
                                    }}
                                    isInvalid={!!errors.selectedOptions}
                                    name='multiprojects'
                                    options={projectList}
                                    showCheckbox
                                    onSelect={handleSelect}
                                />
                                <Form.Control.Feedback type="invalid">{errors.multiprojects}</Form.Control.Feedback>

                            </Col>

                            <Col lg={{span: 3, offset: 4}}>
                                <div className="ms-5 mt-3">
                                    <select
                                        className={status == "completed" ? "completed  px-2 py-1 " : status == "active" ? "active px-2 py-1" : "pending "}
                                        style={{
                                            borderRadius: "15px", color: "white", border: "none",
                                        }}
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option
                                            style={{backgroundColor: "rgba(255, 193, 7, 1)"}}
                                            value="pending"
                                        >
                                            PENDING
                                        </option>
                                        <option
                                            style={{backgroundColor: "rgba(52, 168, 83, 1)"}}
                                            value="completed"
                                        >
                                            COMPLETED
                                        </option>
                                        <option
                                            style={{backgroundColor: "rgb(23, 70, 162)"}}
                                            value="active"
                                        >
                                            ACTIVE
                                        </option>
                                    </select>
                                </div>
                            </Col>

                        </Row>
                        <Row className="mt-2 combinemodal">
                            <Col lg={4}>
                                <Form.Label className="m-0 p-0" htmlFor="inputPassword">
                                    <b>Project name</b>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!!errors.ProjectName}
                                    value={ProjectName}
                                    maxLength={30}
                                    onChange={handleNameChange}
                                    placeholder="Enter Project Name"
                                    className="textback"
                                />
                                <Form.Control.Feedback type="invalid">{errors.ProjectName}</Form.Control.Feedback>
                            </Col>
                            {/* {addProduct.map((row, index) => (
                  <div className="d-flex mt-2" key={row.id}>
                    <div className="row d-flex">

                      <Col lg={3}>
                        <Form.Group >
                          <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
                            <b>Products</b>
                          </Form.Label>
                          <Form.Control type="text" name='name'
                            value={row.name}
                            maxLength={30}
                            isInvalid={!!errors[row.id]?.name}
                            onChange={(e) => handleRowChange(row.id, 'name', e.target.value)} placeholder="Enter Product"
                            className="textback" />
                          <Form.Control.Feedback type="invalid"> {errors[row.id]?.name}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group >
                          <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
                            <b>Quantity</b>
                          </Form.Label>
                          <Form.Control type="text" name='quantity'
                            value={row.quantity}
                            isInvalid={!!errors[row.id]?.quantity}
                            maxLength={30}
                            onChange={(e) => handleRowChange(row.id, 'quantity', e.target.value)}
                            placeholder="Enter Quantity" className="textback" />
                          <Form.Control.Feedback type="invalid"> {errors[row.id]?.quantity}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group >
                          <Form.Label htmlFor="inputPassword5"></Form.Label>
                          <Form.Control
                            as="select"
                            isInvalid={!!errors[row.id]?.unit}
                            required
                            className='textback' value={row.unit} onChange={(e) => handleunitChange(row.id, e.target.value)}
                          >
                            <option value="">Select Unit</option>
                            <option value="ton">ton</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid"> {errors[row.id]?.unit}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </div>
                    <Col lg={1}>
                      {addProduct.length > 1 && (
                        <Image
                          className=" pt-4 mt-2 ms-auto me-4 d-flex"
                          src={minus}
                          onClick={() => {
                            handleRemoveProduct(row.id);
                          }}
                          style={{ cursor: "pointer", width: "20px" }}
                        />
                      )}
                    </Col>
                  </div>
                ))} */}
                            {/* <Col>
                  <Image
                    src={plusicon}
                    className="ms-1"
                    onClick={() => {
                      handleAddProduct();
                    }}
                    style={{
                      marginTop: "1%",
                      cursor: "pointer",
                      height: "20px",
                    }}
                  />
                </Col> */}
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}>
                                <Form.Label className="p-0 m-0" htmlFor="inputPassword">
                                    <b>Start Date</b>
                                </Form.Label>
                                <Form.Control onChange={handlestartDateChange} isInvalid={!!errors.StartDate}
                                              selected={StartDate}
                                              type="date" placeholder="Enter Price"
                                              style={{backgroundColor: "rgba(242, 238, 238, 1)"}}/>
                                <Form.Control.Feedback type="invalid">{errors.StartDate}</Form.Control.Feedback>
                            </Col>
                            <Col lg={3}>
                                <Form.Label className="p-0 m-0" htmlFor="inputPassword5">
                                    <b>End Date</b>
                                </Form.Label>
                                <Form.Control type="date" selected={EndDate} isInvalid={!!errors.EndDate}
                                              min={StartDate}
                                              onChange={handleendDateChange} placeholder="Enter Price"
                                              style={{backgroundColor: "rgba(242, 238, 238, 1)"}}/>
                                <Form.Control.Feedback type="invalid">{errors.EndDate}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label className="p-0 m-0" htmlFor="inputPassword5">
                                    <b>Project Description</b>
                                </Form.Label>
                                <Form.Control as="textarea" isInvalid={!!errors.Desciption} name='Desciption'
                                              value={Desciption}
                                              onChange={handleDescriptionChange} rows={2}
                                              style={{backgroundColor: "rgba(242, 238, 238, 1)"}}/>
                                <Form.Control.Feedback type="invalid">{errors.Desciption}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button
                            className="px-4 py-1 mb-2"
                            style={{
                                borderRadius: "20px", backgroundColor: "rgba(52, 168, 83, 1)", color: "#fff",
                            }}
                            variant="secondary"
                            type="submit"
                        >
                            SUBMIT
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className="table-container">
                <table
                    id="data-table"
                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                >
                    <thead>
                    <tr>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("id")}
                        >
                            Project ID
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("name")}
                        >
                            Project Name
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("name")}
                        >
                            Created by
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("name")}
                        >
                            Created on
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)", width: '15%'}}
                            onClick={() => handleSort("name")}
                        >
                            Start - End date
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("name")}
                        >
                            Quotations
                        </th>
                        <th
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                            onClick={() => handleSort("name")}
                        >
                            Status
                        </th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {projectListdata.map((item) => (<React.Fragment key={item.id}>
                        <tr onClick={() => handleExpand(item.id)}>
                            <td
                                className="d-flex justify-content-around"
                                style={{color: "rgba(35, 104, 238, 1)"}}
                            >
                                <span className="ms-2">{item.id}</span>
                                <span className="expand-trigger me-5">
                        {expandedRow === item.id ? "^" : "˅"}
                      </span>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.created_by}</td>
                            <td>{formatDateToYYMMDD(item.created)}</td>
                            <td>{item.start_date} - {item.end_date}</td>
                            <td>{item.quote_count}</td>
                            <td>
                                {expandedRow !== item.id &&
                                    <StatusDropdown
                                        status={item.status}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={_event => {
                                            updateProjectApiCall(item.id, {status: _event.target.value}, () => {
                                                GetProjectsData()
                                            })
                                        }}
                                    />
                                }
                            </td>
                            <td>
                                <button
                                    className="ms-5 px-3"
                                    style={{
                                        border: "1px solid rgb(255, 115, 29)",
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        color: "rgb(255, 115, 29)",
                                        borderRadius: "30px",
                                        fontSize: "12px",
                                    }}
                                    onClick={(e) => {
                                        onEditClick(item.id)
                                    }}
                                >
                                    <b>EDIT</b>
                                </button>
                            </td>
                        </tr>
                        {expandedRow === item.id && (<React.Fragment
                            className="m-0 p-0"
                            style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
                        >
                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <td>Project ID</td>
                                <td>
                                    <b>{item.id}</b>
                                </td>
                                <td>
                                    Project Description
                                </td>
                                <td colSpan={2}>
                                    <b>{item.description}</b>
                                </td>
                                <td>
                                    <b></b>
                                </td>
                                <td>
                                    <b>
                                        <div>
                                            <StatusDropdown
                                                status={item.status}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={_event => {
                                                    updateProjectApiCall(item.id, {status: _event.target.value}, () => {
                                                        GetProjectsData()
                                                    })
                                                }}
                                            />
                                        </div>
                                    </b>
                                </td>
                                <td>
                                </td>
                            </tr>

                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <td>Project Name</td>
                                <td>
                                    <b>{item.name}</b>
                                </td>

                                <td colSpan={6}></td>
                            </tr>

                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <td>Created by</td>

                                <td>
                                    {/* need to change here created by from the data array */}
                                    <b>{item.created_by}</b>
                                </td>
                                <td colSpan={6}></td>
                            </tr>

                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <td>Start - End Date</td>

                                <td>
                                    {/* need to change here the date from the data array */}
                                    <b>{item.start_date}-{item.end_date}</b>
                                </td>
                                <td colSpan={6}></td>
                            </tr>

                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <td>Product required</td>
                                <td colSpan={7}></td>
                            </tr>

                            <tr style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
                                <button
                                    className="ms-2 mt-2 py-1 px-3"
                                    style={{
                                        border: "1px solid rgb(255, 115, 29)",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        color: "rgb(255, 115, 29)",
                                        fontSize: "12px",
                                        borderRadius: "30px",
                                    }}
                                    onClick={(e) => onEditClick(item.id)}
                                >
                                    <b>EDIT INFORMATION</b>
                                </button>

                                <td>
                                    <button
                                        className="ms-3 py-1 px-3"
                                        style={{
                                            border: "1px solid rgb(255, 115, 29)",
                                            whiteSpace: "nowrap",
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                            color: "rgb(255, 115, 29)",
                                            fontSize: "12px",
                                            borderRadius: "30px",
                                        }}

                                        onClick={() => navigate('/contractororders')}
                                    >
                                        <b>VIEW ORDERS</b>
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="py-1 px-3"
                                        style={{
                                            border: "1px solid rgb(255, 115, 29)",
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                            color: "rgb(255, 115, 29)",
                                            fontSize: "12px",
                                            borderRadius: "30px",
                                        }}

                                        onClick={() => navigate('/contractorquotaiondashboard')}
                                    >
                                        <b>VIEW QUOTES</b>
                                    </button>
                                </td>
                                <td colSpan={5}></td>
                            </tr>

                            {/* Modal for Edit Information */}
                            <Modal className="my-5" show={showEdit} onHide={handleCloseEdit} centered size="xl">
                                <Modal.Header
                                    closeButton
                                    style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
                                >
                                    <Modal.Title className="px-3 py-2">Edit Project Information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{backgroundColor: "#fff"}}>
                                    <Form noValidate validated1={validated1} onSubmit={handleFormSubmit1}>
                                        <Row className="mt-1">
                                            <Col lg={5}>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        value={CreatedBy}
                                                        disabled
                                                        placeholder="Enter Project Name"
                                                        className="textback"
                                                    />

                                                </Form.Group>

                                            </Col>

                                            <Col lg={{span: 3, offset: 4}}>
                                                <div className="ms-5 mt-3">
                                                    <StatusDropdown
                                                        status={status}
                                                        onChange={_event => {
                                                            setStatus(_event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="mt-2 combinemodal">
                                            <Col lg={4}>
                                                <Form.Label className="m-0 p-0" htmlFor="inputPassword">
                                                    <b>Project name</b>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    isInvalid={!!errors1.ProjectName1}
                                                    value={ProjectName1}
                                                    maxLength={30}
                                                    onChange={handleNameChange1}
                                                    placeholder="Enter Project Name"
                                                    className="textback"
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{errors1.ProjectName1}</Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col lg={3}>
                                                <Form.Label className="p-0 m-0" htmlFor="inputPassword">
                                                    <b>Start Date</b>
                                                </Form.Label>

                                                <Form.Control
                                                    type='date'
                                                    className='textcontainer'
                                                    placeholder="Enter Zip Code"
                                                    name='startdate1'
                                                    onChange={handlestartDateChange1}
                                                    selected={StartDate1}
                                                    value={StartDate1}
                                                    required
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{errors1.StartDate1}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label className="p-0 m-0" htmlFor="inputPassword5">
                                                    <b>End Date</b>
                                                </Form.Label>
                                                <Form.Control type="date" selected={EndDate1}
                                                              isInvalid={!!errors1.EndDate1}
                                                              value={EndDate1} min={StartDate1}
                                                              onChange={handleendDateChange1}
                                                              placeholder="Enter Price"
                                                              style={{backgroundColor: "rgba(242, 238, 238, 1)"}}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors1.EndDate1}</Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label className="p-0 m-0" htmlFor="inputPassword5">
                                                    <b>Project Description</b>
                                                </Form.Label>
                                                <Form.Control as="textarea" isInvalid={!!errors1.Desciption1}
                                                              name='Desciption'
                                                              value={Desciption1} onChange={handleDescriptionChange1}
                                                              rows={2}
                                                              style={{backgroundColor: "rgba(242, 238, 238, 1)"}}/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors1.Desciption1}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Button
                                            className="px-4 py-1 mb-2"
                                            style={{
                                                borderRadius: "20px",
                                                backgroundColor: "rgba(52, 168, 83, 1)",
                                                color: "#fff",
                                            }}
                                            variant="secondary"
                                            type="submit"
                                        >
                                            SUBMIT
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>


                        </React.Fragment>)}
                    </React.Fragment>))}
                    </tbody>
                </table>

                <div className="pagination">
                    <Button className='quotebtncss mt-2' style={{marginLeft: '10px'}} type="submit"
                            disabled={next1 != '' && next1 != 'null' && next1 != undefined ? false : true}
                            onClick={() => nextbuttonCLick()}>Next</Button>
                    <Button className='quotebtncss mt-2' style={{marginLeft: '10px'}} type="submit"
                            disabled={previous != '' && previous != 'null' && previous != undefined ? false : true}
                            onClick={() => previousbuttonCLick()}>Previous</Button>
                </div>
            </div>
        </div>
    </div>);
};

export default Projects;
