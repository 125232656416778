import { configureStore } from "@reduxjs/toolkit";

import authSlice from './redux/authSlice';
import quotationData from './redux/quotation';
import contractorQuotation from "./redux/contractorQuotation";
import feedBack from "./redux/feedBack";
import contractorOrder from "./redux/contractorOrder";
import schedules from "./redux/schedules";
import truckingManagement from "./redux/truckingManagement";

const store = configureStore({
  reducer: {
    user:authSlice,
    projectList: quotationData,
    feedbackUser:feedBack,
    data:contractorQuotation,
    contractorOrder:contractorOrder,
    schedules:schedules,
    truckingManagement:truckingManagement
  },
})

export default store