
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchData = createAsyncThunk('fetchData', async (url) => {
  const response = await fetch(url, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const getAllQuotation = createAsyncThunk('getAllQuotaation', async (url) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const getProducts = createAsyncThunk('getProducts', async () => {
  const response = await fetch('https://cold-hat-40370.botics.co/product/', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});



export const getContractor = createAsyncThunk('getContractor', async () => {
  const response = await fetch('https://cold-hat-40370.botics.co/users/?role=contractor', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});

export const getQuotationById = createAsyncThunk('getQuotationById', async (id) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/${id}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const searchByProduct = createAsyncThunk('searchByProduct', async (name) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/?product_name=${name}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const searchByContractor = createAsyncThunk('searchByContractor', async (id) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/?contractor_id=${id}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});



export const searchByProject = createAsyncThunk('searchByProject', async (name) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/?search=${name}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});

export const updateQuotationById = createAsyncThunk('updateQuotationById', async (id,body) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/quote/${id}`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
    body:JSON.stringify(body)
  })
  const data = await response.json();

  return data;
});



export const createNewProject = createAsyncThunk('createNewProject', async (body) => {
  const response = await fetch('https://cold-hat-40370.botics.co/supplier/projects/', {
    method: "post",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
    body:JSON.stringify(body)
    })
  const data = await response.json();

  return data;
});

export const cretaeQuote = createAsyncThunk('cretaeQuote', async (body) => {
  const response = await fetch('https://cold-hat-40370.botics.co/quote/', {
    method: "post",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
    body:JSON.stringify(body)
    })
  const data = await response.json();

  return data;
});

const quotationData = createSlice({
  name: 'myData',
  initialState: {
    projectList: [],
    quotationList: [],
    quotationDetails:[]
  },
  reducers: {},
  extraReducers: {

    [fetchData.pending]: (state, action) => {
      state.loading = true

    },

    [fetchData.fulfilled]: (state, action) => {
      state.projectList = action.payload

    },

    [fetchData.rejected]: (state, action) => {

      state.loading = true

    },

    [getAllQuotation.pending]: (state, action) => {
      state.loading = true

    },

    [getAllQuotation.fulfilled]: (state, action) => {
      state.quotationList = action.payload
    },

    [getAllQuotation.rejected]: (state, action) => {
      state.loading = true
    },
    
    [createNewProject.pending]: (state, action) => {
      state.loading = true

    },

    [createNewProject.fulfilled]: (state, action) => {
      ;

    },

    [createNewProject.rejected]: (state, action) => {

      state.loading = true

    },

    [getQuotationById.pending]: (state, action) => {
      state.loading = true

    },

    [getQuotationById.fulfilled]: (state, action) => {
      state.quotationDetails = action.payload
    },

    [getQuotationById.rejected]: (state, action) => {
      state.loading = true
    },


    [getContractor.pending]: (state, action) => {
      state.loading = true

    },

    [getContractor.fulfilled]: (state, action) => {
      state.quotationDetails = action.payload
    },

    [getContractor.rejected]: (state, action) => {
      state.loading = true
    },

    [cretaeQuote.pending]: (state, action) => {
      state.loading = true
    },

    [cretaeQuote.fulfilled]: (state, action) => {
      state.quotationDetails = action.payload
    },

    [cretaeQuote.rejected]: (state, action) => {
      state.loading = true
    },

    
    [updateQuotationById.pending]: (state, action) => {
      state.loading = true
    },

    [updateQuotationById.fulfilled]: (state, action) => {
      state.quotationDetails = action.payload
    },

    [updateQuotationById.rejected]: (state, action) => {
      state.loading = true
    },


    [getProducts.pending]: (state, action) => {
      state.loading = true
    },

    [getProducts.fulfilled]: (state, action) => {
      state.quotationDetails = action.payload
    },

    [getProducts.rejected]: (state, action) => {
      state.loading = true
    },

    
    
  }
});

export default quotationData.reducer;


