import React, {useEffect, useState} from 'react'
import Sidebar from '../../sidebar/Sidebar';
import {Button, Col, Form, Image, Modal, Row} from 'react-bootstrap';
import './createquotaion.css';
import 'react-datepicker/dist/react-datepicker.css';
import plusicon from '../../../assets/Images/plus-icon.svg';
import {useNavigate} from 'react-router-dom';
import minus from '../../../assets/Images/minus.svg';
import {useDispatch} from "react-redux"
import {cretaeQuote, getContractor} from '../../../redux/quotation';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import {getProjectList} from '../../../redux/project';

export default function CreateQuotaion() {
  const dispatch = useDispatch()
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [projectListdata, setProjectList] = useState([]);
  const [project, setproject] = useState([]);
  const [contractor, setcontractor] = useState([]);
  const [contractorlist, setcontractorlist] = useState([]);
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [ProjectName, setProjectName] = useState('');

  const handleClose = () => {
    setShow(false)
    setErrors('')
    setProjectName('')
  };

  let navigate = useNavigate();
  const [quote, setquote] = useState([]);

  let quotationvalue = {
    "data": {
      "project": "",
      "contractor": "",
      "start_date": "",
      "end_date": "",
      "products_data": []
    },
  }
  useEffect(() => {

    dispatch(getProjectList())
      .then((result) => {
        setProjectList(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

    dispatch(getContractor())
      .then((result) => {
        setcontractorlist(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

  }, []);


  const handlestartDateChange = (date) => {
    setStartDate(date.target.value);
  };
  const handleendDateChange = (date) => {

    setEndDate(date.target.value);
  };
  const contractorSelect = (event) => {
    setcontractor(event.target.value);
  };
  const projectSelect = (event) => {
    setproject(event.target.value);
  };

  const [rows, setRows] = useState([{id: 1, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD"}]);
  const [nextId, setNextId] = useState(2);

  const handleAddRow = () => {
    const newRow = {id: nextId, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD"};
    setRows([...rows, newRow]);
    setNextId(nextId + 1);
  };

  const handleRemoveRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleRowChange = (id, name) => {

    const updatedRows = rows.map((row) =>
      row.id === id ? {...row, name} : row
    );
    setRows(updatedRows);

  };

  const handlequantityChange = (id, quantity) => {
    if (quantity === '0') {

      var quantity = quantity.replace(/0/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, quantity} : row
      );

      setRows(updatedRows);
    } else if (!/^(?=.*[a-zA-Z]).+$/.test(quantity)) {
      var quantity = quantity.replace(/[^\d.]/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, quantity} : row
      );

      setRows(updatedRows);
    } else {
      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, quantity} : row
      );

      setRows(updatedRows);
    }
  };

  const handlepriceChange = (id, price) => {
    if (price === '0') {

      var price = price.replace(/0/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, price} : row
      );

      setRows(updatedRows);
    } else if (!/^\d+(\.\d{1,2})?$/.test(price)) {
      var price = price.replace(/[^\d.]/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, price} : row
      );

      setRows(updatedRows);
    } else {
      const updatedRows = rows.map((row) =>
        row.id === id ? {...row, price} : row
      );

      setRows(updatedRows);
    }
  };

  const handleunitChange = (id, unit) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? {...row, unit} : row
    );

    setRows(updatedRows);
  };

  const handleTerms = (id, terms) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? {...row, terms} : row
    );
    setRows(updatedRows);
  };


  var moment = require('moment');


  const formatDateToYYMMDD = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  function createQuotation(event) {
    event.preventDefault();

    const form = event.currentTarget;


    if (form.checkValidity()) {

      quotationvalue.data.start_date = formatDateToYYMMDD(StartDate);
      quotationvalue.data.end_date = formatDateToYYMMDD(EndDate);
      quotationvalue.data.project = project;
      quotationvalue.data.contractor = contractor;
      const newData = rows.map(({name, quantity, price, unit, terms, currency}) => ({
        name,
        quantity,
        price,
        unit,
        terms,
        currency
      }));
      quotationvalue.data.products_data = newData;

      dispatch(cretaeQuote(quotationvalue.data))
        .then((result) => {
          var list = [];
          list.push(result.payload.data);
          var tempaddressVal = [];
          list.map((i, j) => {
            var tempObj = {};
            tempObj.id = i.id;

            tempObj.contractorid = i.contractor.id
            tempObj.contractor = i.contractor.username;

            tempObj.project = i.project.name;
            tempObj.quotedate = moment((i.created)).format("MM-DD-YYYY");
            tempObj.productlist = i.products;

            tempaddressVal.push(tempObj);
          })
          setquote(tempaddressVal)

          toast.success('Quote created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          })
          goBack()
        })

    } else {
      event.stopPropagation();
    }
    setValidated(true);
  }

  let projectValue = {
    "data": {
      "name": '',
    },
  }

  const isNameValid = (inputName) => {
    const nameRegex = /^(?=.*[a-zA-Z]).+$/;
    return nameRegex.test(inputName);
  };

  const handleNameChange = (event) => {
    const inputName = event.target.value;

    setProjectName(inputName);

    if (errors.ProjectName && isNameValid(inputName)) {
      const updatedErrors = {...errors};

      delete updatedErrors.ProjectName;

      setErrors(updatedErrors);
    }


  };
  const handleFormSubmit = (event) => {

    event.preventDefault();

    const validationErrors = {};

    if (!isNameValid(ProjectName)) {

      validationErrors.ProjectName = "Please enter a valid project name.";

    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    }

    if (Object.keys(validationErrors).length === 0) {
      setProjectName("");
      setErrors("");

    }

    projectValue.data.name = ProjectName;
    let config = {
      method: "post",
      url: 'https://cold-hat-40370.botics.co/project/',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
      data: projectValue.data
    }

    axios
      .request(config)
      .then(response => {

        toast.success('Project created successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });

        dispatch(getProjectList())
          .then((result) => {

            setProjectList(result.payload.data);
          })
          .catch((error) => {
            console.log(error)
          });

        setShow(false)
      })
      .catch(error => {

        toast.error('Error while saving data', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
        });
        console.log(error)
      })
  }
  const todayDate = new Date().toISOString().split('T')[0];
  const goBack = () => navigate("/quotaiondetails")
  return (
    <div style={{display: 'flex'}}>
      <Sidebar/>
      <div className='mainContainer'>

        <div className="header-container">
          <span className='headertext'>Create Quote</span>
          <ToastContainer/>
          <button
            className="px-3 ms-5 mb-2"
            style={{
              border: "1px solid #FF731D",
              color: "#FFFF",
              backgroundColor: "#FF731D",
              borderRadius: "30px",
            }}
            onClick={() => goBack()}
          >
            Back to Quotes
          </button>
        </div>
        <div className="table-container">
          <Form noValidate validated={validated} onSubmit={createQuotation} className='form1'>
            <div className="container">
              <div className="row">

                <div className="col-sm">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Contractor</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      className='textcontainer headertext2'
                      onChange={contractorSelect}
                    >
                      <option value="">Select Contractor</option>

                      {contractorlist.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.username}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Project name</Form.Label>

                    <Form.Control
                      as="select"
                      required
                      className='textcontainer headertext2'
                      onChange={projectSelect}
                    >
                      <option value="">Select Project Name</option>
                      {projectListdata.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>

                    <span className='textcss' style={{cursor: 'pointer'}} onClick={() => setShow(true)}> Create new project</span>
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
              </div>

              {rows.map((row, index) => (
                <div key={row.id}>
                  <div className="row p-2" style={{background: '#F2EEEE'}}>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss">Products
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.products}
                        placeholder="Enter Products"
                        required
                        maxLength={30}
                        onChange={(e) => handleRowChange(row.id, e.target.value, index)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter products</Form.Control.Feedback>

                    </div>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss">Quantity
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.quantity}
                        placeholder="Enter Quantity"
                        required
                        maxLength={30}
                        onChange={(e) => handlequantityChange(row.id, e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter quantity</Form.Control.Feedback>
                    </div>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss">Price
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.price}
                        required
                        maxLength={30}
                        placeholder="Enter Price"
                        onChange={(e) => handlepriceChange(row.id, e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter price</Form.Control.Feedback>
                    </div>
                    <div className="col-sm">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Unit</Form.Label>

                        <Form.Control
                          as="select"
                          required
                          className='headertext2' value={row.unit}
                          onChange={(e) => handleunitChange(row.id, e.target.value)}
                        >
                          <option value="">Select Unit</option>
                          <option value="ton">ton</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select unit</Form.Control.Feedback>
                      </Form.Group>

                    </div>
                    <Form.Label className="text-start labelcss" style={{marginTop: '2%'}}>Terms
                    </Form.Label>
                    <div className="container ">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" value={row.terms} rows={2} required
                                      onChange={(e) => handleTerms(row.id, e.target.value)}/>
                        <Form.Control.Feedback type="invalid">Please enter terms</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  {rows.length > 1 && (
                    <Image className="pb-1 ms-1" src={minus} onClick={() => {
                      handleRemoveRow(row.id)
                    }} style={{marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px'}}/>
                  )}
                </div>
              ))}
              <Image src={plusicon} onClick={() => {
                handleAddRow()
              }} style={{marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px'}}/>


              <div className='row col-md-8'>
                <div className='col-sm-6 startdate'>
                  <Form.Label className="text-start labelcss">Start Date
                  </Form.Label>

                  <Form.Control
                    type='date'
                    className='textcontainer'
                    placeholder="Enter Zip Code"
                    name='startdate'
                    min={todayDate}
                    onChange={handlestartDateChange}
                    selected={StartDate}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please select start date</Form.Control.Feedback>

                </div>
                <div className='col-sm-6 startdate'>
                  <Form.Label className="text-start labelcss">End Date
                  </Form.Label>
                  <Form.Control
                    type='date'
                    className='textcontainer'
                    placeholder="Enter Zip Code"
                    name='enddate'
                    min={StartDate}
                    onChange={handleendDateChange}
                    selected={EndDate}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please select end date</Form.Control.Feedback>
                </div>
              </div>
              <div style={{marginTop: '2%', marginBottom: '2%'}}>

                <Button className='submitbtncss' type="submit">Submit</Button>
              </div>
            </div>
          </Form>
          <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header
              closeButton
              style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
            >
              <Modal.Title className="px-5 py-2">Create new project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleFormSubmit}>
                <Row className="mt-2">
                  <Col lg={6}>
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Project Name</Form.Label>
                      <Form.Control
                        type='text'
                        className='textcontainer'
                        placeholder="Enter Project Name"
                        isInvalid={!!errors.ProjectName}
                        value={ProjectName}
                        onChange={handleNameChange}
                        maxLength={30}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ProjectName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button type="submit" className="px-4 py-1 mb-5 mt-3"
                        style={{borderRadius: "20px", backgroundColor: "rgba(52, 168, 83, 1)", color: "#fff"}}
                        variant="secondary">
                  SUBMIT
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
          <div className='form2'>
            <div className="container" style={{height: '75vh', overflow: 'auto'}}>

              <div className='textcontainer p-2 ' style={{height: '75vh'}}>
                <div className="container">
                  <span className='headertext1'>Approval history</span>
                  {quote.map((item) => (<>
                    <div className='textcontainer1 '>

                      <div className="container mb-2">
                        <div className="row">
                          <div className="col-sm-3 headertext2">
                            Created Quote
                          </div>
                          <div className="col-sm-6" style={{backgroundColor: '#F5F5F5'}}>
                            Contractor- {item.contractor} <br></br>
                            Project: {item.project}
                            <br></br>

                            {item.productlist.map((i) => (<>
                              <span className='headertext2'>
                                {i.name} - ${i.price}/{i.unit}
                                <br></br>

                              </span>
                            </>))}
                          </div>
                          <div className="col-sm-3">
                            {item.quotedate}
                          </div>
                        </div>
                      </div>

                    </div>
                  </>))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
