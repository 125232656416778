import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, } from 'chart.js';
import Sidebar from "../sidebar/Sidebar";
import { Bar } from 'react-chartjs-2';
import { getProjectList } from "../../redux/project";
import { getSupplier, } from "../../redux/contractorQuotation";
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

ChartJS.register(
    CategoryScale, LinearScale,
    BarElement,
);

const ScheduleContractor = () => {
    const dispatch = useDispatch()
    const [monthGraph, setMonthGraph] = useState("January");
    const [projectIdGraph, setProjectIdGraph] = useState("");
    const [supplierIdGraph, setSupplierIdGraph] = useState("");
    const [yearGraph, setYearGraph] = useState(2023)
    const [chart, setChart] = useState([])
    const [supplierList, setSupplierList] = useState([]);
    const [projectList, setProjectList] = useState([]);
   
    let navigate = useNavigate();

    const calYear = () =>{
        console.log("calYear", monthGraph)
        if(monthGraph == "January"){
            return 0
        }else if(monthGraph == "February"){
            return 1
        }else if(monthGraph == "March"){
            return 2
        }
        else if(monthGraph == "April"){
            return 3
        }
        else if(monthGraph == "May"){
            return 4
        }
        else if(monthGraph == "June"){
            return 5
        }
        else if(monthGraph == "July"){
            return 6
        }
        else if(monthGraph == "August"){
            return 7
        }
        else if(monthGraph == "September"){
            return 8
        }
        else if(monthGraph == "October"){
            return 9
        }else if(monthGraph == "November"){
            return 10
        }else{
            return 11
        }
    }

    const fetchData = async () => {
        const response = await fetch(`https://cold-hat-40370.botics.co/schedule/?month=${monthGraph}&year=${yearGraph}&project=${projectIdGraph}&supplier=${supplierIdGraph}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `token ${localStorage.getItem('token')}`
            },
        })
        const data = await response.json()
        
        let tempArray = []
        setChart([]);
        console.log("hhhhhhhhhhhhhhh",yearGraph,calYear())
        data.data.map((item, index) => {
            tempArray.push(
                {
                    start: new Date(yearGraph, calYear()),
                    end: new Date(yearGraph, calYear()),
                    name: item.name,
                    id: `Task ${index + 1}`,
                    type: 'task',
                    progress: item.total,
                    isDisabled: true,
                    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                }
            )
        })
        console.log("tasts", tempArray)
      
        setChart(tempArray);
        // return tempArray;
    }

    useEffect(() => {
        dispatch(getSupplier())
            .then((result) => {
                setSupplierList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(getProjectList())
            .then((result) => {
                setProjectList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // console.log(chart)
    // console.log("mooooooo", monthGraph, yearGraph, projectIdGraph, supplierIdGraph)

    useEffect(() => {
        fetchData();
    }, [monthGraph, supplierIdGraph, yearGraph, projectIdGraph]);

    const data = {
        labels: chart?.data?.map(x => x.name),
        datasets: [{
            label: "# of votes",
            data: chart?.data?.map(x => x.total),
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            //   borderWidth: 1
        }]
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                }
            },
            x: {
                grid: {
                    display: false
                }
            },
        },
        legend: {
            labels: {
                fontSize: 25,
            },
        },
    };

    // let tasks = [
    //     {
    //         start: new Date(2020, 1, 1),
    //         end: new Date(2020, 1, 2),
    //         name: 'Idea',
    //         id: 'Task 0',
    //         type: 'task',
    //         progress: 45,
    //         isDisabled: true,
    //         styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    //     },
    //     {
    //         start: new Date(2020, 1, 1),
    //         end: new Date(2020, 1, 2),
    //         name: 'Ideas',
    //         id: 'Task 1',
    //         type: 'task1',
    //         progress: 500,
    //         isDisabled: true,
    //         styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    //     }
    // ];

    return (
        <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
                <Sidebar />
            </div>

            <div className="ms-5" style={{ width: "90%" }}>
                <div
                    className="d-flex justify-content-between "
                    style={{
                        borderBottom: "1px solid lightgrey",
                        textAlign: "left",
                    }}
                >
                    <h2 className="p-3">Schedule</h2>
                    <button onClick={() => navigate("/createschedule")} className="px-3 py-1 my-4 mx-3" style={{
                        border: "none",
                        backgroundColor: "rgba(255, 115, 29, 1)",
                        color: "#fff",
                        borderRadius: "15px"
                    }}>
                        CREATE SCHEDULE
                    </button>
                </div>

                <div className="mt-4 ms-1" style={{ textAlign: "left" }}>
                    <select className="p-2" onChange={(e) => setProjectIdGraph(e.target.value)}>
                        <option value="">Select Project</option>
                        {projectList.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </select>
                    <select className="ms-2 p-2" onChange={(e) => setSupplierIdGraph(e.target.value)}>
                        <option value="">Select Supplier</option>
                        {supplierList.map((item) => <option key={item.id} value={item.id}>{item.username}</option>)}
                    </select>
                    <select className="ms-2 px-3 py-2" value={yearGraph} onChange={(e) => setYearGraph(e.target.value)}>
                        <option value="">Select Year</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                        <option value={2025}>2025</option>
                        <option value={2026}>2026</option>
                    </select>
                    <select className="ms-2 px-3 py-2" value={monthGraph} onChange={(e) => setMonthGraph(e.target.value)}>
                        <option value="">Select Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                    </select>
                </div>
                {chart.length > 0 ?
                    <Gantt tasks={chart} />
                    :
                    <div style={{display:"flex", justifyContent:"center", marginTop:"40px"}}><div>No data found</div></div>
                }
                {/* <div className="my-3 position-relative" style={{ width: "600px", height: "300px" }}>
                    <Bar
                        data={data}
                        height={100}
                        options={options}
                    />
                    {
                        (chart && chart?.data?.length === 0)
                        && <div
                            className={"w-100 h-100 position-absolute top-0 left-0 d-flex align-items-center justify-content-center bg-white-transparent text-dark"}>
                            No Data found
                        </div>
                    }
                </div> */}
            </div>

        </div>
    );
};

export default ScheduleContractor;
