import React, { useState, useEffect } from 'react'
import Sidebar from '../../sidebar/Sidebar';
import { Form, Button, Image, Col, Row, Modal } from 'react-bootstrap';
import './ViewDetails.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import plusicon from '../../../assets/Images/plus-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import minus from '../../../assets/Images/minus.svg';
import { useSelector, useDispatch } from "react-redux"
import { fetchData, createNewProject, getQuotationById, getContractor, updateQuotationById } from '../../../redux/quotation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getProjectList } from '../../../redux/project';
export default function ViewDetails() {
  const dispatch = useDispatch()
  const quotationDetails = useSelector((state) => state.projectList.quotationDetails.data);
  const { id } = useParams();
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [terms, setTerms] = useState('');
  const [showComment, setComment] = useState(false);
  const [projectListdata, setProjectList] = useState([]);
  const [project, setproject] = useState('');
  const [contractor, setcontractor] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [validated, setValidated] = useState(false);
  const [ProjectName, setProjectName] = useState('');
  const [contractorlist, setcontractorlist] = useState([]);
  let navigate = useNavigate();
  const [quoteid, setquoteid] = useState('');
  const [quote, setquote] = useState([]);
  const [quotationList, setquotationList] = useState([]);
  const [status,setstatus] = useState('');
  useEffect(() => {
    dispatch(getQuotationById(id))
      .then((result) => {
        setRows(result.payload.data.products)
        setcontractor(result.payload.data.contractor.id);
        setproject(result.payload.data.project.id);
        setquoteid(result.payload.data.id);
        setStartDate(result.payload.data.start_date);
        setEndDate(result.payload.data.end_date);
        setstatus(result.payload.data.status)
        getdata(result.payload.data)

      })
      .catch((error) => {

      });

  }, []);



  function getdata(listdata) {
    let list = []
    list.push(listdata);
    let tempaddressVal = [];
    list.map((i, j) => {
      let tempObj = {};
      tempObj.id = i.id;

      tempObj.contractorid = i.contractor.id
      tempObj.contractor = i.contractor.username;
      tempObj.project = i.project.name;
      tempObj.quotedate = moment((i.created)).format("MM-DD-YYYY");
      tempObj.productlist = i.products;

      tempaddressVal.push(tempObj);
    })

    setquote(tempaddressVal)
  }



  let quotationvalue = {
    "data": {
      "project": "",
      "contractor": "",
      "start_date": "",
      "end_date": "",
      "terms": "",
      "products_data": []
    },
  }


  useEffect(() => {
    dispatch(getProjectList())
      .then((result) => {
        
        setProjectList(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

    dispatch(getContractor())
      .then((result) => {
        
        setcontractorlist(result.payload.data);
      })
      .catch((error) => {
        console.log(error)
      });

  }, []);

  const handlestartDateChange = (date) => {
    setStartDate(date.target.value);
  };
  const handleendDateChange = (date) => {
    setEndDate(date.target.value);
  };
  const contractorSelect = (event) => {
    setcontractor(event.target.value);
  };
  const projectSelect = (event) => {
    setproject(event.target.value);
  };

  const [rows, setRows] = useState([{ id: 1, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD" }]);
  const [nextId, setNextId] = useState(2);

  const handleAddRow = () => {
    const newRow = { id: nextId, name: '', quantity: '', price: '', unit: '', terms: '', currency: "USD" };
    setRows([...rows, newRow]);
    setNextId(nextId + 1);
  };

  const handleRemoveRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    ;
  };

  const handleRowChange = (id, name) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, name } : row
    );
    
    setRows(updatedRows);
  };

  const handlequantityChange = (id, quantity) => {
    if (quantity === '0') {

      var quantity = quantity.replace(/0/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, quantity } : row
      );
      
      setRows(updatedRows);
    } else {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, quantity } : row
      );
      
      setRows(updatedRows);
    }
  };

  const handlepriceChange = (id, price) => {
    if (price === '0') {

      var price = price.replace(/0/g, '');

      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, price } : row
      );
      
      setRows(updatedRows);
    } else {
      const updatedRows = rows.map((row) =>
        row.id === id ? { ...row, price } : row
      );
      
      setRows(updatedRows);
    }
  };

  const handleunitChange = (id, unit) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, unit } : row
    );
    
    setRows(updatedRows);
  };


  const handleTerms = (id, terms) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, terms } : row
    );
    setRows(updatedRows);
  };

  var moment = require('moment');

  const formatDateToYYMMDD = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  function createQuotation(event) {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity()) {

      quotationvalue.data.start_date = formatDateToYYMMDD(StartDate);
      quotationvalue.data.end_date = formatDateToYYMMDD(EndDate);
      quotationvalue.data.project = project;
      quotationvalue.data.contractor = contractor;
      quotationvalue.data.terms = terms;
      const newData = rows.map(({ name, quantity, price, unit, terms, currency }) => ({ name, quantity, price, unit, terms, currency }));
      quotationvalue.data.products_data = newData;

      if(status === 'REQUESTED'){
        quotationvalue.data.status = 'SUBMITTED';
      }

      let body = quotationvalue.data;

      // dispatch(updateQuotationById({quoteid,body}))
      // .then((result) => {
      //   
      //   toast.success('Data updated successfully', {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //   });
      //   getdata(result.payload.data)

      // })
      // .catch((error) => {

      // });

      let config = {
        method: "PUT",
        url: `https://cold-hat-40370.botics.co/quote/${quoteid}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${localStorage.getItem('token')}`
        },
        data: quotationvalue.data
      }

      axios
        .request(config)
        .then(response => {


          toast.success('Data updated successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
          const list = []

          list.push(response.data.data);
          const tempaddressVal = [];
          list.map((i, j) => {
            const tempObj = {};
            tempObj.id = i.id;

            tempObj.contractorid = i.contractor.id
            tempObj.contractor = i.contractor.username;


            tempObj.project = i.project.name;
            tempObj.quotedate = moment((i.created)).format("MM-DD-YYYY");
            tempObj.productlist = i.products;
            setstatus(i.status)
            tempaddressVal.push(tempObj);
          })
          setquote(tempaddressVal)

        })
        .catch(error => {
          toast.error('Error while saving data ', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        })

    } else {
      event.stopPropagation();
    }
    setValidated(true);
  }

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div className='mainContainer'>

        <div className="header-container">
          <span className='headertext'>Edit Quote</span>
          <ToastContainer />
          <button
            className="px-3 ms-5 mb-2"
            style={{
              border: "1px solid #FF731D",
              color: "#FFFF",
              backgroundColor: "#FF731D",
              borderRadius: "30px",
            }}
            onClick={() => navigate("/quotaiondetails")}
          >
            Back to Quotes
          </button>
        </div>
        <div className="table-container">
          <Form noValidate validated={validated} onSubmit={createQuotation} className='form1'>
            <div className="container">
              <div className="row">

                <div className="col-sm">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Contractor</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      className='textcontainer headertext2'
                      value={contractor}
                      onChange={contractorSelect}
                    >
                      <option value="">Select Contractor</option>
                      {contractorlist.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.username}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-sm">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Project name</Form.Label>
                    <Form.Control
                      as="select"
                      value={project}
                      required
                      className='textcontainer headertext2'
                      onChange={projectSelect}
                    >
                      <option value="">Select Project Name</option>
                      {projectListdata.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select contractor</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
              </div>

              {rows.map((row, index) => (
                <div key={row.id} className='mt-3'>
                  <div className="row p-2" style={{ background: '#F2EEEE' }}>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss" >Products
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.name}
                        placeholder="Enter Products"
                        required
                        maxLength={30}
                        onChange={(e) => handleRowChange(row.id, e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter products</Form.Control.Feedback>

                    </div>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss" >Quantity
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.quantity}
                        placeholder="Enter Quantity"
                        required
                        onChange={(e) => handlequantityChange(row.id, e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter quantity</Form.Control.Feedback>
                    </div>
                    <div className="col-sm">
                      <Form.Label className="text-start labelcss" >Price
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={row.price}
                        required
                        placeholder="Enter Price"
                        onChange={(e) => handlepriceChange(row.id, e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">Please enter price</Form.Control.Feedback>
                    </div>
                    <div className="col-sm">
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Unit</Form.Label>

                        <Form.Control
                          as="select"
                          required
                          className='headertext2' value={row.unit} onChange={(e) => handleunitChange(row.id, e.target.value)}
                        >
                          <option value="">Select Unit</option>
                          <option value="ton">ton</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select unit</Form.Control.Feedback>
                      </Form.Group>

                    </div>
                    <Form.Label className="text-start labelcss" style={{ marginTop: '2%' }}>Terms
                    </Form.Label>
                    <div className="container ">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" value={row.terms} rows={2} required onChange={(e) => handleTerms(row.id, e.target.value)} />
                        <Form.Control.Feedback type="invalid">Please enter terms</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  {rows.length > 1 && (

                    <Image src={minus} onClick={() => { handleRemoveRow(row.id) }} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px' }} />
                  )}
                </div>
              ))}
              <Image src={plusicon} onClick={() => { handleAddRow() }} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer', height: '20px' }} />


              <div className='row col-md-8'>
                <div className='col-sm-6 startdate' >
                  <Form.Label className="text-start labelcss" >Start Date
                  </Form.Label>

                  <Form.Control
                    type='date'
                    className='textcontainer'
                    placeholder="Enter Zip Code"
                    name='startdate'
                    onChange={handlestartDateChange}
                    selected={StartDate}
                    defaultValue={StartDate}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please select start date</Form.Control.Feedback>

                </div>
                <div className='col-sm-6 startdate' >
                  <Form.Label className="text-start labelcss" >End Date
                  </Form.Label>
                  <Form.Control
                    type='date'
                    className='textcontainer'
                    placeholder="Enter Zip Code"
                    name='enddate'
                    defaultValue={EndDate}
                    onChange={handleendDateChange}
                    selected={EndDate}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please select end date</Form.Control.Feedback>
                </div>
              </div>



              <div style={{ marginTop: '2%', marginBottom: '2%' }}>
                {/* <Button className='quotebtncss' type="submit" onClick={() => createQuotation()}>Get approval</Button> */}
                <Button className='submitbtncss' type="submit" >Submit</Button>
              </div>



            </div>
          </Form>

          <div className='form2'>
            <div className="container" style={{ height: '75vh', overflow: 'auto' }}>

              <div className='textcontainer p-2 ' style={{ height: 'inherit' }}>
                <div className="container">
                  <span className='headertext1'>Approval history</span>
                  {quote.map((item) => (<>
                    {status === 'SUBMITTED' && <div className='textcontainer1 '>

                      <div className="container mb-2" >
                        <div className="row">
                          <div className="col-sm-3 headertext2">
                            Updated Quote
                          </div>
                          <div className="col-sm-6" style={{ backgroundColor: '#F5F5F5' }}>
                            Contractor- {item.contractor} <br></br>
                            Project: {item.project}
                            <br></br>

                            {item.productlist.map((i) => (<>
                              <span className='headertext2'>
                                {i.name} - ${i.price}/{i.unit}
                                <br></br>

                              </span>
                            </>))}
                          </div>
                          <div className="col-sm-3">
                            {item.quotedate}
                          </div>
                        </div>
                      </div>

                    </div>}</>))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
