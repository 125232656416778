import React from 'react';

const StatusDropdown = (props) => {
  const {status, onChange, ...restSelectProps} = props
  const classMap = {
    "PENDING" : 'p-pending',
    "SUBMITTED" : "p-completed",
    "ACTIVE": "p-active"
  }
  return (
    <select
      className={classMap[status]}
      style={{
        borderRadius: "15px",
        color: "white",
        border: "none",
      }}
      value={status}
      onChange={onChange}
      {...restSelectProps}
    >
      <option style={{backgroundColor: "rgba(255, 193, 7, 1)",}} value="PENDING"> PENDING
      </option>
      <option style={{backgroundColor: "rgba(52, 168, 83, 1)",}} value="SUBMITTED"> SUBMITTED
      </option>
      <option style={{backgroundColor: "rgb(23, 70, 162)",}} value="ACTIVE"> ACTIVE</option>
    </select>  );
};

export default StatusDropdown;
