
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllProjects = createAsyncThunk('getAllProjects', async (url) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const getProjectById = createAsyncThunk('getProjectById', async (id) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/project/${id}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});

export const updateProject = createAsyncThunk('updateProject', async (id,body) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/project/${id}`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
    body:JSON.stringify(body)
  })
  const data = await response.json();

  return data;
});


export const getProjectList = createAsyncThunk('getProjectList', async () => {
  const response = await fetch('https://cold-hat-40370.botics.co/project/?paginate=False', {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});

export const searchProject = createAsyncThunk('searchProject', async (value) => {
  const response = await fetch(`https://cold-hat-40370.botics.co/project/?search=${value}`, {
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


export const fetchData = createAsyncThunk('fetchData', async (url) => {
  const response = await fetch({url}, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


const quotationData = createSlice({
  name: 'myData',
  initialState: {
    projectList: [],
    quotationList: [],
    quotationDetails:[]
  },
  reducers: {},
  extraReducers: {

    [getAllProjects.pending]: (state, action) => {
      state.loading = true
    },

    [getAllProjects.fulfilled]: (state, action) => {
      state.projectList = action.payload

    },

    [getAllProjects.rejected]: (state, action) => {
      state.loading = true
    },


    [getProjectById.pending]: (state, action) => {
      state.loading = true

    },

    [getProjectById.fulfilled]: (state, action) => {
      state.quotationList = action.payload
    },

    [getProjectById.rejected]: (state, action) => {
      state.loading = true
    },
    
    [updateProject.pending]: (state, action) => {
      state.loading = true
    },

    [updateProject.fulfilled]: (state, action) => {
      ;
    },

    [updateProject.rejected]: (state, action) => {
      state.loading = true
    },


    [getProjectList.pending]: (state, action) => {
      state.loading = true
    },

    [getProjectList.fulfilled]: (state, action) => {
      ;
    },

    [getProjectList.rejected]: (state, action) => {
      state.loading = true
    },

    
    [fetchData.pending]: (state, action) => {
      state.loading = true

    },

    [fetchData.fulfilled]: (state, action) => {
      state.projectList = action.payload

    },

    [fetchData.rejected]: (state, action) => {

      state.loading = true

    },
  }
});

export default quotationData.reducer;


