import { useState, useEffect } from "react";
import {
  Accordion,
  Container,
  Image,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import contactImage from "../../assets/Images/contactImg.png";
import aboutusA from "../../assets/Images/aboutusA.png";
import aboutusB from "../../assets/Images/aboutusB.png";
import banner from "../../assets/Images/banner.png";
import "./Landing.css";
const Landing = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const isEmailValid = (inputEmail) => {
    // Basic email validation using regular expression

    const emailRegex = /^([\w\-]+\.?){0,2}[\w\-]+@[\w.\-]+$/;

    return emailRegex.test(inputEmail);
  };
  const isMessageValid = (message) => {
    // Implement your own password validation logic

    return message.trim().length > 8;
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;

    setEmail(inputEmail);

    // Clear the error for email field if it becomes valid

    if (errors.email && isEmailValid(inputEmail)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.email;

      setErrors(updatedErrors);
    }
  };
  const handleMessageChange = (event) => {
    const inputMessage = event.target.value;

    setMessage(inputMessage);

    // Clear the error for email field if it becomes valid

    if (errors.message && isMessageValid(message)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.message;

      setErrors(updatedErrors);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    ;
    // dispatch(signUpUser(body))
    // Perform validation

    const validationErrors = {};

    if (!isEmailValid(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!isMessageValid(message)) {
      validationErrors.message =
        "Please enter a valid feedback Message of length greater than 8.";
    }
    //Set validation errors if any

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      //   toast.success('Signup Successful!', {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //   });
      setEmail("");
      setMessage("");
    }
    // Form is valid, perform login logic here

    // Reset form fields and errors

    setErrors({});
  };
  return (
    <div>
        <div className="banner-container mb-4"  style={{
        backgroundImage: `url(${banner})`,
        // backgroundImage: `url(${externalImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '600px',
      }} >
        {/* <div className="banner-main">
            <Image className="banner-main-img" src={banner} alt='' />
        </div> */}
        <div className="banner-content text-center " >
            <h1 className="banner-text">
                <b>One Stop Solution</b>
            </h1>
            <h5 className="banner-text">Lorem lipsum dummy data</h5>
        </div>
        </div>
      <Container>
        <div className="about-us my-5 ">
            <span style={{backgroundColor:"rgba(255, 242, 220, 1)",position:"absolute",width:"60%",zIndex:"-1" ,height:"600px"}}></span>
          <h4 className="text-center p-3">
            <b>
              <span className="header-underline">About</span> Us
            </b>
          </h4>
          <div className="d-flex my-5">
            <div className="about-us-content mx-5 px-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br></br>
              <br></br>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              <br></br>
              <br></br>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br></br>
              <br></br>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              <br></br>
             
            </div>
            <div className="d-flex about-us-image-container">
              <Image
                className="mx-2 about-us-image-a"
                src={aboutusA}
                alt=""
              />
              <Image
                className="mx-2 about-us-image-b"
                src={aboutusB}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="faq">
          <h4 className="text-center">
            <b>
              <span className="header-underline">Frequenty Ask</span>ed
              Questions
            </b>
          </h4>
          <Accordion defaultActiveKey="0">
            <Accordion.Item className="my-5 faq-item" eventKey="0">
              <Accordion.Header>Accordion Item #1</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="my-5 faq-item">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="my-5 faq-item" eventKey="2">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="my-5 faq-item" eventKey="3">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="contact-us d-flex justify-content-around">
          <div className="contact-us-image-container">
            <Image className="contact-us-image" src={contactImage} alt="" />
          </div>
          <div className="contact-us-form">
            <Row>
              <Col lg={{ offset: 1, span: 8 }}>
                <h4 className="mx-4">
                  <b>
                    <span className="header-underline">Contact</span> Us
                  </b>
                </h4>
                <Form className="my-5" onSubmit={handleFormSubmit}>
                  <div className="d-flex">
                    <Form.Group
                      className="mb-3 mx-4 name-contact"
                      controlId="formBasicFname"
                    >
                        <Form.Label className="text-start m-0 p-0">
                      First Name <span style={{color:"rgba(255, 0, 0, 1)"}}>*</span>
                    </Form.Label>
                      <Form.Control
                        className="contact-us-fields m-0 p-0"
                        type="text"
                        // placeholder="First Name"
                        value={firstName}
                        // onChange={handleEmailChange}
                        // isInvalid={!!errors.email}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 mx-4 name-contact"
                      controlId="formBasicLName"
                    >
                        <Form.Label className="text-start m-0 p-0">
                      Last Name <span style={{color:"rgba(255, 0, 0, 1)"}}>*</span>
                    </Form.Label>
                      <Form.Control
                        className="contact-us-fields m-0 p-0"
                        type="text"
                        // placeholder="Last Name"
                        value={lastName}
                        // onChange={handleEmailChange}
                        // isInvalid={!!errors.email}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback> */}
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3 mx-4 mt-4" controlId="formBasicEmail">
                  <Form.Label className="text-start m-0 p-0">
                      Email Address <span style={{color:"rgba(255, 0, 0, 1)"}}>*</span>
                    </Form.Label>
                      <Form.Control
                        className="contact-us-fields m-0 p-0"
                      type="email"
                    //   placeholder="Email Address"
                      value={email}
                      onChange={handleEmailChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="m-3 mx-4 mt-5 mb-3"
                    controlId="formBasicName"
                  >
                    <Form.Label className="text-start mb-3">
                      Enquiry
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your message"
                      value={message}
                      as="textarea"
                      rows={4}
                      onChange={handleMessageChange}
                      isInvalid={!!errors.message}
                      style={{backgroundColor:"rgba(218, 232, 253, 0.5)"}}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mx-4 mb-5">
                    <Button
                      type="submit"
                      className="my-3 px-3"
                      style={{
                        background: "rgba(255, 115, 29, 1)",
                        border: "none",
                        //   width: "100%",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Landing;
