
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const suplierDashboard = createAsyncThunk('suplierDashboard', async () => {
  const response = await fetch('https://cold-hat-40370.botics.co/api/v1/dashboard/', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});

const quotationData = createSlice({
  name: 'dashboard',
  initialState: {
    suplierDashboard: [],
    contractorDashboard: [],
    truckingCompany:[]
  },
  reducers: {},
  extraReducers: {

    [suplierDashboard.pending]: (state, action) => {
      state.loading = true
    },

    [suplierDashboard.fulfilled]: (state, action) => {
      state.suplierDashboard = action.payload
    },

    [suplierDashboard.rejected]: (state, action) => {
      state.loading = true
    },
    
  }
});

export default quotationData.reducer;


