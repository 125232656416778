import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { Image } from 'react-bootstrap'
import profile from "../../assets/Images/profile.png";
import menuProfile from '../../assets/Images/menu-profile.png'
import AppInfo from '../../assets/Images/menu-app-info.png'
import HelpCenter from '../../assets/Images/menu-help-center.png'
import Settings from '../../assets/Images/menu-settings.png'
import InviteFriend from '../../assets/Images/menu-invite-friend.png'
import sendFeedbacks from '../../assets/Images/menu-send-feedback.png'
import SignOut from '../../assets/Images/menu-signout.png'
import { logout } from '../../redux/authSlice';
import { useDispatch, useSelector } from "react-redux"
import { selectIsAuthenticated } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
  const [isShown, setIsShown] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  let navigate = useNavigate();
  const role = localStorage.getItem('role');
  const username =localStorage.getItem('username')
  const onLogout = () => {
    if (!isAuthenticated) navigate('/')
    dispatch(logout())
   
  }


  return (
     <div
      className="d-flex"
      style={{ backgroundColor: "white", width: "20px", height: "auto",zIndex:"9999" }}
    >
      <div style={{ backgroundColor: "#1746A2", width: "auto" }} onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        <div className="my-5" style={{ color: "#fff" }}>
          {isShown && <h5 className="ms-3 my-0"><p>Hello</p></h5>}
          {isShown && <h5 className="ms-3 my-0"><p>{username}</p></h5>}
        </div>
        <ul
          className={isShown?"menu" : "menu my-5 py-5"}
        >
          {/* Add your menu items here */}
          {role !='null' && role !='undefined' && <> <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={menuProfile} alt="" /> {isShown && <span className="mx-4 px-1" onClick={() => navigate("/userprofile")}>Profile</span>}</li>
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={AppInfo} alt="" /> {isShown && <span className="mx-4 px-1">App Info</span>}</li>
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={HelpCenter} alt="" /> {isShown && <span className="mx-4 px-1">Help Center</span>}</li>
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={Settings} alt="" /> {isShown && <span className="mx-4 px-1">Settings</span>}</li>
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={InviteFriend} alt="" /> {isShown && <span className="mx-4 px-1">Invite Friend</span>}</li>
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={SignOut} alt=""  /> {isShown && <span className="mx-4 px-1" onClick={() => navigate("/feedback")} >Send Feedback</span>}</li> </> }
          <li style={{ color: "#fff", cursor: "pointer" }} className="d-flex"><Image className="side-menu-icon my-1" src={sendFeedbacks} alt=""  /> {isShown && <span className="mx-4 px-1" onClick={onLogout}>Signout</span>}</li>

        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
