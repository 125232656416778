import { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../redux/authSlice";
import Logo from "../../assets/Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const response = useSelector((state) => state);
  ;

  useEffect(()=>{
    if(response.user.msg==="password reset link send. Please check your email response from forget"){
      toast.success('Email Sent Sucessfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
    });
    }
  },[])

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;

    setEmail(inputEmail);

    // Clear the error for email field if it becomes valid

    if (errors.email && isEmailValid(inputEmail)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.email;

      setErrors(updatedErrors);
    }
  };
  const isEmailValid = (inputEmail) => {
    // Basic email validation using regular expression

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(inputEmail);
  };
  const body = {
    email: email,
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(forgetPassword(body))
    const validationErrors = {}

    if (!isEmailValid(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)

      return
    }
    setErrors({});
  };
  return (
    <div>
      <ToastContainer/>
      <Card
        className="ms-auto mx-5 my-3"
        style={{
          width: "28rem",
          backgroundColor: "#DADADA",
          textAlign: "left",
          boxShadow: "0px 4px 5px 0px #4B4B4B",
        }}
      >
        <div style={{ backgroundColor: "#1746A2" }}>
          <Card.Img
            variant="top"
            src={Logo}
            className="d-flex my-4 mx-auto"
            style={{ width: "100px" }}
          />
        </div>
        <Card.Body className="p-0 m-0">
          <div className="text-center p-2" style={{ backgroundColor: "#fff" }}>
            <Card.Title>Forget Password</Card.Title>
          </div>
          <Form className="p-4 my-5" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="text-start">
                Enter Email address
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              className="mt-3 mb-5"
              style={{
                background:
                  "linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%)",
                border: "none",
                width: "100%",
              }}
            >
              Submit
            </Button>
          </Form>
          <Link to="/" state={{}}>
            {" "}
            <p className="my-5 text-center" style={{ cursor: "pointer" }}>
              Back to Login
            </p>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ForgetPass;
