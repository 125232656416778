import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createContractorContractsOrderList = createAsyncThunk('createContractorContractsOrderList', async (projectName,supplier) => {
    const response = await fetch (`https://cold-hat-40370.botics.co/contracts/contract-info?project_id=${projectName}&supplier_id=${supplier}`,{
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
    })
    const resData = await response.json();
    return resData;
    });
export const truckingCompanyList = createAsyncThunk('truckingCompanyList', async () => {
    const response = await fetch (`https://cold-hat-40370.botics.co/users/?role=trucking`,{
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
    })
    const resData = await response.json();
    return resData;
    });
export const postTruckingOrder = createAsyncThunk('postTruckingOrder', async (createTruckingOrder) => {
    const response = await fetch (`https://cold-hat-40370.botics.co/trucking_order/`,{
        method: "post",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(createTruckingOrder)
    })
    const resData = await response.json();
    return resData;
    });
    export const getContractorOrderList = createAsyncThunk('getContractorOrderList', async () => {
        const response = await fetch('https://cold-hat-40370.botics.co/contract_order/', {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
          },
        })
        const data = await response.json();
      
        return data;
      });
    export const createContractorOrder = createAsyncThunk('createContractorOrder', async (body) => {
        const response = await fetch ("https://cold-hat-40370.botics.co/contract_order/",{
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(body)
        })
        const resData = await response.json();
        return resData;
        });

    const contractorOrder = createSlice({
        name: 'contractorOrder',
        initialState: {
            projectList: [],
            quotationList: [],
            quotationDetails:[]
        },
        reducers: {},
        extraReducers: {
            [createContractorOrder.pending]: (state, action) => {
                state.loading = true
          
              },
          
              [createContractorOrder.fulfilled]: (state, action) => {
                ;
                state.loading = false
          
              },
          
              [createContractorOrder.rejected]: (state, action) => {
          
                state.loading = true
          
              },
            [truckingCompanyList.pending]: (state, action) => {
                state.loading = true
          
              },
          
              [truckingCompanyList.fulfilled]: (state, action) => {
                state.loading = false
          
              },
          
              [truckingCompanyList.rejected]: (state, action) => {
          
                state.loading = true
          
              },
            [postTruckingOrder.pending]: (state, action) => {
                state.loading = true
          
              },
          
              [postTruckingOrder.fulfilled]: (state, action) => {
                state.loading = false
          
              },
          
              [postTruckingOrder.rejected]: (state, action) => {
          
                state.loading = true
          
              },
        }
    });
    
    export default contractorOrder.reducer
