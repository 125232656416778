/* Author:Tejya pranthi */
/* Description: Todisplay access denied message */

// import React,{useEffect} from 'react';
// import './accessdenied.css';
// import { Link,useNavigate } from 'react-router-dom';
// import { selectIsAuthenticated,addToken } from '../../redux/authSlice';
// import { useSelector, useDispatch } from "react-redux"
// export default function AccessDenied() {
//   let navigate = useNavigate();
  
//   const isAuthenticated = useSelector(selectIsAuthenticated);
//   useEffect(() => {
//      navigate('/')
    
//   }, [navigate, isAuthenticated])

//     return (
//         <div className="access-denied-holder">
//             <div className="access-denied-container">
//                 <h1>Access Denied</h1>
//                 <p>You do not have permission to access requested page</p>
//                 <Link to='/'  >Login</Link>
//             </div>
//         </div>
//     )
// }

import { Navigate } from "react-router-dom";
// const ProtectedRoute = ({ isAuthenticated, children }) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }
//   return children;
// };
// export default ProtectedRoute; 

const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};
export default ProtectedRoute; 