import React, { useState } from 'react';
import DateTimeRangeContainer from 'react-pro-datetimerange-picker';
import moment from 'moment-timezone';
import './Calendar.css';

const Calendar = () => {
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let end = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  ).add(28, 'days');

  const applyCallback = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    start = startDate;
    end = endDate;
    // setIsVisible(false);
  };
  const cancelCallback = (startDate, endDate) => {
    // setStartDate(startDate);
    // setEndDate(endDate);
    // start = startDate;
    // end = endDate;
    setIsVisible(false);
  };

  const isVisibleCallback = (value) => {
    setIsVisible(value);
  };

  let local = {
    format: 'DD-MM-YYYY HH:mm A',
    days: ['Mon', 'Tue', 'Wed', 'The', 'Fri', 'Sat', 'Sun'],
    mondayFirst: true,
    inputGroup: false,
  };
  let minDate = moment(now);

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className="DateTimeRangeMainContainer">
      <div
        className={
          isVisible
            ? 'standaloneDateTimeRangeContainer'
            : 'standaloneDateTimeRangeContainerHidden'
        }
      >
        <p className='text-center calendar-header'>Calendar</p>
        <DateTimeRangeContainer
          start={start}
          end={end}
          local={local}
          minDate={minDate}
          applyCallback={applyCallback}
          cancelCallback={cancelCallback}
          isVisibleCallback={isVisibleCallback}
          twelveHoursClock
          smartMode
          standalone
          style={{
            fromDate: {
              color: '#FFFFFF',
              backgroundColor: '#3380FF',
              borderRadius: '100px 0px 0px 100px',
            },
            betweenDates: {
              backgroundColor: '#3380FF1A',
            },
            toDate: {
              color: '#FFFFFF',
              backgroundColor: '#3380FF',
              borderRadius: '0px 100px 100px 0px',
            },
            hoverCell: {
              backgroundColor: '#3380FF',
              borderRadius: 'none',
            },
            standaloneLayout: {
              display: 'flex',
              minWidth: 'fit-content',
            },
          }}
        ></DateTimeRangeContainer>
        <div className="buttonContainer d-flex justify-content-between p-3">
            <div className='d-flex justify-content-between calendarDetails'>
                <div>
        {
          `${startDate.format('DD-MMM-YYYY')} - ${endDate.format(
            'DD-MMM-YYYY'
          )}`}
                </div>
                <div className='ms-5'>
        {
          `${startDate.format('hh:mm A')} - ${endDate.format(
            'hh:mm A'
          )}`}
                </div>
            </div>
            <div>
                <button className='dtrCancelButtonU mx-4' onClick={()=>document.querySelector(".dtrCancelButton").click()}>CANCEL</button>
                <button className='dtrApplyButtonU ' onClick={()=>document.querySelector(".dtrApplyButton").click()}>APPLY</button>
            </div>

        </div>
      </div>
      {/* <div onClick={() => setIsVisible(true)}>
        {startDate &&
          `${startDate.format('DD-MMM-YYYY, hh:mm A')} - ${endDate.format(
            'DD-MMM-YYYY, hh:mm A'
          )}`}
      </div> */}
    </div>
  );
};

export default Calendar;
