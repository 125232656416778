
import React, { useEffect, useState } from "react";
import './quotaiondetails.css'
import Sidebar from '../../sidebar/Sidebar'
import { useNavigate } from 'react-router-dom';
import { Button, Form, Image, Row, Col } from "react-bootstrap";
import up from '../../../assets/Images/up.svg';
import down from '../../../assets/Images/down.svg'
import { getAllQuotation, getContractor,getProducts,searchByProduct,searchByProject,searchByContractor } from "../../../redux/quotation";
import { useDispatch, useSelector } from "react-redux";
import exportimg from '../../../assets/Images/exportimg.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function Quotaiondetails() {
    const [searchText, setSearchText] = useState("");
    const [searchName, setSearchName] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedRow, setExpandedRow] = useState(null);
    const [quote, setquote] = useState([]);
    const [contractorlist, setcontractorlist] = useState([]);
    const [productList, setproductList] = useState([]);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [quotationList, setquotationList] = useState([]);
    const [next1, setnext1] = useState('');
    const [previous, setprevious] = useState('');
    const [count, setcount] = useState('');
    useEffect(() => {
        dispatch(getAllQuotation('https://cold-hat-40370.botics.co/quote/'))
            .then((result) => {

                const data=result.payload.data.results;

                const filteredData = data.filter(item => item.status === 'REQUESTED');
                const count = filteredData.length;
                setcount(count);
                setquotationList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)

            })
            .catch((errordata) => {

            });

            dispatch(getProducts())
            .then((result) => {
                setproductList(result.payload.data);

                
            })
            .catch((errordata) => {

            });

    }, [dispatch]);

    function requestedQuote(){
        dispatch(getAllQuotation('https://cold-hat-40370.botics.co/quote/'))
        .then((result) => {

            const data=result.payload.data.results;

            const filteredData = data.filter(item => item.status === 'REQUESTED');
            const count = filteredData.length;
            setcount(count);
            setquotationList(filteredData);
        })

    }


    function nextbuttonCLick() {

        dispatch(getAllQuotation(next1))
            .then((result) => {
                setquotationList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    function previousbuttonCLick() {
        dispatch(getAllQuotation(previous))
            .then((result) => {
                setquotationList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    function editClick(editid) {

        navigate(`/viewdetails/${editid}`)
    }

    useEffect(() => {
        dispatch(getContractor())
            .then((result) => {
                
                setcontractorlist(result.payload.data);
            })
            .catch((error) => {
                console.log(error)
            });

    }, []);
    useEffect(() => {
        getdata()

    }, [quotationList, next1, previous, contractorlist]);
    var moment = require('moment');
    function getdata() {
        
        var tempaddressVal = [];

        if (quotationList != '' && quotationList != null && quotationList != undefined) {
            var productlist = [];
            quotationList.map((l, m) => {
                var tempObj = {};
                l.products.map((k, p) => {
                    if (k.id != '') {
                        tempObj.id = k.id;
                        tempObj.product = k.name;
                    }
                });

                productlist.push(tempObj);
            })

            // setproductList(productlist);


            quotationList.map((i, j) => {
                var tempObj = {};
                tempObj.id = i.id;
                tempObj.action = i.action;



                tempObj.products = i.products.map((k, l) => k.name).join(', ');
                if (i.products.map((k, l) => k.product).length > 1) {
                    tempObj.price = 'Multiple products';
                    tempObj.unit = '';
                } else {
                    tempObj.unit = i.products.map((k, l) => k.unit);
                    tempObj.price = i.products.map((k, l) => k.price);
                }

                if (i.products.map((k, l) => k.product).length > 1) {
                    tempObj.quantity = 'Multiple'
                } else {
                    tempObj.quantity = i.products.map((k, l) => k.quantity);
                }
                // tempObj.contractorid = i.contractor
                // tempObj.contractor = contractorlist.filter((p) => p.id == i.contractor).map((item) => item.username)[0];

                tempObj.contractorid = i.contractor.id
                tempObj.contractor = i.contractor.username;
                tempObj.productlist = i.products;
                tempObj.project = i.project.name;

                tempObj.quotedate = moment((i.created)).format("MM/DD/YYYY");
                tempObj.status = i.status;
                tempObj.start_date = moment((i.start_date)).format("MM/DD/YYYY");
                tempObj.end_date = moment((i.end_date)).format("MM/DD/YYYY");
                tempObj.terms = i.terms;


                tempaddressVal.push(tempObj);
            })
            setquote(tempaddressVal)
        }
        
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);

        dispatch(searchByProject(e.target.value))
        .then((result) => {

            const data=result.payload.data.results;

            setquotationList(result.payload.data.results);
            setnext1(result.payload.data.next)
            setprevious(result.payload.data.previous)
        })
        .catch((errordata) => {

        });
    };

    const handleDropdownChange = (e) => {
        ;
        setSearchName(e.target.value);

        dispatch(searchByContractor(e.target.value))
        .then((result) => {

            const data=result.payload.data.results;

            setquotationList(result.payload.data.results);
            setnext1(result.payload.data.next)
            setprevious(result.payload.data.previous)
        })
        .catch((errordata) => {

        });
    };

    const productDropdownChange = (e) => {
        setSearchName(e.target.value);

        dispatch(searchByProduct(e.target.value))
        .then((result) => {

            const data=result.payload.data.results;

            setquotationList(result.payload.data.results);
            setnext1(result.payload.data.next)
            setprevious(result.payload.data.previous)
        })
        .catch((errordata) => {

        });

    }

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    // const filteredData = quote.filter((item) => {

    //     if (searchName) {
    //         return item.project === searchName || item.contractorid == searchName || item.productlist.filter((p) => p.name == searchName)[0];

    //     } else {
    //         return (
    //             item.project.toLowerCase().includes(searchText.toLowerCase()) ||
    //             item.project.toString().includes(searchText) ||
    //             item.project.toLowerCase().includes(searchText.toLowerCase())
    //         );
    //     }
    // });

    // const sortedData = filteredData.sort((a, b) => {
    //     
    //     if (sortColumn === "project") {
    //         return sortDirection === "asc"
    //             ? a.project.localeCompare(b.project)
    //             : b.project.localeCompare(a.project);
    //     } else if (sortColumn === "contractor") {
    //         return sortDirection === "asc" ? a.contractor - b.contractor : b.contractor - a.contractor
    //     } else {
    //         return 0;
    //     }
    // });
    return (
        <>
            <div style={{ display: "flex" }}>
                <Sidebar />

                <div className="dashboardtable">
                    <div className="d-flex justify-content-between">
                        <span className='headertext'>Quotes</span>
                        <Button className='quotebtncss px-4' type="submit" onClick={() => navigate("/createquotaion")}  > Create new quote</Button>

                    </div>

                    <hr></hr>
                    <Row className="topsearchbar">

                        <Col className="col-search" lg={2}>
                            <label className="input-container">
                                <Form.Control
                                    type="text"
                                    className="search-form"
                                    value={searchText}
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                />
                                <FontAwesomeIcon className="fa-search" icon={faMagnifyingGlass} />
                            </label>
                        </Col>

                        <Col lg={2}>
                            <Form.Select value={searchName} onChange={handleDropdownChange}>
                                <option value="">Contractor</option>
                                {contractorlist.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.username}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>

                        <Col lg={2}>
                            <Form.Select value={searchName} onChange={productDropdownChange}>
                                <option value="">Products</option>
                                {productList.map((item) => (
                                    <option key={item.name} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>

                        <Col lg={2} className="exportimage">
                                <Image src={exportimg} style={{ marginRight: '10px', cursor: 'pointer' }} />Export
                        </Col>

                        <Col lg={{ offset: 2, span: 2 }}>
                            <div className="quotecountflex">
                            <Button className='quotebtncss px-4' type="submit"   onClick={() => requestedQuote()}> Quote Requests</Button>
                            {count!=0 &&<span className="e-badge e-badge-info e-badge-notification e-badge-overlap quotecount" >{count}</span>}
                            </div>
                        </Col>
                    </Row>

                    <div className="table-container">
                        <table id="data-table" className="tableborder">
                            <thead>
                                <tr >
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Project Id
                                    </th>
                                    <th className="projectnamecss" onClick={() => handleSort("project")}>
                                        Project Name
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("contractor")}>
                                        Contractor
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("product")}>
                                        Products
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("price")}>
                                        Quote Price
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("date")}>
                                        Quote Date
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("quantity")}>
                                        Qty
                                    </th>
                                    <th className="projectnamecss">Status</th>
                                    <th className="tableborder" style={{ width: '14%' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {quote.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr className="quotestatus" data-status={item.status}>
                                            <td className="d-flex" onClick={() => handleExpand(item.id)} >
                                                <span className="ms-2" style={{ color: '#2F80ED', marginRight: '5px' }}>{item.id}</span>
                                                <span className="expand-trigger">
                                                    {expandedRow === item.id ?
                                                        <Image src={up} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer' }} />
                                                        :
                                                        <Image src={down} style={{ marginTop: '2%', marginBottom: '2%', cursor: 'pointer' }} />}
                                                </span>
                                            </td>
                                            <td>{item.project}</td>
                                            <td>{item.contractor}</td>
                                            <td>{item.products}</td>
                                            {item.unit && item.price !='' && item.price !='undefined' ? <td>${item.price}/{item.unit}</td> : <td>{item.price}</td>}
                                            <td>{item.quotedate}</td>
                                            <td>{item.quantity} <span className="mx-1">{item.unit}</span></td>
                                            {expandedRow === item.id ? <><td></td><td></td></> : (<>
                                                <td>
                                                    {item.status === 'SUBMITTED' &&
                                                        <Button className='quotebtncss2 px-4' type="submit" > Submitted</Button>}
                                                    {item.status === 'PENDING' && <Button className='quotebtncss1' type="submit" > Pending for approval</Button>}
                                                    {item.status === 'REQUESTED' && <Button className='quotebtncss3' type="submit" >Requested  Quote</Button>}
                                                </td>
                                                <td>
                                                    {/* <Button className='editbtn' type="submit" onClick={() => editClick(item.id)}> Edit</Button> */}
                                                    {item.status === 'SUBMITTED' && <Button className='editbtn' type="submit" onClick={() => editClick(item.id)}> Edit</Button>}
                                                    {item.status === 'REQUESTED' && <Button className='acceptbtn' type="submit" onClick={() => editClick(item.id)} >Accept</Button>}
                                                </td></>)}

                                        </tr>
                                        {expandedRow === item.id && (
                                            <React.Fragment
                                            >
                                                <tr className="tdBackgroundColor">
                                                    <td >Project Id</td>
                                                    <td className="ps-5"><b>{item.id}</b></td>
                                                    <td colSpan={1}></td>
                                                    <td colSpan={6}>  <tr className="tdBackgroundColor">
                                                        <td colSpan={6} style={{ width: '150px' }} ><b>Products</b></td>
                                                        <td colSpan={1} className="ps-2"><b>Quote</b></td>
                                                        <td colSpan={3} className="ps-5"><b>Terms</b></td></tr></td>

                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Project Name</td>
                                                    <td className="ps-5">
                                                        <b>{item.project}</b>
                                                    </td>
                                                    <td colSpan={1}></td>
                                                    <td colSpan={6}>
                                                        {item.productlist.map((productitem) => (<>
                                                            <tr className="tdBackgroundColor">
                                                                <td colSpan={6} style={{ width: '150px' }} ><b>{productitem.name} - {productitem.quantity}{productitem.unit}</b> </td>
                                                                <td colSpan={1} className="ps-2"><b>${productitem.price}/{productitem.unit} </b></td>
                                                                <td colSpan={3} className="ps-5"><b>{productitem.terms}</b></td>
                                                            </tr>
                                                        </>))}
                                                    </td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Contractor</td>
                                                    <td className="ps-5">
                                                        <b>{item.contractor}</b>
                                                    </td>
                                                    <td colSpan={7}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Quote Date</td>
                                                    <td className="ps-5"><b>{item.quotedate}</b></td>
                                                    <td colSpan={7}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Status</td>
                                                    <td className="ps-5">
                                                        {item.status === 'SUBMITTED' &&
                                                            <Button className='quotebtncss2 px-4' type="submit" > Submitted</Button>}
                                                        {item.status === 'PENDING' && <Button className='quotebtncss1' style={{width:"150px"}} type="submit" > Pending for approval</Button>}
                                                        {item.status === 'REQUESTED' && <Button className='quotebtncss3' style={{width:"150px"}} type="submit" >Requested  Quote</Button>}
                                                    </td>
                                                    <td colSpan={7}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">

                                                    <td>  {item.status === 'SUBMITTED' &&  <Button className='editbtncss' type="submit" onClick={() => editClick(item.id)}> Edit</Button>}
                                                        {item.status === 'REQUESTED' && <Button className='acceptbtn' onClick={() => editClick(item.id)} type="submit" >Accept</Button>}</td>
                                                    <td> <Button className='editbtncss' type="submit"  >View history</Button></td>
                                                    <td colSpan={7}></td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}

                            </tbody>
                        </table>
                        <div className="pagination">
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit" disabled={next1 != '' && next1 != 'null' && next1 != undefined ? false : true} onClick={() => nextbuttonCLick()}>Next</Button>
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit" disabled={previous != '' && previous != 'null' && previous != undefined ? false : true} onClick={() => previousbuttonCLick()} >Previous</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
