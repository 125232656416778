import React from 'react'
import { Card, Button, Form } from "react-bootstrap";
import Logo from '../../assets/Images/logo.png';
import { Link, useNavigate } from 'react-router-dom';


const EmailVerified = () => {
    let navigate = useNavigate();

  return (
    <div><Card
    className="ms-auto mx-5 my-3"
    style={{
      width: "28rem",
      backgroundColor: "#DADADA",
      textAlign: "left",
      boxShadow: "0px 4px 5px 0px #4B4B4B",
    }}
  >
    <div style={{ backgroundColor: "#1746A2" }}>
      <Card.Img
        variant="top"
        src={Logo}
        className="d-flex my-4 mx-auto"
        style={{ width: "100px" }}
      />
    </div>
      <div className="text-center p-2" style={{ backgroundColor: "#fff" }}>
        <Card.Title>Forget Password</Card.Title>
      </div>
    <Card.Body className="p-0 mx-5 my-5">
    <p className='p-3  text-center'>We have sent instructions to reset your password to the email address associated with your account. Please check your inbox and spam/junk folders for an email from us.</p>
      <Button
        type='submit'
        className='mt-3 mb-5'
        style={{
          background:
            "linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%)",
          border: "none",
          width: "100%",
        }}
        onClick={()=>navigate("/")}
      >
        Go to Login
      </Button>
     </Card.Body>

  </Card></div>
  )
}

export default EmailVerified