import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const createContractorQuotation = createAsyncThunk('contractorQuotation', async (body) => {
const response = await fetch ("https://cold-hat-40370.botics.co/quote/",{
    method: "post",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(body)
})
const resData = await response.json();
return resData;
});
export const acceptContractorQuotation = createAsyncThunk('acceptContractorQuotation', async (acceptBody) => {
const response = await fetch (`http://cold-hat-40370.botics.co/quote/11/accept-reject`,{
    method: "PUT",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(acceptBody)
})
const resData = await response.json();
return resData;
});

export const getSupplier = createAsyncThunk('getSupplier', async () => {
  const response = await fetch('https://cold-hat-40370.botics.co/users/?role=supplier', {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${localStorage.getItem('token')}`
    },
  })
  const data = await response.json();

  return data;
});


const contractorQuotation = createSlice({
    name: 'data',
    initialState: {
        projectList: [],
        quotationList: [],
        quotationDetails:[]
    },
    reducers: {},
    extraReducers: {
        [createContractorQuotation.pending]: (state, action) => {
            state.loading = true

          },

          [createContractorQuotation.fulfilled]: (state, action) => {
            ;
            if(action.payload.developer_message==="Request was successful"){
              toast.success('Sucessfully Submitted!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: true,
            });
            }

          },

          [createContractorQuotation.rejected]: (state, action) => {

            state.loading = true

          },
        [acceptContractorQuotation.pending]: (state, action) => {
            state.loading = true

          },

          [acceptContractorQuotation.fulfilled]: (state, action) => {
            // ;
            state.loading = false
          },

          [acceptContractorQuotation.rejected]: (state, action) => {
            state.loading = true
          },
          [getSupplier.pending]: (state, action) => {
            state.loading = true

          },

          [getSupplier.fulfilled]: (state, action) => {
            state.quotationDetails = action.payload
          },

          [getSupplier.rejected]: (state, action) => {
            state.loading = true
          },


    }
});

export default contractorQuotation.reducer
