import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Redirect } from 'react-router-dom'
import Signup from './components/sigup/Signup';
import Login from './components/login/Login';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Upload from './components/upload/Upload';
import Calendar from './components/calendar/Calendar';
import Dashboard from './components/dashboard/Dashboard';
import ForgetPass from './components/login/ForgetPass';
import ResetPass from './components/login/ResetPass';
import { selectIsAuthenticated } from './redux/authSlice';
import { useSelector } from 'react-redux';
import EmailVerified from './components/login/EmailVerified';
import ProtectedRoute from './components/AccessDenied/accessdenied';
import Quotaiondetails from './components/Quotation/QuotationDetails/quotaiondetails';
import CreateQuotaion from './components/Quotation/CreateQuotation/createquotaion';
import ContractorQuotesDashboard from './components/contractorDashboard/ContractorQuotesDashboard';
import ContractorOrders from './components/contractorDashboard/ContractorOrders';
import Feedback from './components/feedback/Feedback';
import ChangePassword from './components/profile/changePassword/ChangePassword';
import Landing from './components/landing/Landing';
import ContractDashboard from './components/quoteContracts/contractDashboard/ContractDashboard';
import CreateContracts from './components/quoteContracts/createContracts/CreateContracts';
import OrdersDashboard from './components/orders/ordersDashboard/OrdersDashboard';
import ViewDetails from './components/Quotation/ViewDetails/ViewDetails';
import CreateProfile from './components/User/Profile/CreateProfile/CreateProfile';
import UpdateProfile from './components/User/Profile/UpdateProfile/UpdateProfile';
import UploadPhoto from './components/User/Profile/UploadPhoto/UploadPhoto';
import MessageScreen from './components/MessageScreen/MessageScreen';
import ProfileInformation from './components/User/Profile/ProfileInformation/ProfileInformation';
import Projects from './components/project/Projects';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import TermsNCondition from './components/termsncondition/TermsNCondition';
import TruckingCompanyOrder from './components/truckingOrderManagement/TruckingCompanyOrder';
import ContractorDashboard from './components/contractorDashboard/contractorDashboard/contractorDashboard';
import TruckingDashboard from './components/truckingDashboard/TruckingDashboard';
import ScheduleContractor from './components/schedules/ScheduleContractor';
import ScheduleSupplier from './components/schedules/ScheduleSupplier';
import UpdateContract from './components/quoteContracts/updateContract/UpdateContract';
import ContractorContract from './components/contractorContract/contractorContract/ContractorContract';
import CreateContractorContract from './components/contractorContract/createContract/createContract';
import CreateSchedule from './components/schedules/CreateSchedule';
import ChatWrapper from './components/Chat/chatWrapper';


function App() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  

  return (

    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path='/signup' element={<Signup />} />
          <Route exact path='/chat' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ChatWrapper /></ProtectedRoute>} />
          <Route exact path='/' element={<Login />} />
          <Route exact path='/forgetpassword' element={<ForgetPass />} />
          <Route exact path='/upload' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Upload /></ProtectedRoute>} />
          <Route exact path='/feedback' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Feedback /></ProtectedRoute>} />
          <Route exact path='/changepassword' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ChangePassword /></ProtectedRoute>} />
          <Route path="/quotationDashboard" element={ <ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}> <Dashboard /></ProtectedRoute>}/>
          <Route path="/contractorDashboard" element={ <ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}> <ContractorDashboard/> </ProtectedRoute>}/>
          <Route path="/quotaiondetails" element={ <ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Quotaiondetails /></ProtectedRoute>}/>
          <Route path="/ordersdashboard" element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><OrdersDashboard /></ProtectedRoute>}/>
          <Route exact path='/contractdashboard' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ContractDashboard /></ProtectedRoute>} />
          <Route exact path='/userprofile' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><CreateProfile /></ProtectedRoute>} />
          <Route exact path='/editprofile' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><UpdateProfile /></ProtectedRoute>} />
          <Route exact path='/profileinformation' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ProfileInformation /></ProtectedRoute>} />
          <Route exact path='/viewdetails/:id' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ViewDetails /></ProtectedRoute>} />
          <Route exact path='/messagescreen' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><MessageScreen /></ProtectedRoute>} />
          <Route exact path='/landing' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Landing /></ProtectedRoute>} />
          <Route exact path='createquotaion' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><CreateQuotaion /></ProtectedRoute>} />
          <Route exact path='contractorquotaiondashboard' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ContractorQuotesDashboard /></ProtectedRoute>} />
          <Route exact path='/calendar' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Calendar /></ProtectedRoute>} />
         
          <Route exact path='/emailverified' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><EmailVerified /></ProtectedRoute>} />
          <Route exact path='/resetPassword?/:token' element={< ResetPass />} />
          <Route exact path='/contractororders' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}>< ContractorOrders /></ProtectedRoute>} />
          <Route exact path='/createcontracts/:id' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}>< CreateContracts /></ProtectedRoute>} />
          <Route exact path='/privacypolicy' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}>< PrivacyPolicy /></ProtectedRoute>} />
          <Route exact path='/termsncondition' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}>< TermsNCondition /></ProtectedRoute>} />
          <Route exact path='/truckingcompanyorder' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}>< TruckingCompanyOrder /></ProtectedRoute>} />
          <Route exact path='/truckingdashboard' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><TruckingDashboard /></ProtectedRoute>} />
          <Route exact path='/schedulecontractor' element={<ScheduleContractor/>} />
          <Route exact path='/schedulesupplier' element={<ScheduleSupplier/>} />
          <Route exact path='/uploadphoto'  element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><UploadPhoto /></ProtectedRoute>} />
          <Route exact path='/ContractorContract'  element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><ContractorContract /></ProtectedRoute>} />
          <Route exact path='/projects'  element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><Projects /></ProtectedRoute>} />
          <Route exact path='/CreateContractorContract/:id'  element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><CreateContractorContract /></ProtectedRoute>} />
          <Route exact path='/createschedule'  element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><CreateSchedule /></ProtectedRoute>} />         
          <Route exact path='/updatecontract/:id1/:id2' element={<ProtectedRoute isAuthenticated={localStorage.getItem('isAuthenticated')}><UpdateContract /></ProtectedRoute>} />
        
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
