
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllContract = createAsyncThunk('getAllContract', async () => {
    const response = await fetch('https://cold-hat-40370.botics.co/quote/contract-requested', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    })
    const data = await response.json();
  
    return data;
  });

  export const getContractList = createAsyncThunk('getContractList', async (url) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    })
    const data = await response.json();
  
    return data;
  });

  export const searchBycontract = createAsyncThunk('searchBycontract', async (value) => {
    const response = await fetch(`https://cold-hat-40370.botics.co/contracts/?search=${value}`, {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    })
    const data = await response.json();
  
    return data;
  });


  export const getContractById = createAsyncThunk('getContractById', async (id) => {
    const response = await fetch(`https://cold-hat-40370.botics.co/quote/${id}`, {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    })
    const data = await response.json();
  
    return data;
  });

  export const getContractByContractId = createAsyncThunk('getContractByContractId', async (id) => {
    const response = await fetch(`https://cold-hat-40370.botics.co/contracts/${id}`, {
      method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    })
    const data = await response.json();
  
    return data;
  });

const quotationData = createSlice({
  name: 'contract',
  initialState: {
    contractList: [],
  },
  reducers: {},
  extraReducers: {

    [getAllContract.pending]: (state, action) => {
      state.loading = true
    },

    [getAllContract.fulfilled]: (state, action) => {
      state.contractList = action.payload
    },

    [getAllContract.rejected]: (state, action) => {
      state.loading = true
    },
    
    
  }
});

export default quotationData.reducer;


