import React, { useState, useRef, useEffect } from 'react'
import { Form, Button, Image } from 'react-bootstrap';
import Sidebar from '../../../sidebar/Sidebar';
import './UpdateProfile.css';
import Camera from '../../../../assets/Images/Camera.svg'
import Upload_icon from '../../../../assets/Images/Upload_icon.svg'
import { useSelector, useDispatch } from "react-redux"
import { getUserProfile, updateProfile, uploadPhoto } from '../../../../redux/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

export default function UpdateProfile() {
    const dispatch = useDispatch()

    const location = useLocation();
    const userdata = location.state?.userdata || [];
    const navigate = useNavigate()
    
    const [validated, setValidated] = useState(false);
    const updateddata = useSelector((state) => state.user.updateddata);
    const [gender, setgender] = useState('');
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        address: '',
        city: '',
        zipcode: '',
        state: '',
        country: ''
    });



    useEffect(() => {

        dispatch(getUserProfile())

        formData.username = userdata.username;
        formData.email = userdata.email;
        formData.first_name = userdata.first_name;
        formData.last_name = userdata.last_name;
        formData.dob = userdata.dob;
        formData.gender = userdata.gender;
        formData.address = userdata.address;
        formData.city = userdata.city;
        formData.state = userdata.state;
        formData.country = userdata.country;
        formData.zipcode = userdata.zipcode;
        setgender(userdata.gender)

        ;
        setSelectedImage(userdata.avatar);

    }, []);


    const handleSelect = (event) => {

        setgender(event.target.value)
    };

   

    const handleImageUpload = (event) => {
        const formData1 = new FormData()
        const file = event.target.files[0];
        if (file) {
            ;
            setSelectedImage(URL.createObjectURL(file));
            formData1.append("avatar", file)
            
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://cold-hat-40370.botics.co/api/v1/uploadpicture/',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'token fdc37e5186764d50b10313fc6123aa8a682d9490',
                },
                data: formData1
            };

            axios.request(config)
                .then((response) => {
                    ;
                    setSelectedImage(response.avatar);
                })
                .catch((error) => {
                    console.log(error);
                });



        } else {
            setSelectedImage(null);
        }
    };
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    var moment = require('moment');
    const handleFormSubmit = (event) => {

        event.preventDefault();

        const validationErrors = {};

        const form = event.currentTarget;
        if (form.checkValidity()) {
            // Perform form submission or other actions here
            formData.gender = gender;
            formData.dob = moment((formData.dob)).format("YYYY-MM-DD");;
            formData.last_name = formData.first_name;

            dispatch(updateProfile(formData))
            window.scrollTo(0, 0);

        } else {
            event.stopPropagation();
        }
        setValidated(true);


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className='profileclass'>
                <div className="profile-container">
                    <span className='profiletext'>Update Profile</span>
                </div>

                <div className="table-container">

                    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                    <ToastContainer />
                        <div className=" col-sm-8">
                            <div className="row ">
                                <div className='header-profile'>
                                    <div>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleImageUpload}
                                        />
                                        {!selectedImage && <Image src={Upload_icon} alt="Image" style={{ width: '70px', height: '70px', cursor: 'pointer' }} onClick={handleButtonClick} />}

                                        {selectedImage && <Image src={selectedImage} alt="User Photo" roundedCircle style={{ width: '70px', height: '70px', cursor: 'pointer' }} />}
                                        {selectedImage && <span className="e-badge e-badge-info e-badge-notification e-badge-overlap cameraimage" ><Image src={Camera} alt="Image" style={{ width: '26px', height: '30px', cursor: 'pointer' }} onClick={handleButtonClick} /></span>}
                                        <span className='profiletextcss'>{formData.username}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Full Name"
                                            value={formData.first_name}
                                            name='first_name'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter full name</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Street Address</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Street Address"
                                            value={formData.address}
                                            name='address'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter address</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Username"
                                            value={formData.username}
                                            name='username'
                                            disabled
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter username</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter City"
                                            value={formData.city}
                                            name='city'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter city</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Email"
                                            defaultValue={formData.email}
                                            name='email'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please Enter Email</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            placeholder="Enter Zip Code"
                                            value={formData.zipcode}
                                            name='zipcode'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control
                                            type='date'
                                            className='textcontainer'
                                            placeholder="Enter Zip Code"
                                            name='dob'
                                            onChange={handleChange}
                                            value={formData.dob}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please select dob</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type='text'
                                            required
                                            name='state'
                                            className='textcontainer'
                                            placeholder="Enter State"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter state</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Gender</Form.Label>

                                        <Form.Control
                                            as="select"
                                            required
                                            name='gender'
                                            value={gender}
                                            onChange={handleSelect}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select an gender
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-sm">
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type='text'
                                            className='textcontainer'
                                            name='country'
                                            placeholder="Enter Country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter dob country</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                        </div>
                        <Button className='quotebtncss' type="submit"  >SAVE CHANGES</Button>
                    </Form>

                </div>
            </div>



        </div>
    )
}
