import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {Card,Button,Form} from "react-bootstrap";
import { useDispatch,useSelector } from "react-redux";
import { resetPassword } from "../../redux/authSlice";
import Logo from '../../assets/Images/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

const ResetPass = () => {
  let { token } = useParams();
  const dispatch = useDispatch()
  const [tokenKey,setTokenKey] = useState("");
  const [tokenId,setTokenId] = useState("");
  const [password,setPassword]= useState("")
  const [rePassword,setRePassword] = useState("")
  const [errors, setErrors] = useState("");
  const response = useSelector(state => state?.user?.detail)


  useEffect(()=>{
    setTokenId(token.split("-")[2])
    setTokenKey(token.split("-")[0]+"-"+token.split("-")[1])
  },[])
  const body ={
    new_password1: password,
    new_password2: rePassword,
    uid: tokenId,
    token: tokenKey
}
const handlePasswordChange = (event) => {
    // if (event.target.value.length > 0) {
    //   setShowEyePass(true);
    // } else {
    //   setShowEyePass(false);
    // }
    const inputPassword = event.target.value;

    setPassword(inputPassword);
    // Clear the error for password field if it becomes non-empty

    if (errors.password && inputPassword.trim().length > 8) {
      const updatedErrors = { ...errors };

      delete updatedErrors.password;

      setErrors(updatedErrors);
    }
  };
const handleConfirmPasswordChange = (event) => {
    // if (event.target.value.length > 0) {
    //   setShowEye(true);
    // } else {
    //   setShowEye(false);
    // }
    const inputPassword = event.target.value;

    setRePassword(inputPassword);

    // Clear the error for password field if it becomes non-empty

    if (errors.confirm_password && inputPassword.trim().length > 8) {
      const updatedErrors = { ...errors };

      delete updatedErrors.confirm_password;

      setErrors(updatedErrors);
    }
    if (errors.confirm_password_matches && inputPassword===password) {
      const updatedErrors = { ...errors };

      delete updatedErrors.confirm_password_matches;

      setErrors(updatedErrors);
    }
  };
  const handleSubmit =(e)=>{
    e.preventDefault()
    dispatch(resetPassword(body))
    
    const validationErrors = {};
    if (!isPasswordValid(password)) {
        validationErrors.password = "Please enter a valid password";
      }
  
      if (!isConfirmPasswordValid(rePassword)) {
        validationErrors.confirm_password = "Please enter a valid re password";
      }
      if(!isPasswordMatches(rePassword)){
        validationErrors.confirm_password_matches = "Password and Confirm does not match";
      }
      if (Object.keys(validationErrors).length === 0){
        setPassword("")
        setRePassword("")
      }
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
  
        return;
      }
    setErrors({});
  }
  const isPasswordValid = (inputPassword) => {
    // Implement your own password validation logic

    return inputPassword.trim().length > 8;
  };
  const isConfirmPasswordValid = (inputPassword) => {
    // Implement your own password validation logic

    return inputPassword.trim().length > 8  
  };
  const isPasswordMatches =(inputPassword)=>{
    return inputPassword ===password
  }
  return (
    <>
    <div>
    <Card
        className="ms-auto mx-5 my-3"
        style={{
          width: "28rem",
          backgroundColor: "#DADADA",
          textAlign: "left",
          boxShadow: "0px 4px 5px 0px #4B4B4B",
        }}
      >
        <div style={{ backgroundColor: "#1746A2" }}>
          <Card.Img
            variant="top"
            src={Logo}
            className="d-flex my-4 mx-auto"
            style={{ width: "100px" }}
          />
        </div>
        <Card.Body className="p-0 m-0">
          <div className="text-center p-2" style={{ backgroundColor: "#fff" }}>
            <Card.Title>Reset Password</Card.Title>
          </div>
         {!errors.password && <ToastContainer />} 
          <Form className="p-4 my-4" onSubmit={handleSubmit}>
            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label className="text-start">Enter Password</Form.Label>
              <Form.Control type="password"
               placeholder="Enter Password" 
               value={password}
               onChange={handlePasswordChange}
               isInvalid={!!errors.password}/>
               <Form.Control.Feedback type="invalid">
                 {errors.password}
               </Form.Control.Feedback>            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicRePassword">
              <Form.Label className="text-start">Enter Re-Password</Form.Label>
              <Form.Control type="password" 
              placeholder="Enter Re-Password" 
              value={rePassword}
                onChange={handleConfirmPasswordChange}
                isInvalid={!!errors.confirm_password || !!errors.confirm_password_matches}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirm_password_matches ||errors.confirm_password}
              </Form.Control.Feedback>
            </Form.Group>
          <Button
          className="mb-4"
            type='submit'
            style={{
              background:
                "linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%)",
              border: "none",
              width: "100%",
            }}
          >
            Submit
          </Button>
          </Form>
          <Link to="/" state={{}}>    <p className="my-5 text-center" style={{cursor:"pointer"}}  >Back to Login</p></Link>
          {/* <p className="my-2 text-center">Not registered yet? <span style={{fontWeight:"bold",textDecoration:"underline"}}>Sign up</span></p> */}
        </Card.Body>
      </Card>
    </div>
      {/* <div>Token-{tokenKey} </div>
      <div>Uid -{tokenId} </div>
      <div>Password -{password} </div>
      <div>Re-Password -{rePassword} </div> */}
    </>
  );
};

export default ResetPass;
