
import React, { useState, useRef } from 'react';
import Upload_icon from '../../../../assets/Images/Upload_icon.svg'
import { Card, Button, Form, Image } from "react-bootstrap";
import "./UploadPhoto.css";

const UploadPhoto = () => {


    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="d-flex">
            <Card
                className="mx-auto my-5 uploadcard"
                style={{ width: "40%",  border: " 1px solid #FFFFFF", }}
            >

                <Card.Body className="my-5 py-4">

                    <Form className="my-5">
                   <div className='uploadedPhoto'>{selectedImage && <img src={selectedImage} alt="Uploaded" />}</div> 
                        <div className='imageContainer my-5 py-4'>
                      
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            <Image src={Upload_icon} alt="Image" className='uploadimage' onClick={handleButtonClick} /><br></br>
                            <span>Drag & drop files or Browse</span>
                        </div>

                    </Form>
                </Card.Body>:



            </Card>

        </div>
    );
};

export default UploadPhoto;



