import React, { useState, useEffect } from 'react'
import Sidebar from '../sidebar/Sidebar'
import './TruckingDashboard.css'
import { useSelector, useDispatch } from "react-redux"
import DashboardHeader from '../dashboardHeader/DashboardHeader'
import { selectIsAuthenticated, addToken } from '../../redux/authSlice';
import { Card, Button, Form, Image, Container } from "react-bootstrap";
import quotes from '../../assets/Images/quotes.svg'
import schedules from '../../assets/Images/schedules.svg'
import orders from '../../assets/Images/orders.svg'
import contracts from '../../assets/Images/contracts.svg'
import { suplierDashboard } from '../../redux/dashboard'

export default function TruckingDashboard() {
  const dispatch = useDispatch()
  const [token, settoken] = useState(localStorage.getItem('token'));
  const [order, setorder] = useState('');
  const [schedule, setschedule] = useState('');

  useEffect(()=>{
    dispatch(suplierDashboard())
    .then((result) => {
      const data=result.payload;
      setorder(data.order_count)

    })
  })
  return (
    <div >
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div className='dashboardtable ' >
          <div className="d-flex justify-content-between">
            <span className='headertext'>Dashboard</span>
          </div>
          <hr></hr>
          <Container className='TruckingDashboard'>
          <div className='dashboardflex '>
         

            <Card className='dashboardcard'
            >
              <Card.Body className='dashboardbody'>
                <div>
                  <Image src={orders} alt="Image" className='dashboardimage' />
                </div>
                <div className='ms-2'>
                  <div className='txtcss'>Orders</div>
                  <div className='numbercss'>{order}</div>
                </div>
              </Card.Body>
            </Card> 
            <Card className='dashboardcard'
            >
              <Card.Body className='dashboardbody'>
                <div>
                  <Image src={schedules} alt="Image" className='dashboardimage' />
                </div>
                <div className='ms-2'>
                  <div className='txtcss'>Schedules</div>
                </div>
              </Card.Body>
            </Card> 

          </div>
          </Container>
        </div>

      </div>
    </div>
  )
}
