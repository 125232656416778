import { useState, useEffect } from "react";
import { Row, Button, Form, Col } from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import { feedbackuser } from "../../redux/feedBack";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Feedback = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const isEmailValid = (inputEmail) => {
    // Basic email validation using regular expression

    const emailRegex = /^([\w\-]+\.?){0,2}[\w\-]+@[\w.\-]+$/;

    return emailRegex.test(inputEmail);
  };
  const isMessageValid = (message) => {
    // Implement your own password validation logic

    return message.trim().length > 8;
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;

    setEmail(inputEmail);

    // Clear the error for email field if it becomes valid

    if (errors.email && isEmailValid(inputEmail)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.email;

      setErrors(updatedErrors);
    }
  };
  const handleMessageChange = (event) => {
    const inputMessage = event.target.value;

    setMessage(inputMessage);

    // Clear the error for email field if it becomes valid

    if (errors.message && isMessageValid(message)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.message;

      setErrors(updatedErrors);
    }
  };
  const body={
    email:email,
    message:message
  }
  const handleFormSubmit = (event) => {
    event.preventDefault();
    ;
    
    // Perform validation

    const validationErrors = {};

    if (!isEmailValid(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!isMessageValid(message)) {
      validationErrors.message = "Please enter a valid feedback Message of length greater than 8.";
    }
    //Set validation errors if any

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    }
    if (Object.keys(validationErrors).length === 0) {
      dispatch(feedbackuser(body))
      //   toast.success('Signup Successful!', {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //   });
      setEmail("");
      setMessage("");
    }
    // Form is valid, perform login logic here

    // Reset form fields and errors

    setErrors({});
  };


  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div className="dashboardtable">
        <div className="d-flex justify-content-between">
          <h3>Feedback</h3>
        </div>
        <ToastContainer />
        <hr></hr>
        <div className="mb-5">
          <Row>
            <Col lg={4}>
              <Form onSubmit={handleFormSubmit} style={{height:"420px"}}>
                <Form.Group className="mb-3 mx-4" controlId="formBasicEmail">
                  <Form.Label className="text-start m-0 p-0">
                    <b>Email address</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={handleEmailChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m-3 mx-4" controlId="formBasicName">
                <Form.Label className="text-start m-0 p-0">
                    <b>Message</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your message here..."
                    value={message}
                    as="textarea"
                    rows={3}
                    onChange={handleMessageChange}
                    isInvalid={!!errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mx-4 mb-5">
                  <Button
                    type="submit"
                    style={{
                      background:
                        "rgba(52, 168, 83, 1)",
                      border: "none",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
