import React from 'react'
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import './Footer.css'
const Footer = () => {
  return (
    <div>
      <Navbar className='footercss'>
        <Container fluid>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Navbar.Text className='navtext'>
                © {new Date().getFullYear()} My Website. All rights reserved.
              </Navbar.Text>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Nav>
                <Nav.Link className='navtext' href="/terms">Term of use</Nav.Link>
                <Nav.Link className='navtext' href="/privacy">Privacy Policy</Nav.Link>
                <Nav.Link className='navtext' href="/terms">Aware</Nav.Link>
                <Nav.Link className='navtext' href="/privacy">Help Center</Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}

export default Footer