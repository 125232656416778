import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Upload.css";

const Upload = () => {
  const [attachment, setAttachment] = useState("No Selected File");
  const [recentActive,setRecentActive] = useState(false)
  
  return (
    <div className="d-flex">
      <Card
        className="mx-auto my-5"
        style={{ width: "50%", backgroundColor: "#DADADA",borderRadius: "24px",border:" 1px solid #F6D0B9" }}
      >
        <Card.Header>
          <div
            className="mx-auto px-3"
            style={{
              backgroundColor: "#fff",
              borderRadius: "40px",
              width: "250px",
            }}
          >
            <button
              className={!recentActive?"activeButton":"nonActiveButton"}
              onClick={()=>setRecentActive(false)}
            >
              New Upload
            </button>
            <button
              className={recentActive?"activeButton":"nonActiveButton"}
              onClick={()=>setRecentActive(true)}
            >
              Recent
            </button>
          </div>
        </Card.Header>
       { !recentActive? <Card.Body className="my-5 py-4">
          <Form className="my-5">
              <Form.Group
                controlId="formFileLg"
                className=" mx-3 my-5"
                style={{ border: "none" }}
                >
                <Form.Control
                  type="file"                 
                  className="login-input"
                  hidden
                  size="lg"
                  onChange={({target:{files}})=>{
                    files[0] && setAttachment(files[0].name)
                  }}
                  />
              </Form.Group>
                    <div onClick={()=>document.querySelector(".login-input").click()} className="d-flex m-5" style={{ backgroundColor: "#fff" }}>
              <p className="m-1 p-1" style={{backgroundColor:"#FF731D",color:"#fff",border:"none"}}>Choose File</p>
              <p className="mx-5 my-2">{attachment}</p>
            </div>
            <div
              className="mx-auto px-3"
              style={{ borderRadius: "20px", width: "300px" }}
            >
              <button
                className="px-4 py-1 m-1"
                style={{
                  border: "none",
                  backgroundColor: "#fff",
                  borderRadius: "20px",
                }}
              >
                Back
              </button>
              <button
                className="px-4 py-1 m-1"
                style={{
                  border: "none",
                  color: "#fff",
                  backgroundColor: "#FF731D",
                  borderRadius: "20px",
                }}
              >
                Upload
              </button>
            </div>
          </Form>
        </Card.Body>:
        <Card.Body>
        <div className="d-flex justify-content-between">
          <div className="p-0 m-0">
              <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
              <p className="p-0 m-0">3 min ago</p>
          </div>
          <div>
              <p className='m-2'>60 Kb</p>
              <p></p>
          </div>
        </div>
          <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
        <div className="d-flex justify-content-between">
          <div className="p-0 m-0">
              <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
              <p className="p-0 m-0">3 min ago</p>
          </div>
          <div>
              <p className='m-2'>60 Kb</p>
              <p></p>
          </div>
        </div>
          <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
        <div className="d-flex justify-content-between">
          <div className="p-0 m-0">
              <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
              <p className="p-0 m-0">3 min ago</p>
          </div>
          <div>
              <p className='m-2'>60 Kb</p>
              <p></p>
          </div>
        </div>
          <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
        <div className="d-flex justify-content-between">
          <div className="p-0 m-0">
              <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
              <p className="p-0 m-0">3 min ago</p>
          </div>
          <div>
              <p className='m-2'>60 Kb</p>
              <p></p>
          </div>
        </div>
          <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
          <button className="d-flex mx-auto px-4" style={{backgroundColor:"#fff",border:"none",borderRadius:"12px"}}>View all uploads</button>
      </Card.Body>
        }
 {recentActive && <h5 className="py-0 my-0 text-center" style={{backgroundColor:"#fff",borderRadius:"0 0 20px 20px"}}>Last synced : 3 min ago</h5>} 
      </Card>
      {/* <Card
        className="mx-auto"
        style={{ width: "50%", backgroundColor: "#DADADA" }}
      >
        <Card.Header>
          <div
            className="mx-auto px-3"
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
              width: "300px",
            }}
          >
            <button
              className="px-4 py-1 me-1 my-1"
              style={{
                border: "none",            
                backgroundColor: "#fff",
                borderRadius: "20px",
              }}
            >
              New Upload
            </button>
            <button
              className="px-4 my-1 ms-1 py-1"
              style={{
                border: "none",
                color: "#fff",
                backgroundColor: "#FF731D",
                borderRadius: "20px",
              }}
            >
              Recent
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="p-0 m-0">
                <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
                <p className="p-0 m-0">3 min ago</p>
            </div>
            <div>
                <p className='m-2'>60 Kb</p>
                <p></p>
            </div>
          </div>
            <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
          <div className="d-flex justify-content-between">
            <div className="p-0 m-0">
                <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
                <p className="p-0 m-0">3 min ago</p>
            </div>
            <div>
                <p className='m-2'>60 Kb</p>
                <p></p>
            </div>
          </div>
            <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
          <div className="d-flex justify-content-between">
            <div className="p-0 m-0">
                <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
                <p className="p-0 m-0">3 min ago</p>
            </div>
            <div>
                <p className='m-2'>60 Kb</p>
                <p></p>
            </div>
          </div>
            <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
          <div className="d-flex justify-content-between">
            <div className="p-0 m-0">
                <p className="p-0 m-0"><b>User-journey01.pdf</b></p>
                <p className="p-0 m-0">3 min ago</p>
            </div>
            <div>
                <p className='m-2'>60 Kb</p>
                <p></p>
            </div>
          </div>
            <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"#fff"}}/>
            <button className="d-flex mx-auto px-4" style={{backgroundColor:"#fff",border:"none",borderRadius:"12px"}}>View all uploads</button>
        </Card.Body>
       <h5 className="py-0 my-0 text-center" style={{backgroundColor:"#fff"}}>Last synced : 3 min ago</h5> 

      </Card> */}
    </div>
  );
};

export default Upload;
