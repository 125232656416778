
import React, { useState, useRef, useEffect } from 'react'
import { Form, Button, Image, Card } from 'react-bootstrap';
import Sidebar from '../../../sidebar/Sidebar';
import './ProfileInformation.css';
import Camera from '../../../../assets/Images/Camera.svg'
import Upload_icon from '../../../../assets/Images/Upload_icon.svg'
import { useSelector, useDispatch } from "react-redux"
import { getUserProfile, updateProfile } from '../../../../redux/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
export default function ProfileInformation() {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [gender, setgender] = useState('');
    const [role, setrole] = useState('');
    const [full_name, setfullname] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [dob, setdob] = useState('');


    const [formData, setFormData] = useState({
        role: '',
        email: '',
        full_name: '',
        dob: '',
        gender: '',
        addresses: []
    });
    const userdata = useSelector((state) => state.user.userdata);

    useEffect(() => {
        dispatch(getUserProfile()).then((result) => {
            
            setEmail(result.payload.email);
        });

    }, []);

    const handleNameChange = (event) => {
        const inputName = event.target.value;

        setfullname(inputName);

        if (errors.full_name && isNameValid(inputName)) {
            const updatedErrors = { ...errors };

            delete updatedErrors.full_name;

            setErrors(updatedErrors);
        }
    };
    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;

        setEmail(inputEmail);

        if (errors.email && isEmailValid(inputEmail)) {
            const updatedErrors = { ...errors };

            delete updatedErrors.email;

            setErrors(updatedErrors);
        }
    };


    const handleSelect = (event) => {

        setgender(event.target.value)

        const inputgender = event.target.value

        if (errors.gender) {
            const updatedErrors = { ...errors };

            delete updatedErrors.gender;

            setErrors(updatedErrors);
        }
    };

    const roleSelect = (event) => {

        setrole(event.target.value)
        const inputrole = event.target.value

        if (errors.role) {
            const updatedErrors = { ...errors };

            delete updatedErrors.role;

            setErrors(updatedErrors);
        }
    };

    const handleRowChange = (id, fieldName, value) => {

        const updatedRows = rows.map((record) => {
            if (record.id === id) {
                return { ...record, [fieldName]: value };
            }
            return record;
        });

        setRows(updatedRows);
        const validationErrors = { ...errors };
        if (validationErrors[id] && validationErrors[id][fieldName]) {
            delete validationErrors[id][fieldName];
            if (Object.keys(validationErrors[id]).length === 0) {
                delete validationErrors[id];
            }
            setErrors(validationErrors);
        }
    };

    var moment = require('moment');

    const isNameValid = (inputName) => {
        const nameRegex = /^(?=.*[a-zA-Z]).+$/;
        return nameRegex.test(inputName);
    };

    const isEmailValid = (inputEmail) => {
        const emailRegex = /^([\w\-]+\.?){0,2}[\w\-]+@[\w.\-]+$/;
        return emailRegex.test(inputEmail);
    };

    const handleChange = (event) => {
        setdob(event.target.value)
        const inputdate = event.target.value

        if (errors.dob) {
            const updatedErrors = { ...errors };

            delete updatedErrors.dob;

            setErrors(updatedErrors);
        }
    };
    const handleFormSubmit = (event) => {

        event.preventDefault();

        const validationErrors = {};
        const form = event.currentTarget;

        if (!/^[a-zA-Z]+$/.test(full_name)) {
            validationErrors.full_name = "Please enter a valid name.";
        }

        if (!isEmailValid(email)) {
            validationErrors.email = "Please enter a valid email.";
        }

        if (!role) {
            validationErrors.role = "Please select role.";
        }

        if (!gender) {
            validationErrors.gender = "Please select gender.";
        }

        if (!dob) {
            validationErrors.dob = "Please select date.";
        }

        rows.forEach((record) => {
            const recordErrors = {};
            if (!record.address_line_1) {
                recordErrors.address_line_1 = 'address is required.';
            }else if (!isNameValid(record.address_line_1)) {
                recordErrors.address_line_1 = "Please enter valid address.";
            }

            if (!record.city) {
                recordErrors.city = 'city is required.';
            } else if (!/^[a-zA-Z]+$/.test(record.city)) {
                recordErrors.city = 'Please enter valid city.';
            }

            if (!record.zipcode) {
                recordErrors.zipcode = 'zipcode is required.';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(record.zipcode)){
                recordErrors.zipcode = 'Please enter valid zipcode.';
            }


            if (!record.state) {
                recordErrors.state = 'state is required.';
            } else if (!/^[a-zA-Z]+$/.test(record.state)) {
                recordErrors.state = 'Please enter valid state.';
            }

            if (!record.country) {
                recordErrors.country = 'country is required.';
            } else if (!/^[a-zA-Z]+$/.test(record.country)) {
                recordErrors.country = 'Please enter valid country.';
            }


            if (Object.keys(recordErrors).length > 0) {
                validationErrors[record.id] = recordErrors;
            }
        });


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            return;
        }
        formData.full_name = full_name;
        formData.gender = gender;
        formData.role = role;
        formData.email =email;
        formData.dob = moment((dob)).format("YYYY-MM-DD");
        const newData = rows.map(({ address_line_1, city, zipcode, state, country }) => ({ address_line_1, city, zipcode, state, country }));
        formData.addresses = newData;
        

        dispatch(updateProfile(formData))
            .then((result) => {
                localStorage.setItem('role', result.payload.data.role)
                localStorage.setItem('username', result.payload.username)
                if (result.payload.data.role == 'supplier') {
                    navigate('/quotationDashboard')
                } else if (result.payload.data.role == 'contractor') {
                    navigate('/contractorDashboard')
                } else if (result.payload.data.role == 'trucking') {
                    navigate('/truckingdashboard')
                } else {
                    navigate('/profileinformation')
                }

            })
            .catch((errordata) => {

            });

    };

    const [rows, setRows] = useState([{ id: 1, address_line_1: '', city: '', zipcode: '', state: '', country: '' }]);
    const [nextId, setNextId] = useState(2);

    const handleAddRow = () => {
        const newRow = { id: nextId, address_line_1: '', city: '', zipcode: '', state: '', country: '' };
        setRows([...rows, newRow]);
        setNextId(nextId + 1);
    };

    const handleRemoveRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
        ;
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <Card className="ms-auto mx-auto my-5  profile"
                style={{
                    width: "50rem",
                    textAlign: "left",
                    boxShadow: "0px 4px 5px 0px #4B4B4B",
                }}>
                <Card.Header style={{ background: '#1746A2', color: '#FFFFFF' }}>
                    <span className='profiletext'>Profile Information</span>
                </Card.Header>
                <Card.Body >
                    <div >
                        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                            <div className=" col-sm-12">
                                <div className="row mb-2">
                                    <div className="col-sm">
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control
                                                className='textcontainer'
                                                as="select"
                                                required
                                                name='role'
                                                value={role}
                                                onChange={roleSelect}
                                                isInvalid={!!errors.role}
                                            >
                                                <option value="">Select Role</option>
                                                <option value="supplier">Supplier</option>
                                                <option value="contractor">Contractor</option>
                                                <option value="trucking">Trucking</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.role}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm">
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control
                                                as="select"
                                                required
                                                className='textcontainer'
                                                name='gender'
                                                isInvalid={!!errors.gender}
                                                value={gender}
                                                onChange={handleSelect}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.gender}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-sm">
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                className='textcontainer'
                                                placeholder="Enter Full Name"
                                                name='full_name'
                                                onChange={handleNameChange}
                                                isInvalid={!!errors.full_name}
                                                value={full_name}
                                                required
                                                maxLength={20}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm">
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type='text'
                                                className='textcontainer'
                                                placeholder="Enter Email"
                                                defaultValue={email}
                                                isInvalid={!!errors.email}
                                                name='email'
                                                onChange={handleEmailChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                </div>

                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <Form.Group controlId="exampleForm.SelectCustom">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control
                                                type='date'
                                                className='textcontainer'
                                                placeholder="Enter Zip Code"
                                                name='dob'
                                                max="2005-12-31"
                                                isInvalid={!!errors.dob}
                                                onChange={handleChange}
                                                value={dob}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                {rows.map((row, index) => (
                                    <div key={row.id}>
                                        <div style={{ background: '#DAD8D8', padding: 10 }}>
                                            <div className="row mb-2">
                                                <div className="col-sm">
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Label>Street Address</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            className='textcontainer'
                                                            placeholder="Enter Street Address"
                                                            value={row.address_line_1}
                                                            isInvalid={!!errors[row.id]?.address_line_1}
                                                            name='address_line_1'
                                                            onChange={(e) => handleRowChange(row.id, 'address_line_1', e.target.value)}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid"> {errors[row.id]?.address_line_1}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm">
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            className='textcontainer'
                                                            placeholder="Enter City"
                                                            value={row.city}
                                                            isInvalid={!!errors[row.id]?.city}
                                                            name='city'
                                                            onChange={(e) => handleRowChange(row.id, 'city', e.target.value)}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors[row.id]?.city}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row mb-2">


                                                <div className="col-sm">
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Label>Zip Code</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            className='textcontainer'
                                                            placeholder="Enter Zip Code"
                                                            value={row.zipcode}
                                                            isInvalid={!!errors[row.id]?.zipcode}
                                                            name='zipcode'
                                                            maxLength={6}
                                                            onChange={(e) => handleRowChange(row.id, 'zipcode', e.target.value)}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors[row.id]?.zipcode}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-sm">
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            required
                                                            name='state'
                                                            className='textcontainer'
                                                            placeholder="Enter State"
                                                            isInvalid={!!errors[row.id]?.state}
                                                            value={rows.state}
                                                            onChange={(e) => handleRowChange(row.id, 'state', e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors[row.id]?.state}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-sm-6">
                                                    <Form.Group controlId="exampleForm.SelectCustom">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            className='textcontainer'
                                                            name='country'
                                                            placeholder="Enter Country"
                                                            value={row.country}
                                                            isInvalid={!!errors[row.id]?.country}
                                                            onChange={(e) => handleRowChange(row.id, 'country', e.target.value)}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors[row.id]?.country}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        {rows.length > 1 && (
                                            <span onClick={() => { handleRemoveRow(row.id) }} className='txtcss'>remove address</span>
                                        )}

                                    </div>))}
                                <span onClick={() => { handleAddRow() }} className='addtxtcss' >Add new address</span>

                            </div>
                            <Button className='btncss' type="submit"  >Get Started</Button>
                        </Form>

                    </div></Card.Body>

            </Card>


        </div>
    )
}
