import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const truckingManagementList = createAsyncThunk('createContractorContractsOrderList', async () => {
    const response = await fetch (`https://cold-hat-40370.botics.co/trucking_order/`,{
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
    })
    const resData = await response.json();
    return resData;
    });

    const truckingManagement = createSlice({
        name: 'truckingManagement',
        initialState: {
            projectList: [],
            quotationList: [],
            quotationDetails:[]
        },
        reducers: {},
        extraReducers: {
            [truckingManagementList.pending]: (state, action) => {
                state.loading = true
          
              },
          
              [truckingManagementList.fulfilled]: (state, action) => {
                ;
                state.loading = false
          
              },
          
              [truckingManagementList.rejected]: (state, action) => {
          
                state.loading = true
          
              },
        }
    });
    
    export default truckingManagement.reducer
