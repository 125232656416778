
import React, { useState } from 'react';
import './MessageScreen.css'; // Import CSS file for styling

const MessageScreen = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      const newMessage = {
        content: inputMessage,
        sender: 'John Doe', // Replace with actual sender information
        timestamp: new Date().toLocaleString(),
      };

      setMessages([...messages, newMessage]);
      setInputMessage('');
    }
  };

  return (
    <div className="group-message-screen">
      <header className="group-header">Group Name</header>

      <ul className="message-list">
        {messages.map((message, index) => (
          <li
            key={index}
            className={`message ${message.sender === 'John Doe' ? 'outgoing' : 'incoming'}`}
          >
            <div className="sender">{message.sender}</div>
            <div className="content">{message.content}</div>
            <div className="timestamp">{message.timestamp}</div>
          </li>
        ))}
      </ul>

      <div className="compose">
        <input
          type="text"
          className="message-input"
          value={inputMessage}
          onChange={handleInputChange}
          placeholder="Type a message..."
        />
        <button className="send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default MessageScreen;
