import { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import Login from "../login/Login";
import { useNavigate,Link } from "react-router-dom";
import Logo from '../../assets/Images/logo.png'
import { useDispatch } from "react-redux";
import { signUpUser } from "../../redux/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import './Signup.css';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Signup = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [tnc, setTnc] = useState(false);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const [showEye, setShowEye] = useState(false);
  const [showEyePass, setShowEyePass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordPass, setShowPasswordPass] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePasswordVisibilityPassword = () => {
    setShowPasswordPass((prevShowPassword) => !prevShowPassword);
  };


  const handleNameChange = (event) => {
    const inputName = event.target.value;

    setName(inputName);

    // Clear the error for email field if it becomes valid

    if (errors.name && isNameValid(inputName)) {
      const updatedErrors = { ...errors};

      delete updatedErrors.name;

      setErrors(updatedErrors);
    }
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;

    setEmail(inputEmail);

    // Clear the error for email field if it becomes valid

    if (errors.email && isEmailValid(inputEmail)) {
      const updatedErrors = { ...errors };

      delete updatedErrors.email;

      setErrors(updatedErrors);
    }
  };

  const handlePasswordChange = (event) => {
    if (event.target.value.length > 0) {
      setShowEyePass(true);
    } else {
      setShowEyePass(false);
    }
    const inputPassword = event.target.value;

    setPassword(inputPassword);
    // Clear the error for password field if it becomes non-empty

    if (errors.password && inputPassword.trim().length > 8) {
      const updatedErrors = { ...errors };

      delete updatedErrors.password;

      setErrors(updatedErrors);
    }
  };
  const handleConfirmPasswordChange = (event) => {
    if (event.target.value.length > 0) {
      setShowEye(true);
    } else {
      setShowEye(false);
    }
    const inputPassword = event.target.value;

    setConfirm_password(inputPassword);

    // Clear the error for password field if it becomes non-empty

    if (errors.confirm_password && inputPassword.trim().length > 8) {
      const updatedErrors = { ...errors };

      delete updatedErrors.confirm_password;

      setErrors(updatedErrors);
    }
    if (errors.confirm_password_matches && inputPassword===password) {
      const updatedErrors = { ...errors };

      delete updatedErrors.confirm_password_matches;

      setErrors(updatedErrors);
    }
  };

  const handleTermsNConditionsChange = () => {
    setTnc(!tnc);
    if (errors.termsAndConditions) {
      const updatedErrors = { ...errors };

      delete updatedErrors.termsAndConditions;

      setErrors(updatedErrors);
    }
  };
  const body={
    name:name,
    email:email,
    password:password,
    confirm_password:confirm_password
   }
  const handleFormSubmit = (event) => {
    event.preventDefault();


    const validationErrors = {};
    if (!isNameValid(name)) {
      validationErrors.name = "Please enter a valid user name.";
    }
    if (!isEmailValid(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!isPasswordValid(password)) {
      validationErrors.password = "Please enter a valid password";
    }
    if (!isConfirmPasswordValid(confirm_password)) {
      validationErrors.confirm_password = "Please enter a valid password";
    }
    if(!isPasswordMatches(confirm_password)){
      validationErrors.confirm_password_matches = "Password and Confirm does not match";
    }
    if (!tnc) {
      validationErrors.tnc = "Please accept the terms & conditions";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if(Object.keys(validationErrors).length === 0){
      setEmail("");
      setName("")
      setPassword("");
      setConfirm_password("");
      setTnc(false);
      dispatch(signUpUser(body))
    }
    setErrors({});
  };

  const isNameValid = (inputName) => {
    // Basic email validation using regular expression

    const nameRegex = /^([a-zA-Z'-.]+(?: [a-zA-Z'-.]+)?)$/;  

    // made a change in the name regex

    return nameRegex.test(inputName);
  };
  const isEmailValid = (inputEmail) => {
    // Basic email validation using regular expression

    const emailRegex = /^([\w\-]+\.?){0,2}[\w\-]+@[\w.\-]+$/;

    return emailRegex.test(inputEmail);
  };

  const isPasswordValid = (inputPassword) => {
    // Implement your own password validation logic

    return inputPassword.trim().length > 8;
  };
  const isConfirmPasswordValid = (inputPassword) => {
    // Implement your own password validation logic

    return inputPassword.trim().length > 8  
  };
  const isPasswordMatches =(inputPassword)=>{
    return inputPassword ===password
  }

  return (
    <div>
       
      <Card
        className="ms-auto mx-5 my-3"
        style={{
          width: "28rem",
          backgroundColor: "#DADADA",
          textAlign: "left",
          boxShadow: "0px 4px 5px 0px #4B4B4B",
        }}
      >
        <div style={{ backgroundColor: "#1746A2" }}>
          <Card.Img
            variant="top"
            src={Logo}
            className="d-flex my-4 mx-auto"
            style={{ width: "100px" }}
          />
        </div>
        <Card.Body className="p-0 m-0">
          <div className="text-center p-2" style={{ backgroundColor: "#fff" }}>
            <Card.Title>Sign Up</Card.Title>
          </div>
         <ToastContainer /> 
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="m-3 mx-4" controlId="formBasicName">
              <Form.Label className="text-start">Enter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={handleNameChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 mx-4" controlId="formBasicEmail">
              <Form.Label className="text-start">
                Enter Email address
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 mx-4" controlId="formBasicPassword">
              <Form.Label className="text-start">Create Password</Form.Label>
              <Form.Control
                type={showPasswordPass ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                isInvalid={!!errors.password}
              />
              {showEyePass && (
                  <FontAwesomeIcon
                    className="eyeiconcp"
                    icon={showPasswordPass ? faEye : faEyeSlash}
                    onClick={handleTogglePasswordVisibilityPassword}
                  />
                )}
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 mx-4"
              controlId="formBasicConfirmPassword"
            >
              <Form.Label className="text-start">Confirm Password</Form.Label>
              <Form.Control
                 type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={confirm_password}
                onChange={handleConfirmPasswordChange}
                isInvalid={!!errors.confirm_password || !!errors.confirm_password_matches}
              />
              {showEye && (
                  <FontAwesomeIcon
                    className="eyeiconp"
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={handleTogglePasswordVisibility}
                  />
                )}
              <Form.Control.Feedback type="invalid">
                {errors.confirm_password_matches ||errors.confirm_password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 mx-4" controlId="formBasicCheckbox">
              <input
                type="checkbox"
                checked={tnc}
                onChange={handleTermsNConditionsChange}
                isInvalid={!!errors.tnc}
                className="form-input-check"
              />
              <label htmlFor="check" className="form-input-check mx-2">
                I have read the{" "}
                <span
                  style={{ cursor: "pointer", color: "#FF731D" }}
                 
                >
                  Terms and Conditions
                </span>
              </label>
              <div style={{ color: "rgb(220,53,69)",fontSize:"15px"}}>{errors.tnc}</div>
              <Form.Control.Feedback type="invalid">
                {errors.tnc}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mx-4">
              <Button
                type="submit"
                style={{
                  background:
                    "linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%)",
                  border: "none",
                  width: "100%",
                }}
                
              >
                Sign Up
              </Button>
            </Form.Group>
          </Form>
          <Link to="/" state={{}}>    <p className="my-5 text-center" style={{cursor:"pointer"}}  >Back to Login</p></Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Signup;
