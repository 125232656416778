import React, {useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import "./TruckingCompanyOrder.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowDown, faLocationDot, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import exportimg from "../../assets/Images/exportimg.svg";
import {useDispatch} from "react-redux";
import {Col, Form, Image, Row} from "react-bootstrap";
import {truckingManagementList} from "../../redux/truckingManagement";
import {MONTHS} from "../../constants";

const TruckingCompanyOrder = () => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(true);
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedRow, setExpandedRow] = useState(null);
    const [truckingList, setTruckingList] = useState([]);
    const [totalCount, setTotalCount] = useState("")
    const [totalCompletedCount, setTotalCompletedCount] = useState("")
    const [totalTransitCount, setTotalTransitCount] = useState("")
    const [totalNewOrderCount, setTotalNewOrderCount] = useState("");
    useEffect(() => {
        dispatch(truckingManagementList())
            .then((result) => setTruckingList(result.payload, "result"))
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => {
        setTotalCount(truckingList.length)
        setTotalCompletedCount(truckingList.filter((item) => item.status === "COMPLETED").length)
        setTotalNewOrderCount(truckingList.filter((item) => item.status === "SUBMITTED").length)
        setTotalTransitCount(truckingList.filter((item) => item.status === "IN_TRANSIT").length)
    }, [truckingList])
    const handleTruckingRequest = async (item, status) => {
        const response = await fetch(`https://cold-hat-40370.botics.co/trucking_order/${item.id}/`, {
            method: "PUT", headers: {
                "Content-Type": "application/json", Authorization: `token ${localStorage.getItem("token")}`,
            }, body: JSON.stringify({
                status: status,
                freight_price: item.order.order_items[0].product.price,
                delivery_date: item.delivery_date,
                pickup_location: item.pickup_location,
                delivery_location: item.delivery_location,
                unit: item.order.order_items[0].product.quantity,
                order: item.order.id,
            }),
        });
        return await response.json();
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    return (<div style={{display: "flex"}}>
        <div style={{display: "flex"}}>
            <Sidebar/>
        </div>
        <div className="dashboardtable">
            <div className="d-flex justify-content-between">
                <h3>Orders</h3>
            </div>
            <hr></hr>
            <div>
                <button
                    className={toggle ? "btn-order px-5 py-2" : "btn-order-inactive px-5 py-2"}
                    onClick={() => setToggle(true)}
                >
                    ORDERS
                </button>
                <button
                    className={toggle ? "btn-order-inactive px-5 py-2" : "btn-order px-5 py-2"}
                    onClick={() => setToggle(false)}
                >
                    ORDER REQUESTS
                </button>
            </div>
            {toggle ? (<>
                <div className="status-dashboard-bar mt-3">
                    <div className="status-dashboard">
                        <div
                            className="py-1 px-4"
                            style={{
                                border: "1px solid black",
                                width: "150px",
                                borderTopLeftRadius: "6px",
                                borderBottomLeftRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <b>Total Orders</b>
                        </div>
                        <div
                            className="pt-1 px-3"
                            style={{
                                border: "1 px solid green",
                                backgroundColor: "rgba(47, 128, 237, 1)",
                                width: "50px",
                                color: "white",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <h5>{totalCount}</h5>
                        </div>
                    </div>

                    <div className="status-dashboard">
                        <div
                            className="py-1 px-4"
                            style={{
                                border: "1px solid black",
                                width: "150px",
                                borderTopLeftRadius: "6px",
                                borderBottomLeftRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <b>Completed</b>
                        </div>
                        <div
                            className="pt-1 px-3"
                            style={{
                                border: "1 px solid green",
                                backgroundColor: "rgba(52, 168, 83, 1)",
                                width: "50px",
                                color: "white",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <h5>{totalCompletedCount}</h5>
                        </div>
                    </div>
                    <div className="status-dashboard">
                        <div
                            className="py-1 px-4"
                            style={{
                                border: "1px solid black",
                                width: "150px",
                                borderTopLeftRadius: "6px",
                                borderBottomLeftRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <b>In Transit</b>
                        </div>
                        <div
                            className="pt-1 px-3"
                            style={{
                                border: "1 px solid green",
                                backgroundColor: "rgba(255, 193, 7, 1)",
                                width: "50px",
                                color: "white",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                            }}
                        >
                            <h5>{totalTransitCount}</h5>
                        </div>
                    </div>
                    <div className="status-dashboard">
                        <div
                            className="py-1 px-4"
                            style={{
                                border: "1px solid black",
                                width: "150px",
                                borderTopLeftRadius: "6px",
                                borderBottomLeftRadius: "6px",
                                borderRight: "none",
                            }}
                        >
                            <b>New Order</b>
                        </div>
                        <div
                            className="pt-1 px-3"
                            style={{
                                border: "1 px solid green",
                                backgroundColor: "rgba(255, 115, 29, 1)",
                                width: "50px",
                                color: "white",
                                borderTopRightRadius: "6px",
                                borderBottomRightRadius: "6px",
                            }}
                        >
                            <h5>{totalNewOrderCount}</h5>
                        </div>
                    </div>
                </div>

                <div className="table-container">
                    <table
                        id="data-table"
                        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                    >
                        <thead>
                        <tr>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Order ID{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Project ID{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Products
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Contractor{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Quantity{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Delivery Date{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Order Date{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Pickup Location{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Delivery Location{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                onClick={() => handleSort("name")}
                            >
                                Status{" "}
                                {sortColumn === "name" && <span>{sortDirection}</span>}
                            </th>
                            <th
                                style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                colSpan={2}
                            >
                                Action
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {truckingList.map((item) => (<React.Fragment key={item.id}>
                            <tr onClick={() => handleExpand(item.id)}>
                                <td>{item.order.id}</td>
                                <td
                                    className="d-flex justify-content-between mt-2"
                                    style={{color: "rgba(35, 104, 238, 1)"}}
                                >
                                    <span className="">{item.order.project.id}</span>
                                    <span className="expand-trigger ">
                              {expandedRow === item.id ? "^" : "˅"}
                            </span>{" "}
                                </td>
                                <td>
                                    {item.order.order_items[0].product.name}
                                    {/* {item.products.map((item)=>item.name).join(' , ')} */}
                                </td>
                                <td>{item.order.contractor.name}</td>
                                <td>
                                    {item.order.order_items[0].product.quantity}{" "}
                                    {item.order.order_items[0].product.unit}
                                </td>
                                <td>{item.delivery_date}</td>
                                <td>{item.created_at.slice(0, 10)}</td>
                                <td>{item.pickup_location.substring(0, 18)}</td>
                                <td>{item.delivery_location.substring(0, 18)}</td>
                                {/* <td>{item.status}</td> */}
                                <td>
                                    {expandedRow === item.id ? ("") : (item.status === "IN_TRANSIT" ?
                                        <div
                                            onClick={() => handleTruckingRequest(item, "COMPLETED")}
                                            className={item.status === "COMPLETED" ? "t-completed px-2 py-1" : item.status === "IN_TRANSIT" ? "t-in-transit px-2 py-1" : "t-new-order px-2 py-1"}
                                            style={{fontSize: "10px"}}
                                        >
                                            {/* THIS NEEDS TO BE FIXED FROM BACKEND */}
                                            {item.status !== "SUBMITTED" && item.status}
                                        </div> : <div
                                            //   onClick={()=>handleTruckingRequest(item,"COMPLETED")}
                                            className={item.status === "COMPLETED" ? "t-completed px-2 py-1" : item.status === "IN_TRANSIT" ? "t-in-transit px-2 py" : "t-new-order px-2 py-1"}
                                            style={{fontSize: "10px"}}
                                        >
                                            {/* THIS NEEDS TO BE FIXED FROM BACKEND */}
                                            {item.status == "SUBMITTED" ? "NEW ORDER" : ""}
                                            {item.status !== "SUBMITTED" && item.status}
                                        </div>)}
                                </td>
                                <td colSpan={2} style={{color: "#2F80ED"}}>
                                    <button
                                        className="mx-3 px-3"
                                        style={{
                                            border: "none",
                                            cursor: "pointer",
                                            backgroundColor: "#2F80ED",
                                            color: "#fff",
                                            borderRadius: "30px",
                                        }}

                                        //   onClick={() => handleNameClick(row)}
                                    >
                                        CHAT
                                    </button>
                                    {item.status === "IN TRANSIT" && (<FontAwesomeIcon
                                        icon={faLocationDot}
                                        fontSize={18}
                                    />)}
                                    <span className="ms-3">
                              <FontAwesomeIcon
                                  icon={faFileArrowDown}
                                  fontSize={18}
                              />
                            </span>
                                </td>
                            </tr>
                            {expandedRow === item.id && (<React.Fragment
                                className="m-0 p-0"
                                style={{
                                    backgroundColor: "rgba(245, 245, 245, 1)",
                                }}
                            >
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}>Order Id</td>
                                    <td colSpan={2}>
                                        <b>{item.order.id}</b>
                                    </td>
                                    <td colSpan={2}>
                                        <b>Pickup Location</b>
                                    </td>
                                    <td rowSpan={1} colSpan={1} className="mt-0">
                                        <b>{item.pickup_location}</b>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}>Project ID</td>
                                    <td colSpan={2}>
                                        <b>{item.order.project.id}</b>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}> Products</td>
                                    <td colSpan={2}>
                                        {" "}
                                        <b>{item.order.order_items[0].product.name}</b>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colSpan={3}></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}> Contractor</td>
                                    <td colSpan={2}>
                                        <b>{item.order.contractor.username}</b>
                                    </td>
                                    <td colSpan={2}>
                                        <b>Delivery Location</b>
                                    </td>
                                    <td colSpan={1} rowSpan={1}>
                                        <b>{item.delivery_location}</b>{" "}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}>Quantity</td>
                                    <td colSpan={2}>
                                        <b>
                                            {item.order.order_items[0].product.quantity}{" "}
                                            {item.order.order_items[0].product.unit}
                                        </b>
                                    </td>
                                    <td colSpan={8}></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}>Order Date</td>
                                    <td colSpan={2}>
                                        <b>{item.created_at.slice(0, 10)}</b>
                                    </td>
                                    <td colSpan={1}>
                                        <b>Price</b>
                                    </td>
                                    <td colSpan={1}>
                                        {item.order.order_items[0].product.price}{" "}
                                        {item.order.order_items[0].product.currency}
                                    </td>
                                    <td colSpan={6}></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                >
                                    <td colSpan={2}>Delivery Date</td>
                                    <td colSpan={2}>
                                        <b>{item.delivery_date}</b>
                                    </td>
                                    <td colSpan={1}>
                                        <b>Status</b>
                                    </td>
                                    <td colSpan={1}>
                                        <>{item.status}</>
                                    </td>
                                    <td colSpan={1}>
                                        {item.status === "IN_TRANSIT" ? <div
                                            onClick={() => handleTruckingRequest(item, "COMPLETED")}
                                            className={item.status === "COMPLETED" ? "t-completed px-2" : item.status === "IN_TRANSIT" ? "t-in-transit px-2" : "t-new-order px-2"}
                                            style={{fontSize: "10px"}}
                                        >
                                            {/* THIS NEEDS TO BE FIXED FROM BACKEND */}
                                            {item.status !== "SUBMITTED" && item.status}
                                        </div> : <div
                                            //   onClick={()=>handleTruckingRequest(item,"COMPLETED")}
                                            className={item.status === "COMPLETED" ? "t-completed px-2 py-1" : item.status === "IN_TRANSIT" ? "t-in-transit px-2 py" : "t-new-order px-2 py-1"}
                                            style={{fontSize: "10px"}}
                                        >
                                            {/* THIS NEEDS TO BE FIXED FROM BACKEND */}
                                            {item.status == "SUBMITTED" ? "NEW ORDER" : ""}
                                            {item.status !== "SUBMITTED" && item.status}
                                        </div>}
                                    </td>
                                    <td colSpan={5}></td>
                                </tr>
                                <tr
                                    style={{
                                        backgroundColor: "rgba(245, 245, 245, 1)",
                                    }}
                                ></tr>
                            </React.Fragment>)}
                        </React.Fragment>))}
                        </tbody>
                    </table>
                </div>
            </>) : (<>
                {/* filter div */}
                <div className="mt-3">
                    <div className="table-container">
                        <table
                            id="data-table"
                            style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                        >
                            <thead>
                            <tr>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Order ID{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Project ID{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Products
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Contractor{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Quantity{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Delivery Date{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Order Date{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Pickup Location{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Delivery Location{" "}
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    onClick={() => handleSort("name")}
                                >
                                    Freight Price
                                    {sortColumn === "name" && (<span>{sortDirection}</span>)}
                                </th>
                                <th
                                    style={{border: "1px solid rgba(186, 186, 186, 1)"}}
                                    colSpan={2}
                                >
                                    Action
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {truckingList.filter((item) => item.status === "SUBMITTED").map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr onClick={() => handleExpand(item.id)}>
                                        <td>{item.order.id}</td>
                                        <td
                                            className="d-flex justify-content-between mt-2"
                                            style={{color: "rgba(35, 104, 238, 1)"}}
                                        >
                                            <span className="">{item.order.project.id}</span>
                                            <span className="expand-trigger ">
                                {expandedRow === item.id ? "^" : "˅"}
                              </span>{" "}
                                        </td>
                                        <td>
                                            {item.order.order_items[0].product.name}
                                            {/* {item.products.map((item)=>item.name).join(' , ')} */}
                                        </td>
                                        <td>{item.order.contractor.name}</td>
                                        <td>
                                            {item.order.order_items[0].product.quantity}{" "}
                                            {item.order.order_items[0].product.unit}
                                        </td>
                                        <td>{item.delivery_date}</td>
                                        <td>{item.created_at.slice(0, 10)}</td>
                                        <td>{item.pickup_location.substring(0, 18)}</td>
                                        <td>{item.delivery_location.substring(0, 18)}</td>
                                        <td>{item.order.order_items[0].product.price}</td>
                                        {expandedRow === item.id ? ("") : (
                                            <td colSpan={2} style={{color: "#2F80ED"}}>
                                                <button
                                                    className="mx-2 px-3 py-1"
                                                    style={{
                                                        border: "none",
                                                        borderRadius: "15px",
                                                        color: "#fff",
                                                        backgroundColor: "rgba(52, 168, 83, 1)",
                                                    }}
                                                    onClick={() => handleTruckingRequest(item, "IN_TRANSIT")}
                                                >
                                                    Accept
                                                </button>
                                                <button
                                                    className="px-3 py-1"
                                                    style={{
                                                        border: "none",
                                                        borderRadius: "15px",
                                                        color: "#fff",
                                                        backgroundColor: "rgba(255, 115, 29, 1)",
                                                    }}
                                                    onClick={() => handleTruckingRequest(item, "REJECTED")}
                                                >
                                                    Reject
                                                </button>
                                            </td>)}
                                    </tr>
                                    {expandedRow === item.id && (<React.Fragment
                                        className="m-0 p-0"
                                        style={{
                                            backgroundColor: "rgba(245, 245, 245, 1)",
                                        }}
                                    >
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}>Order Id</td>
                                            <td colSpan={2}>
                                                <b>{item.order.id}</b>
                                            </td>
                                            <td colSpan={2}>
                                                <b>Pickup Location</b>
                                            </td>
                                            <td rowSpan={2} colSpan={2} className="mt-0">
                                                <b>{item.pickup_location}</b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}>Project ID</td>
                                            <td colSpan={2}>
                                                <b>{item.order.project.id}</b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}> Products</td>
                                            <td colSpan={2}>
                                                {" "}
                                                <b>{item.order.order_items[0].product.name}</b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={3}></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}> Contractor</td>
                                            <td colSpan={2}>
                                                <b>{item.order.contractor.username}</b>
                                            </td>
                                            <td colSpan={2}>
                                                <b>Delivery Location</b>
                                            </td>
                                            <td colSpan={2} rowSpan={2}>
                                                <b>{item.delivery_location}</b>{" "}
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}>Quantity</td>
                                            <td colSpan={2}>
                                                <b> {item.order.order_items[0].product.quantity}{" "}
                                                    {item.order.order_items[0].product.unit}</b>
                                            </td>
                                            <td colSpan={8}></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}>Order Date</td>
                                            <td colSpan={2}>
                                                <b>{item.created_at.slice(0, 10)}</b>
                                            </td>
                                            <td colSpan={2}>
                                                <b>Price</b>
                                            </td>
                                            <td colSpan={2}> {item.order.order_items[0].product.price}{" "}
                                                {item.order.order_items[0].product.currency}</td>
                                            <td colSpan={4}></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2}>Delivery Date</td>
                                            <td colSpan={2}>
                                                <b>{item.delivery_date}</b>
                                            </td>
                                            <td colSpan={2}></td>
                                            <td colSpan={1}>
                                                <div></div>
                                            </td>
                                            <td colSpan={5}></td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "rgba(245, 245, 245, 1)",
                                            }}
                                        >
                                            <td colSpan={2} style={{color: "#2F80ED"}}>
                                                <button
                                                    className="mx-2 px-3 py-1"
                                                    style={{
                                                        border: "none",
                                                        borderRadius: "15px",
                                                        color: "#fff",
                                                        backgroundColor: "rgba(52, 168, 83, 1)",
                                                    }}
                                                >
                                                    Accept
                                                </button>
                                                <button
                                                    className="px-3 py-1"
                                                    style={{
                                                        border: "none",
                                                        borderRadius: "15px",
                                                        color: "#fff",
                                                        backgroundColor: "rgba(255, 115, 29, 1)",
                                                    }}
                                                    onClick={() => handleTruckingRequest(item, "REJECTED")}
                                                >
                                                    Reject
                                                </button>
                                            </td>
                                            <td colspan={10}></td>
                                        </tr>
                                    </React.Fragment>)}
                                </React.Fragment>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>)}
        </div>
    </div>);
};

export default TruckingCompanyOrder;
