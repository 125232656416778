

import React, { useState,useEffect } from "react";
import './OrdersDashboard.css'
import Sidebar from '../../sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Form, Image, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import edit from '../../../assets/Images/edit.svg';
import close from '../../../assets/Images/close.svg';
import up from '../../../assets/Images/up.svg'
import down from '../../../assets/Images/down.svg'
import deletecross from '../../../assets/Images/deletecross.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import { getContractorOrderList } from "../../../redux/contractorOrder";

export default function OrdersDashboard() {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [searchName, setSearchName] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedRow, setExpandedRow] = useState(null);
    const [supplierOrderList,setSupplierOrderList]= useState([])
    const [activeStatus,setActiveStatus]= useState("All")
    const [count,setCount] = useState(0)
    let navigate = useNavigate();
    
    const handleAcceptOrderRequest = async (id)=>{
        const response = await fetch (`https://cold-hat-40370.botics.co/contract_order/${id}/`,{
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            status:"ACCEPTED"
        })
    })
    const resData = await response.json();
    
    return resData
        // 
        // dispatch((acceptContractorQuotation(acceptBody)))
      }

    useEffect(() => {
        dispatch(getContractorOrderList())
          .then((result) => {
            setSupplierOrderList(result.payload);
            setCount(result.payload.filter((item)=>item.status==="SUBMITTED").length);
            ;
          })
          .catch((errordata) => {console.log(errordata)});
      }, []);
    const data = [
        {
            orderid: "AAAA1",
            projectid: "CON-114",
            projectname: "John Doe",
            products: "Asphalt, Steel",
            contracter: "ABC",
            price: "125$",
            delivery_date: "08-07-2023",
            order_date: "08-07-2023",
            status:'placed'
        },
        {
            orderid: "AAAA2",
            projectid: "CON-115",
            projectname: "John Doe",
            products: "Asphalt, Steel",
            contracter: "ABC",
            price: "125$",
            delivery_date: "08-07-2023",
            order_date: "08-07-2023",
            status:'requested'
        },


    ];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleDropdownChange = (e) => {
        setSearchName(e.target.value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    const filteredData = data.filter((item) => {
        if (searchName) {
            return item.name === searchName;
        } else {
            return (
                item.projectname.toLowerCase().includes(searchText.toLowerCase()) ||
                item.name.toString().includes(searchText) ||
                item.name.toLowerCase().includes(searchText.toLowerCase())
            );
        }
    });

    const sortedData = filteredData.sort((a, b) => {
        if (sortColumn === "name") {
            return sortDirection === "asc"
                ? a.projectname.localeCompare(b.projectname)
                : b.projectname.localeCompare(a.projectname);
        } else if (sortColumn === "price") {
            return sortDirection === "asc" ? a.price - b.price : b.price - a.price;
        } else {
            return 0;
        }
    });

    return (
        <>
            <div style={{ display: "flex" }}>
                <Sidebar />
                <div className="dashboardtable">
                    <div className="d-flex justify-content-between">
                        <span className='headertext'>Orders</span>
                    </div>
                    <hr></hr>
                    <div className="d-flex">
                        <button
                            className="py-2 px-3 my-3 "
                            onClick={()=>setActiveStatus("All")}
                            style={{
                                color: "#fff",
                                width: "250px",
                                backgroundColor:activeStatus==="All" ?  "#FF731D":"rgba(255, 115, 29, 0.5)",
                                border: "none",
                            }}
                        >
                            All
                        </button>
                        <div className="quotecountflex">
                        <button
                            className="py-2 px-3 my-3 mx-1"
                            onClick={()=>setActiveStatus("Request")}
                            style={{
                                color: "#fff",
                                width: "250px",
                                backgroundColor: activeStatus==="Request" ? "#FF731D":"rgba(255, 115, 29, 0.5)",
                                border: "none",
                            }}
                        >
                            Order Request
                        </button>
                        {count!=0 &&  <span className="ms-auto e-badge e-badge-info e-badge-notification e-badge-overlap ordercount" >{count}</span>}
                        </div>
                    </div>

                    <Row className="mb-3">
                        <Col className="col-search" lg={2}>
                        <label className="input-container">
                            <Form.Control
                                type="text"
                                className="search-form"
                                value={searchText}
                                onChange={handleSearch}
                                placeholder="Search..."
                            />
                              <FontAwesomeIcon className="fa-search" icon={faMagnifyingGlass} />
                              </label>
                        </Col>
                        <Col lg={2}>
                            <Form.Select value={searchName} onChange={handleDropdownChange}>
                                <option value="">Project Name</option>
                                {data.map((item) => (
                                    <option key={item.name} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>

                    </Row>
                    {
                        activeStatus==="All" &&
                    <div className="table-container">
                        <table id="data-table" className="tableborder">
                            <thead>
                                <tr >
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Order ID
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="projectnamecss" onClick={() => handleSort("name")}>
                                        Project ID
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Project Name
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Products
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Contractor
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Price
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Delivery date
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" style={{width:'25%'}}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {supplierOrderList?.map((item) => (
                                    <React.Fragment key={item.id}>
                                        
                                        <tr  data-status={item.status} class="status" >
                                        <td>{item.order_items[0].order}</td>
                                            <td className="d-flex" onClick={() => handleExpand(item.id)} style={{ color: '#2F80ED' }}>
                                                <span className="mx-3" style={{marginRight:'5px'}}  >{item.project.id}</span>
                                                <span className="expand-trigger">
                                                    {expandedRow === item.id ?

                                                        <Image src={up} className="upimage" />
                                                        :
                                                        <Image src={down} className="upimage" />}
                                                </span>
                                            </td>
                                            <td>{item.project.name}</td>
                                            <td>{item.order_items[0].product.name}</td>
                                            <td>{item.contractor.username}</td>
                                            <td>{item.order_items[0].product.price/item.order_items[0].product.quantity*item.order_items[0].quantity}</td>
                                            <td>{item.delivery_date}</td>
                                            <td>
                                 
                                            {item.status === 'placed' &&  <Button className='quotebtncss2' type="submit" > ORDER PLACED</Button>}
                                                {item.status === 'SUBMITTED' &&  <Button className='quotebtncss3 mx-2' type="submit" >REQUESTED</Button>}
                                                {item.status === 'SUBMITTED' &&  <Button className='quotebtncss2 '  onClick={() => handleAcceptOrderRequest(item.id)} type="submit" >ACCEPT</Button>
                                                }
                                                <Button className='chatcss' type="submit" href="/chat">CHAT</Button>
                                            </td>
                                        </tr>
                                        {expandedRow === item.id && (
                                            <React.Fragment
                                                className="m-0 p-0 tdBackgroundColor"

                                            >

                                                <tr className="tdBackgroundColor">
                                                    <td >Project Id</td>

                                                    <td><b>{item.id}</b></td>

                                                    <td colSpan={3}><b>Project Description</b></td>
                                                  

                                                    <td colSpan={2}><b>Products</b></td>

                                                    <td><b>Quote</b></td>
                                                    
                                                    {/* <td></td> */}
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td>Project Name</td>
                                                    <td><b>{item.project.name}</b></td>
                                                     <td rowSpan={2} colSpan={3} ></td>
                                                    <td colSpan={2}><b>{item.order_items[0].product.name}- {item.order_items[0].product.quantity} {item.order_items[0].product.unit}</b> </td>
                                                    <td><b>{item.order_items[0].product.price} {" "} {item.order_items[0].product.currency}</b></td>
                                                    {/* <td></td> */}
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Contractor</td>
                                                    <td>
                                                        <b>{item.contractor.username}</b>
                                                    </td>
                                                    <td colSpan={3}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Order Date</td>
                                                    <td><b>{item.created_at.slice(0,10)}</b></td>
                                                    <td colSpan={2}><b>Term for Suppliers</b></td>
                                                    <td colSpan={4}></td>
                                                </tr>

                                                
                                                <tr className="tdBackgroundColor">
                                                    <td>Delivery Date</td>
                                                    <td><b>{item.delivery_date}</b></td>
                                                    <td colSpan={3}>
                                                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s */}
                                                    </td>
                                                    <td colSpan={3}></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td><b>Truck Details:</b></td>
                                                    
                                                    <td colSpan={7}></td>
                                                   
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Company</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Pick up Date</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Drop off date</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Price</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>BOL:</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">

                                                   
                                                    <td colSpan={2}> <Button className='disabled' type="submit"  disabled>TRACK ORDER</Button></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    }
                    {
                        activeStatus==="Request" &&
                    <div className="table-container">
                        <table id="data-table" className="tableborder">
                            <thead>
                                <tr >
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Order ID
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="projectnamecss" onClick={() => handleSort("name")}>
                                        Project ID
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Project Name
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Products
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Contractor
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Price
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" onClick={() => handleSort("name")}>
                                        Delivery date
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" style={{width:'25%'}}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {supplierOrderList?.filter((item)=>item.status==="SUBMITTED").map((item) => (
                                    <React.Fragment key={item.id}>
                                        
                                        <tr  data-status={item.status} class="status" >
                                        <td>{item.order_items[0].order}</td>
                                            <td className="d-flex" onClick={() => handleExpand(item.id)} style={{ color: '#2F80ED' }}>
                                                <span className="mx-3" style={{marginRight:'5px'}}  >{item.project.id}</span>
                                                <span className="expand-trigger">
                                                    {expandedRow === item.id ?

                                                        <Image src={up} className="upimage" />
                                                        :
                                                        <Image src={down} className="upimage" />}
                                                </span>
                                            </td>
                                            <td>{item.project.name}</td>
                                            <td>{item.order_items[0].product.name}</td>
                                            <td>{item.contractor.username}</td>
                                            <td>{item.order_items[0].product.price/item.order_items[0].product.quantity*item.order_items[0].quantity}</td>
                                            <td>{item.delivery_date}</td>
                                            <td>
                                 
                                            {item.status === 'placed' &&  <Button className='quotebtncss2' type="submit" > ORDER PLACED</Button>}
                                                {item.status === 'SUBMITTED' &&  <Button className='quotebtncss3 mx-2' type="submit" >REQUESTED</Button>}
                                                {item.status === 'SUBMITTED' &&  <Button className='quotebtncss2 '  onClick={() => handleAcceptOrderRequest(item.id)} type="submit" >ACCEPT</Button>
                                                }
                                                <Button className='chatcss' type="submit" href="/chat">CHAT</Button>
                                            </td>
                                        </tr>
                                        {expandedRow === item.id && (
                                            <React.Fragment
                                                className="m-0 p-0 tdBackgroundColor"

                                            >

                                                <tr className="tdBackgroundColor">
                                                    <td >Project Id</td>

                                                    <td><b>{item.id}</b></td>

                                                    <td colSpan={3}><b>Project Description</b></td>
                                                  

                                                    <td colSpan={2}><b>Products</b></td>

                                                    <td><b>Quote</b></td>
                                                    
                                                    {/* <td></td> */}
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td>Project Name</td>
                                                    <td><b>{item.project.name}</b></td>
                                                     <td rowSpan={2} colSpan={3} ></td>
                                                    <td colSpan={2}><b>{item.order_items[0].product.name}- {item.order_items[0].product.quantity} {item.order_items[0].product.unit}</b> </td>
                                                    <td><b>{item.order_items[0].product.price} {" "} {item.order_items[0].product.currency}</b></td>
                                                    {/* <td></td> */}
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Contractor</td>
                                                    <td>
                                                        <b>{item.contractor.username}</b>
                                                    </td>
                                                    <td colSpan={3}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Order Date</td>
                                                    <td><b>{item.created_at.slice(0,10)}</b></td>
                                                    <td colSpan={2}><b>Term for Suppliers</b></td>
                                                    <td colSpan={4}></td>
                                                </tr>

                                                
                                                <tr className="tdBackgroundColor">
                                                    <td>Delivery Date</td>
                                                    <td><b>{item.delivery_date}</b></td>
                                                    <td colSpan={3}>
                                                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s */}
                                                    </td>
                                                    <td colSpan={3}></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td><b>Truck Details:</b></td>
                                                    
                                                    <td colSpan={7}></td>
                                                   
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Company</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Pick up Date</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Drop off date</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Price</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>BOL:</td>
                                                    <td><b></b></td>
                                                    <td colSpan={6}></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">

                                                   
                                                    <td colSpan={2}> <Button className='disabled' type="submit"  disabled>TRACK ORDER</Button></td>
                                                    <td colSpan={6}></td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}
