import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const createSchedulesRequest = createAsyncThunk('createSchedulesRequest', async (body) => {
    const response = await fetch("https://cold-hat-40370.botics.co/schedule/", {
        method: "post",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    })
    const resData = await response.json();
    return resData;
});

export const getScheduleList = createAsyncThunk('getScheduleList', async () => {
    const response = await fetch('https://cold-hat-40370.botics.co/schedule/list', {
        method: "get",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
    })
    const data = await response.json();

    return data;
});

export const editSchedule = createAsyncThunk('editSchedule', async (body) => {
    const {id} = body
    const response = await fetch(`https://cold-hat-40370.botics.co/schedule/${id}`, {
        method: "put",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    })
    const data = await response.json();
    return data;
});

const schedules = createSlice({
    name: 'schedules',
    initialState: {
        projectList: [],
        quotationList: [],
        quotationDetails: []
    },
    reducers: {},
    extraReducers: {
        [createSchedulesRequest.pending]: (state, action) => {
            state.loading = true

        },

        [createSchedulesRequest.fulfilled]: (state, action) => {
            ;
            state.loading = false

        },

        [createSchedulesRequest.rejected]: (state, action) => {

            state.loading = true

        },

        [getScheduleList.pending]: (state, action) => {
            state.loading = true
        },

        [getScheduleList.fulfilled]: (state, action) => {
           
        },

        [getScheduleList.rejected]: (state, action) => {
            state.loading = true
        },

        [editSchedule.pending]: (state, action) => {
            state.loading = true

        },

        [editSchedule.fulfilled]: (state, action) => {
            ;
            state.loading = false

        },

        [editSchedule.rejected]: (state, action) => {

            state.loading = true

        },
    }
});

export default schedules.reducer
