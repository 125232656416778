import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Sidebar from "../sidebar/Sidebar";
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale,} from 'chart.js';
import {getProjectList} from "../../redux/project";
import {Bar} from 'react-chartjs-2';
import {getContractor} from "../../redux/quotation";
import "./Schedule.css"

ChartJS.register(
    CategoryScale, LinearScale,
    BarElement,
);

const ScheduleSupplier = () => {
    const dispatch = useDispatch()
    const [monthGraph, setMonthGraph] = useState("January");
    const [projectIdGraph, setProjectIdGraph] = useState("");
    const [contractorIdGraph, setContractorIdGraph] = useState("");
    const [yearGraph, setYearGraph] = useState(2023)
    const [chart, setChart] = useState(null)
    const [contractorList, setContractorList] = useState([]);
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        dispatch(getContractor())
            .then((result) => {
                setContractorList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(getProjectList())
            .then((result) => {
                setProjectList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    const fetchData = async () => {
        const response = await fetch(`https://cold-hat-40370.botics.co/schedule/?month=${monthGraph}&year=${yearGraph}&project=${projectIdGraph}&contractor=${contractorIdGraph}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `token ${localStorage.getItem('token')}`
            },
        })

        const data = await response.json();
        setChart(data);
        return data;
    }

    useEffect(() => {
        fetchData();
    }, [monthGraph, yearGraph, projectIdGraph, contractorIdGraph]);


    const data = {
        labels: chart?.data?.map(x => x.name),
        datasets: [{
            label: "# of votes",
            data: chart?.data?.map(x => x.total),
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
        }]
    }
    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                }
            },
            x: {
                grid: {
                    display: false
                }
            },
        },
        legend: {
            labels: {
                fontSize: 25,
            },
        },
    }

    console.log(chart)
    return (
        <div style={{display: "flex"}}>
            <div style={{display: "flex"}}>
                <Sidebar/>
            </div>
            <div className="ms-5">
                <div
                    className="d-flex justify-content-between "
                    style={{
                        borderBottom: "1px solid lightgrey",
                        textAlign: "left",
                    }}
                >
                    <h2 className="p-3">Schedule</h2>
                </div>

                <div className="mt-4 ms-1" style={{textAlign: "left"}}>
                    <select className="p-2" onChange={(e) => setProjectIdGraph(e.target.value)}>
                        <option value="">Select Project</option>
                        {projectList.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </select>
                    {/*<select className="ms-2 p-2" onChange={(e) => setContractorIdGraph(e.target.value)}>*/}
                    {/*    <option value="">Select Contractor</option>*/}
                    {/*    {contractorList.map((item) => <option key={item.id} value={item.id}>{item.username}</option>)}*/}
                    {/*</select>*/}
                    <select className="ms-2 px-3 py-2" onChange={(e) => setYearGraph(e.target.value)}>
                        <option value="">Select Year</option>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                        <option value={2025}>2025</option>
                        <option value={2026}>2026</option>
                    </select>
                    <select className="ms-2 px-3 py-2" onChange={(e) => setMonthGraph(e.target.value)}>
                        <option value="">Select Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                    </select>
                </div>
                <div className="my-3 position-relative" style={{width: "600px", height: "300px"}}>
                    <Bar
                        data={data}
                        height={100}
                        options={options}
                    />
                    {
                        (chart && chart?.data?.length === 0)
                        && <div
                            className={"w-100 h-100 position-absolute top-0 left-0 d-flex align-items-center justify-content-center bg-white-transparent text-dark"}>
                            No Data found
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ScheduleSupplier
