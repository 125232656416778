

import React, { useEffect, useState } from "react";
import './ContractDashboard.css'
import Sidebar from '../../sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Form, Image, Row, Col, Table } from "react-bootstrap";
import edit from '../../../assets/Images/edit.svg';
import close from '../../../assets/Images/close.svg';
import up from '../../../assets/Images/up.svg'
import down from '../../../assets/Images/down.svg'
import deletecross from '../../../assets/Images/deletecross.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, fas } from "@fortawesome/free-solid-svg-icons";
import { getAllContract, getContractList,searchBycontract } from "../../../redux/contract";
import { useDispatch } from "react-redux";


export default function ContractDashboard() {
    const [searchText, setSearchText] = useState("");
    const [searchName, setSearchName] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedRow, setExpandedRow] = useState(null);
    let navigate = useNavigate();
    const [contractData, setContractData] = useState([]);
    const [contractData1, setContractData1] = useState([]);
    const [show, setShow] = useState(true);
    const [hide, sethide] = useState(false);
    const [count, setcount] = useState('');
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const [contractActive, setcontractActive] = useState(false);
    const [next1, setnext1] = useState('');
    const [previous, setprevious] = useState('');

    const storeUserId = localStorage.getItem("userId");
    useEffect(() => {

        dispatch(getContractList('https://cold-hat-40370.botics.co/contracts/'))
            .then((result) => {
                setIsActive(true);
                setContractData1(result.payload.data.results)
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)

            })
        dispatch(getAllContract())
            .then((result) => {
                setcount(result.payload.data.length);
            })

    }, [])

    function nextbuttonCLick() {

        dispatch(getContractList(next1))
            .then((result) => {
                setIsActive(true);
                setContractData1(result.payload.data.results)
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    function previousbuttonCLick() {
        dispatch(getContractList(previous))
            .then((result) => {
                setIsActive(true);
                setContractData1(result.payload.data.results)
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);

        dispatch(searchBycontract(e.target.value))
            .then((result) => {

                const data = result.payload.data.results;

                setContractData1(result.payload.data.results)
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    };


    const handleDropdownChange = (e) => {
        setSearchName(e.target.value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    const contractAll = () => {
        setcontractActive(false);
        setIsActive(true);
        sethide(true);
        setShow(false)
        setContractData([])
        dispatch(getContractList('https://cold-hat-40370.botics.co/contracts/'))
            .then((result) => {
                setContractData1(result.payload.data.results)
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
                ;
            })
    }

    const contractRequested = () => {
        setcontractActive(true);
        setIsActive(false);
        sethide(false);
        setShow(true)
        setContractData1([])
        dispatch(getAllContract())
            .then((result) => {
                setContractData(result.payload.data)
                setnext1('')
                setprevious('')
                ;
            })
    }



    return (
        <>
            <div style={{ display: "flex" }}>
                <Sidebar />
                <div className="dashboardtable">
                    <div className="d-flex justify-content-between">
                        <span className='headertext'>Contracts</span>

                    </div>
                    <hr></hr>
                    <div className="d-flex">
                        <button
                            className={isActive ? 'active-button' : 'inactive-button'}
                            onClick={() => contractAll()}
                        >

                            All
                        </button>

                        <div className="quotecountflex">
                            <button
                                style={{ marginLeft: '5px' }}
                                className={contractActive ? 'active-button' : 'inactive-button'}
                                onClick={() => contractRequested()}
                            >
                                Contracts Requested
                            </button>
                            {count != 0 && <span className="e-badge e-badge-info e-badge-notification e-badge-overlap contractcount" >{count}</span>}
                        </div>
                    </div>

                    <Row className="mb-5">
                        <Col className="col-search" lg={2}>
                            <label className="input-container">
                                <Form.Control
                                    type="text"
                                    className="search-form"
                                    value={searchText}
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                />
                                <FontAwesomeIcon className="fa-search" icon={faMagnifyingGlass} />
                            </label>
                        </Col>

                    </Row>

                    <div className="table-container">
                        <table id="data-table" className="tableborder">
                            <thead>
                                <tr >
                                    <th className="tableborder" >
                                        Contract id
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" >
                                        Contractor
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" >
                                        Project name
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>

                                    <th className="tableborder" >
                                        Products
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" >
                                        Price
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="tableborder" >
                                        Quantity
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>

                                    <th className="tableborder" >
                                        Load Number
                                        {sortColumn === "name" && <span>{sortDirection}</span>}
                                    </th>
                                    <th className="projectnamecss">Status</th>
                                    <th className="tableborder" style={{ width: '14%' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody show={show}>
                                {contractData.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr className="quotestatus" data-status={item.status}>
                                            <td className="d-flex " style={{ color: '#2F80ED' }} onClick={() => handleExpand(item.id)}>
                                                {/* <span style={{ marginRight: '5px' }}>{item.id}</span> */}
                                                <span className="expand-trigger">
                                                    {expandedRow === item.id ?

                                                        <Image src={up} className="upimage" />
                                                        :
                                                        <Image src={down} className="upimage" />}
                                                </span>
                                            </td>
                                            <td>{item.contractor.name}</td>
                                            <td >{item.project.name}</td>
                                            <td> {item.products.map((item) => item.id).length > 1
                                                ? "Multiple Products"
                                                : item.products.map((item) => (
                                                    <div>
                                                        {item.name}
                                                    </div>
                                                ))}</td>
                                            <td>
                                                {item.products.map((item) => item.id).length > 1
                                                    ? "Multiple Products"
                                                    : item.products.map((item) => (
                                                        <div>
                                                            {item.price} {item.currency}
                                                        </div>
                                                    ))}
                                            </td>
                                            <td>
                                                {item.products.map((item) => item.id).length > 1
                                                    ? "Multiple"
                                                    : item.products.map((item) => (
                                                        <div>
                                                            {item.quantity} {item.unit}
                                                        </div>
                                                    ))}
                                            </td>
                                            <td>{item.load_number}</td>
                                            <td>

                                                {item.status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit"  > Submitted, not signed</Button>}
                                                {item.status === 'signed' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }}>Signed and active</Button>}
                                                {item.status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Requested</Button>}

                                            </td>
                                            <td className="tdcss">
                                                {item.status === 'SUBMITTED_NOT_SIGNED' && <Image src={edit} className="editimage" />}
                                                {item.status === 'viewed' && <Image src={deletecross} className="editimage" />}
                                                {item.status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }} onClick={() => { navigate(`/createcontracts/${item.id}`) }}>Accept</Button>}
                                                <Button className='chatcss' type="submit" href="/chat">Chat</Button>
                                            </td>
                                        </tr>
                                        {expandedRow === item.id && (
                                            <React.Fragment
                                                className="m-0 p-0 tdBackgroundColor">

                                                <tr className="tdBackgroundColor" >
                                                    <td colSpan={1}>Contract id</td>

                                                    <td>
                                                        {/* <b>{item.id}</b> */}
                                                    </td>

                                                    <td colSpan={3}><b>Project Description</b></td>

                                                    <td><b>Products</b></td>

                                                    <td style={{ paddingLeft: 30 }}><b>Quote</b></td>
                                                    <td ><b>Terms</b></td>

                                                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button className='chatcss1' type="submit" href="/chat" >Chat</Button>
                                                    </td>

                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td rowSpan={1}>Supplier</td>

                                                    <td>
                                                        <b>{item.supplier.name}</b>
                                                    </td>

                                                    <td rowSpan={2} colSpan={3} >{item.project.description}</td>


                                                    {item.products.map((productitem) => (
                                                        <>
                                                            <tr className="tdBackgroundColor">
                                                                <td>
                                                                    <b>{productitem.name}-</b>{" "}
                                                                </td>
                                                                <td>
                                                                    <b>
                                                                        {productitem.quantity}
                                                                        {productitem.unit}
                                                                    </b>
                                                                </td>
                                                                <td style={{ paddingLeft: 30 }}>
                                                                    <b>
                                                                        {productitem.price}
                                                                        {productitem.currency}
                                                                    </b>
                                                                </td>
                                                                <td style={{ paddingLeft: 40 }}>
                                                                    <b>{productitem.terms}</b>
                                                                </td>

                                                            </tr>
                                                        </>
                                                    ))}
                                                    <td colSpan={3}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Contractor</td>

                                                    <td>
                                                        <b>{item.contractor.name}</b>
                                                    </td>
                                                    <td colSpan={3}></td>
                                                    <td></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td>Load number</td>
                                                    <td><b>{item.load_number}</b></td>
                                                    <td rowSpan={2} colSpan={3}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td> Project name</td>
                                                    <td><b>{item.project.name}</b></td>
                                                    <td colSpan={4}>  </td>

                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Status</td>
                                                    <td>
                                                        {item.status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit" style={{ width: '160px' }}> Submitted, not signed</Button>}
                                                        {item.status === 'VIEWED' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }}>Signed and active</Button>}
                                                        {item.status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Requested</Button>}

                                                    </td>
                                                    <td colSpan={4}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">


                                                    <td> {item.status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit" style={{ width: '90px' }} onClick={() => { navigate(`/createcontracts/${item.id}`) }}>Edit</Button>}
                                                        {item.status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss2' type="submit" style={{ width: '120px' }} onClick={() => { navigate(`/createcontracts/${item.id}`) }}>Accept</Button>}</td>
                                                    <td>
                                                        {/* <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Cancel contract</Button> */}
                                                    </td>
                                                    <td colSpan={3}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>

                            <tbody hide={hide}>
                                {contractData1.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr className="quotestatus" data-status={item.status}>
                                            <td className="d-flex " style={{ color: '#2F80ED' }} onClick={() => handleExpand(item.id)}>
                                                <span style={{ marginRight: '5px' }}>{item.id}</span>
                                                <span className="expand-trigger">
                                                    {expandedRow === item.id ?

                                                        <Image src={up} className="upimage" />
                                                        :
                                                        <Image src={down} className="upimage" />}
                                                </span>
                                            </td>
                                            <td>{item.quote.contractor.name}</td>
                                            <td >{item.quote.project.name}</td>
                                            <td> {item.quote.products.map((item) => item.id).length > 1
                                                ? "Multiple Products"
                                                : item.quote.products.map((item) => (
                                                    <div>
                                                        {item.name}
                                                    </div>
                                                ))}</td>
                                            <td>
                                                {item.quote.products.map((item) => item.id).length > 1
                                                    ? "Multiple Products"
                                                    : item.quote.products.map((item) => (
                                                        <div>
                                                            {item.price} {item.currency}
                                                        </div>
                                                    ))}
                                            </td>
                                            <td>
                                                {item.quote.products.map((item) => item.id).length > 1
                                                    ? "Multiple"
                                                    : item.quote.products.map((item) => (
                                                        <div>
                                                            {item.quantity} {item.unit}
                                                        </div>
                                                    ))}
                                            </td>
                                            <td>{item.load_number}</td>
                                            <td>

                                                {item.supplier_status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit"  > Submitted, not signed</Button>}
                                                {item.supplier_status === 'SIGNED_AND_ACTIVE' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }}>Signed and active</Button>}
                                                {item.quote.supplier_status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Requested</Button>}

                                            </td>
                                            <td className="tdcss">
                                                <span style={{ width: '20px' }}>
                                                    {item.supplier_status === 'SUBMITTED_NOT_SIGNED' && <Image onClick={() => { navigate(`/updatecontract/${item.quote.id}/${item.id}`) }} src={edit} className="editimage" />}
                                                </span>
                                                {item.supplier_status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }} onClick={() => { navigate(`/createcontracts/${item.id}`) }}>Accept</Button>}
                                                <Button className='chatcss' type="submit" href="/chat">Chat</Button>
                                            </td>
                                        </tr>
                                        {expandedRow === item.id && (
                                            <React.Fragment
                                                className="m-0 p-0 tdBackgroundColor">

                                                <tr className="tdBackgroundColor" >
                                                    <td colSpan={1}>Contract id</td>

                                                    <td><b>{item.id}</b></td>

                                                    <td colSpan={3}><b>Project Description</b></td>

                                                    <td><b>Products</b></td>

                                                    <td style={{ paddingLeft: 30 }}><b>Quote</b></td>
                                                    <td ><b>Terms</b></td>

                                                    <td style={{ display: 'flex', justifyContent: 'end' }}>
                                                        <span style={{ width: '20px' }}></span>
                                                        <Button className='chatcss1' type="submit" href="/chat" >Chat</Button>
                                                    </td>

                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td rowSpan={1}>Supplier</td>

                                                    <td>
                                                        <b>{item.quote.supplier.name}</b>
                                                    </td>

                                                    <td rowSpan={2} colSpan={3} >{item.quote.project.description}</td>


                                                    {item.quote.products.map((productitem) => (
                                                        <>
                                                            <tr className="tdBackgroundColor">
                                                                <td>
                                                                    <b>{productitem.name}-</b>
                                                                </td>
                                                                <td>
                                                                    <b>
                                                                        {productitem.quantity}
                                                                        {productitem.unit}
                                                                    </b>
                                                                </td>
                                                                <td style={{ paddingLeft: 30 }}>
                                                                    <b>
                                                                        {productitem.price}
                                                                        {productitem.currency}
                                                                    </b>
                                                                </td>
                                                                <td style={{ paddingLeft: 40 }}>
                                                                    <b>{productitem.terms}</b>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                    <td colSpan={3}></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td>Contractor</td>

                                                    <td>
                                                        <b>{item.quote.contractor.name}</b>
                                                    </td>
                                                    <td colSpan={3}></td>
                                                    <td></td>
                                                </tr>


                                                <tr className="tdBackgroundColor">
                                                    <td>Load number</td>
                                                    <td><b>{item.load_number}</b></td>
                                                    <td rowSpan={2} colSpan={3}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">
                                                    <td> Project name</td>
                                                    <td><b>{item.quote.project.name}</b></td>
                                                    <td colSpan={4}>  </td>

                                                </tr>
                                                <tr className="tdBackgroundColor">
                                                    <td>Status</td>
                                                    <td>
                                                        {item.supplier_status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit" style={{ width: '160px' }}> Submitted, not signed</Button>}
                                                        {item.supplier_status === 'SIGNED_AND_ACTIVE' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }}>Signed and active</Button>}
                                                        {item.supplier_status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Requested</Button>}

                                                    </td>
                                                    <td colSpan={4}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr className="tdBackgroundColor">


                                                    <td> {item.supplier_status === 'SUBMITTED_NOT_SIGNED' && <Button className='quotebtncss' type="submit" style={{ width: '90px' }} onClick={() => { navigate(`/updatecontract/${item.id}`) }}>Edit</Button>}
                                                        {item.supplier_status === 'CONTRACT_REQUESTED' && <Button className='quotebtncss2' type="submit" style={{ width: '150px' }} onClick={() => { navigate(`/createcontracts/${item.id}`) }}>Accept</Button>}</td>
                                                    <td>
                                                        {/* <Button className='quotebtncss3' type="submit" style={{ width: '150px' }}>Cancel contract</Button> */}
                                                    </td>
                                                    <td colSpan={3}></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit" disabled={next1 != '' && next1 != 'null' && next1 != undefined ? false : true} onClick={() => nextbuttonCLick()}>Next</Button>
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit" disabled={previous != '' && previous != 'null' && previous != undefined ? false : true} onClick={() => previousbuttonCLick()} >Previous</Button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}
