import React, {useState} from 'react'
import {Button, Card, Form, Image} from "react-bootstrap";
import google from '../../assets/Images/google.svg'
import './Login.css';
import Logo from '../../assets/Images/logo.png'
import {addAuthenticator, facebookLogin, getUserProfile, signinuser} from '../../redux/authSlice';
import {useDispatch} from "react-redux";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import FacebookLoginButton from './FacebookLoginButton';

const Login = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showEye, setShowEye] = useState(false);

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    if (errors.email && isEmailValid(inputEmail)) {
      const updatedErrors = {...errors};
      delete updatedErrors.email;
      setErrors(updatedErrors);
    }
  };

  const handlePasswordChange = (event) => {
    if (event.target.value.length > 0) {
      setShowEye(true)
    } else {
      setShowEye(false)
    }

    const inputPassword = event.target.value;
    setPassword(inputPassword);

    if (errors.password && inputPassword.trim().length > 8) {
      const updatedErrors = {...errors};
      delete updatedErrors.password;
      setErrors(updatedErrors);
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
    if (errors.rememberMe) {
      const updatedErrors = {...errors};
      delete updatedErrors.rememberMe;
      setErrors(updatedErrors);
    }
  };

  const handleFormSubmit = (event) => {

    event.preventDefault();

    // form validation
    const validationErrors = {};
    if (!isEmailValid(email)) {
      validationErrors.email = 'Please enter a valid email address.';
    }


    if (!isPasswordValid(password)) {
      validationErrors.password = 'Please enter a valid password';
    }

    //Set validation errors if any
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Form is valid, perform login logic here
    ;
    // Reset form fields and errors
    setEmail('');
    setPassword('');
    setRememberMe(false);
    setErrors({});
    const body = {
      email: email?.toLowerCase(),
      password: password
    }

    dispatch(signinuser(body))
      .then((result) => {
        if (result.payload.key) {
          dispatch(getUserProfile())
            .then((result) => {
              
              localStorage.setItem('role', result.payload.role)
              localStorage.setItem('username', result.payload.username)
              localStorage.setItem('userId', result.payload.id)
              if (result.payload.role == 'supplier') {
                navigate('/quotationDashboard')
              } else if (result.payload.role === 'contractor') {
                navigate('/contractorDashboard')
              } else if (result.payload.role === 'trucking') {
                navigate('/truckingdashboard')
              } else {
                navigate('/profileinformation')
              }

            })
            .catch((errordata) => {

            });
        } else {

        }
      })
      .catch((error) => {
        console.log(error)
      });

  };

  const isEmailValid = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };
  const isPasswordValid = (inputPassword) => {
    return inputPassword.trim().length > 0;
  };

  const responseFacebook = (accessToken) => {

    if (accessToken) {
      const body = {
        access_token: accessToken
      }

      let data = JSON.stringify(body)
      dispatch(facebookLogin(data))
        .then((result) => {

          if (result.payload.key) {
            dispatch(getUserProfile())
              .then((result) => {
                localStorage.setItem('role', result.payload.role)
                localStorage.setItem('username', result.payload.username)
                localStorage.setItem('userId',result.payload.id)
                if (result.payload.role == 'supplier') {
                  navigate('/quotationDashboard')
                } else if (result.payload.role == 'contractor') {
                  navigate('/contractorDashboard')
                } else if (result.payload.role == 'trucking') {
                  navigate('/truckingdashboard')
                } else {
                  navigate('/profileinformation')
                }

              })
              .catch((errordata) => {

              });
          }
        })
        .catch((error) => {
          console.log(error)
        });

    } else {

    }

  }

  const clientId = '21290094707-p5n0h6730b7emb0ll9dng17ad3d0d2po.apps.googleusercontent.com'
  const handleGoogleSignInAPI = async dispatch => {
    try {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: "https://www.googleapis.com/auth/userinfo.email",
        callback: res => {

          if (res?.access_token) {

            const body = {
              access_token: res.access_token
            }
            let data = JSON.stringify(body)
            let config = {
              method: "post",
              url: 'https://cold-hat-40370.botics.co/modules/social-auth/google/login/',
              headers: {
                "Content-Type": "application/json"
              },
              data: data
            }

            axios
              .request(config)
              .then(response => {
                localStorage.setItem("token", response.data.key)
                googlelogin(response.data.key)
              })
              .catch(error => {
                window.scrollTo(0, 0)
                if (error?.response?.status === 400) {
                  console.log(error, "error")
                  toast.error(error.response.data.non_field_errors[0], {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: true,
                  });
                }
              })
          } else {
            window.scrollTo(0, 0)
            toast.error("No access token", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              hideProgressBar: true,
            });
          }
        },
        error_callback: err => {
          window.scrollTo(0, 0)
          toast.error("No access token", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      })

    } catch (err) {
    }
  }


  const googlelogin = (response) => {
    dispatch(addAuthenticator(response))
    dispatch(getUserProfile())
      .then((result) => {
        localStorage.setItem('role', result.payload.role)
        localStorage.setItem('username', result.payload.username)
        localStorage.setItem('userId', result.payload.id)
        if (result.payload.role == 'supplier') {
          navigate('/quotationDashboard')
        } else if (result.payload.role == 'contractor') {
          navigate('/contractorDashboard')
        } else if (result.payload.role == 'trucking') {
          navigate('/truckingdashboard')
        } else {
          navigate('/profileinformation')
        }

      })
      .catch((errordata) => {

      });


  }

  return (
    <>

      <div className='mainCard'>

        <Card
          className="ms-auto mx-5 my-3 "
          style={{
            width: "28rem",
            backgroundColor: "#DADADA",
            textAlign: "left",
            boxShadow: "0px 4px 5px 0px #4B4B4B",
          }}>
          {/* <button onClick={handleLogin}>Login with Facebook</button>
             <button onClick={logout}>logout</button> */}

          <div className='backcolor'>
            <Card.Img variant="top" src={Logo}
                      className="d-flex my-4 mx-auto logowidth"/>
          </div>
          <div className="text-center p-2" style={{backgroundColor: "#fff"}}>
            <Card.Title>Log In</Card.Title>
          </div>
          <Card.Body>
            <ToastContainer/>

            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label className="text-start labelcss">Enter Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">


                <Form.Label className="text-start labelcss">Enter Password

                </Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordChange}
                  isInvalid={!!errors.password}
                />

                {showEye &&

                  <FontAwesomeIcon
                    className='eyeicon'
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={handleTogglePasswordVisibility}
                  />}

                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                <p className="my-2 text-center labelcss" style={{marginLeft: '64%', cursor: 'pointer'}}
                   onClick={() => navigate("/forgetpassword")}>
                  <Link to="/forgetpassword" state={{}}> <span className='signupcss' style={{
                    textDecoration: "underline",
                    fontFamily: 'Roboto-bold'
                  }}>Forgot Password?</span>
                  </Link>
                </p>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRememberMe">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  isInvalid={!!errors.rememberMe}
                  className='labelcss'
                />
                <Form.Control.Feedback type="invalid">{errors.rememberMe}</Form.Control.Feedback>
              </Form.Group>
              <Button
                className='btncss'
                type="submit"
              >
                Login
              </Button>

            </Form>

            <p className="my-2 text-center labelcss">or login with</p>
            <div className='lgimageflex'>
              <FacebookLoginButton onFacebookLogin={responseFacebook}/>

              <Image src={google} alt="Image" className='googleimage' onClick={() => {
                handleGoogleSignInAPI()
              }}/>
            </div>


            <p className="my-2 text-center labelcss">Not registered yet?
              <Link to="/signup" state={{}}>
                <span
                  className='signupcss'
                  style={{textDecoration: "underline", fontFamily: 'Roboto-bold'}}>
                  Sign up
                </span>
              </Link>
            </p>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default Login
