import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Sidebar from "../sidebar/Sidebar";
import { createSchedulesRequest } from "../../redux/schedules";
import { getProjectList } from "../../redux/project";
import { getScheduleList, editSchedule } from "../../redux/schedules";
import { getSupplier, } from "../../redux/contractorQuotation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../redux/quotation";
import { Button, Modal } from "react-bootstrap";

const CreateSchedule = () => {
    const [projectId, setProjectId] = useState("");
    const [supplierId, setSupplierId] = useState("");
    const [rows, setRows] = useState([{ name: "", quantity: "", unit: "" }]);
    const [month, setMonth] = useState("January");
    const [year, setYear] = useState(2023)
    const [activeButton, setActiveButton] = useState(null);
    const [activeButton1, setActiveButton1] = useState(0);
    const [supplierList, setSupplierList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [scheduleList, setsScheduleList] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [next1, setnext1] = useState('');
    const [previous, setprevious] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [editObj, setEditObj] = useState(null)
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        dispatch(getSupplier())
            .then((result) => {
                console.log("Supplier List", result.payload.data)
                setSupplierList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(getProjectList())
            .then((result) => {
                setProjectList(result.payload.data);
            })
            .catch((error) => {
                console.log(error);
            });

        dispatch(getScheduleList())
            .then((result) => {
                setsScheduleList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    console.log("Editobj", editObj)

    const addRowTable = () => {
        const data = {
            product: "",
            quantity: "",
            unit: "",
        };
        setRows([...rows, data]);
    };
    const tableRowRemove = (index) => {
        const dataRow = [...rows];
        dataRow.splice(index, 1);
        setRows(dataRow);
    };
    const onValUpdate = (i, event) => {
        const { name, value } = event.target;
        const data = [...rows];
        data[i][name] = value;
        setRows(data);
    };

    const scheduleBody = {
        "project": Number(projectId),
        "supplier": Number(supplierId),
        "year": year,
        "month": month,
        "schedule_product": rows
    }

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const months1 = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const handleBtn = (item, index) => {
        setActiveButton(index);
        setMonth(item);
    };

    const handleBtn1 = (item, index) => {
        setActiveButton1(index);
        const tempObj = { ...editObj }
        tempObj.month = item
        setEditObj(tempObj)
    };

    const handleExpand = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        } else {
            setExpandedRow(id);
        }
    };

    const handleSchedules = () => {
        dispatch(createSchedulesRequest(scheduleBody))
            .then(() => {
                toast.success("Schedule Created Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                navigate(-1);
            })
    }

    const formatDateToYYMMDD = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function nextbuttonCLick() {

        dispatch(fetchData(next1))
            .then((result) => {
                setsScheduleList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    function previousbuttonCLick() {
        dispatch(fetchData(previous))
            .then((result) => {
                setsScheduleList(result.payload.data.results);
                setnext1(result.payload.data.next)
                setprevious(result.payload.data.previous)
            })
            .catch((errordata) => {

            });
    }

    const editModalYear = (e) => {
        const tempObj = { ...editObj }
        tempObj.year = e.target.value
        setEditObj(tempObj)
    }

    const editModalSupplier = (e) => {
        const tempObj = { ...editObj }
        tempObj.supplier = parseInt(e.target.value)
        setEditObj(tempObj)
    }

    const editModalProject = (e) => {
        const tempObj = { ...editObj }
        tempObj.project = e.target.value
        setEditObj(tempObj)
    }

    const editModalRows = (e, index) => {
        let { name, value } = e.target
        console.log("values", name, value, index)
        let tempObj = { ...editObj }
        let tempArr = [...tempObj.schedule_product]
        let tempArrObj = { ...tempArr[index] }
        tempArrObj[name] = value
        tempArr[index] = tempArrObj
        tempObj.schedule_product = tempArr
        setEditObj(tempObj)
    }

    const handleCloseEdit = () => {
        setShowEdit(false)
        setEditObj(null)
    };

    const handleEditCall = () => {

        const tempObj = { ...editObj }
        delete tempObj.contractor
        delete tempObj.created
        delete tempObj.modified
        delete tempObj.total_quantity_sayali
        //    delete tempObj.id
        console.log("editObj", tempObj)
        //    setEditObj(tempObj)

        dispatch(editSchedule(tempObj))
            .then((result) => {
                console.log("edit response", result)
                setEditObj(null)
                setShowEdit(false)
                setsScheduleList([]);
                setnext1('')
                setprevious('')
                dispatch(getScheduleList())
                    .then((result) => {
                        setsScheduleList(result.payload.data.results);
                        setnext1(result.payload.data.next)
                        setprevious(result.payload.data.previous)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const setPreMonth = (month) => {
        if (month == "January") {
            setActiveButton1(0)
        } else if (month == "February") {
            setActiveButton1(1)
        } else if (month == "March") {
            setActiveButton1(2)
        }
        else if (month == "April") {
            setActiveButton1(3)
        }
        else if (month == "May") {
            setActiveButton1(4)
        }
        else if (month == "June") {
            setActiveButton1(5)
        }
        else if (month == "July") {
            setActiveButton1(6)
        }
        else if (month == "August") {
            setActiveButton1(7)
        }
        else if (month == "September") {
            setActiveButton1(8)
        }
        else if (month == "October") {
            setActiveButton1(9)
        } else if (month == "November") {
            setActiveButton1(10)
        } else {
            setActiveButton1(11)
        }
    }

    return (
        <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
                <Sidebar />
            </div>
            <div className="ms-5">
                <div
                    style={{
                        borderBottom: "1px solid lightgrey",
                        textAlign: "left",
                    }}
                >
                    <h2 className="p-2">Create a Schedule</h2>
                </div>

                <div className="mt-4 ms-1" style={{ textAlign: "left" }}>
                    <select className="p-2" onChange={(e) => setSupplierId(e.target.value)}>
                        <option value="1">Select supplier</option>
                        {supplierList.map((item) => {
                            return (<>
                                <option value={item.id}>{item.username}</option>
                            </>)
                        })}
                    </select>
                    <select className="ms-2 p-2" onChange={(e) => setProjectId(e.target.value)}>
                        <option value="">Select project</option>
                        {projectList.map((item) => {
                            return (<>
                                <option value={item.id}>{item.name}</option>
                            </>)
                        })}
                    </select>
                    <select className="ms-2 px-3 py-2" onChange={(e) => setYear(e.target.value)}>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                    </select>
                </div>

                <div className="mt-4" style={{ fontSize: "14px" }}>
                    {months.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <button
                                    className=" py-2 ms-1 px-4"
                                    style={{
                                        border: "none",
                                        backgroundColor:
                                            activeButton === index ? "#FF731A" : "#FFC7A6",
                                        // width: "100px",
                                    }}
                                    onClick={() => handleBtn(item, index)}
                                >
                                    {item}
                                </button>
                            </React.Fragment>
                        );
                    })}
                </div>

                <div className="mt-4 ms-1" style={{ textAlign: "left" }}>
                    <h5>
                        <b>Products</b>
                    </h5>
                </div>
                <div className="ms-1 mt-2 py-2 ps-3">
                    <Form>
                        {rows.map((rowsData, index) => {
                            const { name, quantity, unit } = rowsData;
                            return (
                                <Row
                                    key={index}
                                    className="mt-2"
                                    style={{ textAlign: "left", height: "60px" }}
                                >
                                    <Col xs={3}>
                                        <Form.Control
                                            style={{ borderRadius: "10px" }}
                                            placeholder="name"
                                            name="name"
                                            value={name}
                                            onChange={(event) => onValUpdate(index, event)}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Control
                                            style={{ borderRadius: "10px" }}
                                            placeholder="Quantity"
                                            name="quantity"
                                            value={quantity}
                                            onChange={(event) => onValUpdate(index, event)}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Select
                                            aria-label="Unit"
                                            style={{ borderRadius: "10px" }}
                                            name="unit"
                                            value={unit}
                                            onChange={(event) => onValUpdate(index, event)}
                                        >
                                            <option>Unit</option>
                                            <option value="Ton">Ton</option>
                                            <option value="KG">KG</option>
                                            <option value="Quintal">Quintal</option>
                                        </Form.Select>
                                    </Col>
                                    <Col
                                        xs={1}
                                        className="mt-1 pt-1"
                                        style={{ color: "red", cursor: "pointer" }}
                                    >
                                        <div
                                            style={{ display: "flex" }}
                                            onClick={() => tableRowRemove(index)}
                                        >
                                            {/* <AiOutlineDelete size={22} /> */}
                                            <p className="ms-1">Delete</p>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row className="mt-2" style={{ textAlign: "left", color: "#FF731A" }}>
                            <Col onClick={addRowTable} xs={2}>
                                <span style={{ fontSize: "13px" }}>Add new product</span>
                            </Col>
                        </Row>
                    </Form>
                    <button
                        className="px-4 py-1 mt-4 mb-5" style={{
                            border: "none",
                            borderRadius: "20px",
                            backgroundColor: "rgba(52, 168, 83, 1)",
                            color: "#fff",
                        }} onClick={handleSchedules}>Submit
                    </button>

                    <div className="table-container">
                        <table
                            id="data-table"
                            style={{ border: "1px solid rgba(186, 186, 186, 1)" }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)" }}
                                    // onClick={() => handleSort("id")}
                                    >
                                        Schedule ID
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)" }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Contractor
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)" }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Created on
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)" }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Modified on
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)", width: '15%' }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Month
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)", width: '15%' }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Total Quantity Stone
                                    </th>
                                    <th
                                        style={{ border: "1px solid rgba(186, 186, 186, 1)", width: '15%' }}
                                    // onClick={() => handleSort("name")}
                                    >
                                        Year
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scheduleList.map((item) => (<React.Fragment key={item.id}>
                                    <tr
                                        onClick={() => handleExpand(item.id)}
                                    >
                                        <td
                                            className="d-flex justify-content-around"
                                            style={{ color: "rgba(35, 104, 238, 1)" }}
                                        >
                                            <span className="ms-2">{item.id}</span>
                                            <span className="expand-trigger me-5">
                                                {expandedRow === item.id ? "^" : "˅"}
                                            </span>
                                        </td>
                                        <td>{item.contractor}</td>
                                        <td>{formatDateToYYMMDD(item.created)}</td>
                                        <td>{formatDateToYYMMDD(item.modified)}</td>
                                        <td>{item.month}</td>
                                        {/* <td>{item.project}</td>
                                        <td>{item.supplier}</td> */}
                                        <td>{item.total_quantity_Stone}</td>
                                        <td>{item.year}</td>
                                        <td>
                                            <button
                                                className=""
                                                style={{
                                                    border: "1px solid rgb(255, 115, 29)",
                                                    cursor: "pointer",
                                                    backgroundColor: "transparent",
                                                    color: "rgb(255, 115, 29)",
                                                    borderRadius: "30px",
                                                    fontSize: "12px",
                                                }}
                                                onClick={(e) => {
                                                    setEditObj(item)
                                                    setPreMonth(item.month)
                                                    setShowEdit(true)
                                                }}
                                            >
                                                <b>EDIT</b>
                                            </button>
                                        </td>
                                    </tr>
                                    {expandedRow === item.id && (<React.Fragment
                                        className="m-0 p-0"
                                        style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
                                    >
                                        <div style={{ padding: "10px", marginBottom: "10px", width:"1000px", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                            <div>Schedule id : {item.id}</div>
                                            <div>Project Name : {item.project ? item.project.name ? item.project.name : "" : ""}</div>
                                            <div>Supplier Name : {item.project ? item.supplier.name ? item.supplier.name : "" : ""}</div>
                                        </div>
                                        
                                        {/* {item.schedule_product.map((item) => {
                                            return (
                                                <div style={{ padding: "10px", marginBottom: "10px", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                                    <div>Product id : {item.id}</div>
                                                    <div>Product Name : {item.project ? item.project.name ? item.project.name : "" : ""}</div>
                                                    <div>Supplier Name : {item.supplier ? item.supplier.name ? item.supplier.name : "" : ""}</div>
                                                    <div>Schedule : {item.schedule_product? item.schedule_product.schedule ? item.schedule_product.schedule : "" : ""}</div>
                                                    <div>Total Quantity : {item.schedule_product ? item.schedule_product.total_quantity ? item.schedule_product.total_quantity : "" : ""}</div>
                                                    <div>Unit : {item.schedule_product ? item.schedule_product.unit ? item.schedule_product.unit : "" : ""}</div>
                                                </div>
                                            )
                                        })} */}
                                        {/* Modal for Edit Information */}
                                        {editObj ? <Modal className="my-5" show={showEdit} onHide={handleCloseEdit} centered size="xl">
                                            <Modal.Header
                                                closeButton
                                                style={{ color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)" }}
                                            >
                                                <Modal.Title className="px-3 py-2">Edit Schedule Information</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{ backgroundColor: "#fff" }}>
                                                <div className="mt-4 ms-1" style={{ textAlign: "left" }}>
                                                    <select className="p-2" value={editObj.supplier.id} onChange={(e) => editModalSupplier(e)}>
                                                        <option value="">Select supplier</option>
                                                        {supplierList.map((item) => {
                                                            return (<>
                                                                <option value={item.id}>{item.username}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                    <select className="ms-2 p-2" value={editObj.project.id} onChange={(e) => editModalProject(e)}>
                                                        <option value="">Select project</option>
                                                        {projectList.map((item) => {
                                                            return (<>
                                                                <option value={item.id}>{item.name}</option>
                                                            </>)
                                                        })}
                                                    </select>
                                                    <select className="ms-2 px-3 py-2" value={editObj.year} onChange={(e) => editModalYear(e)}>
                                                        <option value="2023">2023</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2025">2025</option>
                                                        <option value="2026">2026</option>
                                                    </select>
                                                </div>

                                                <div className="mt-4" style={{ fontSize: "14px" }}>
                                                    {months1.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <button
                                                                    className=" py-2 ms-1 px-4"
                                                                    style={{
                                                                        border: "none",
                                                                        backgroundColor:
                                                                            activeButton1 === index ? "#FF731A" : "#FFC7A6",
                                                                        // width: "100px",
                                                                    }}
                                                                    onClick={() => handleBtn1(item, index)}
                                                                >
                                                                    {item}
                                                                </button>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>

                                                <div className="mt-4 ms-1" style={{ textAlign: "left" }}>
                                                    <h5>
                                                        <b>Products</b>
                                                    </h5>
                                                </div>
                                                <div className="ms-1 mt-2 py-2 ps-3">
                                                    {!editObj ?
                                                        <div>hiiiiii</div>
                                                        :
                                                        <Form>
                                                            {editObj.schedule_product.map((rowsData, index) => {
                                                                const { name, quantity, unit } = rowsData;
                                                                return (
                                                                    <Row
                                                                        key={index}
                                                                        className="mt-2"
                                                                        style={{ textAlign: "left", height: "60px" }}
                                                                    >
                                                                        <Col xs={3}>
                                                                            <Form.Control
                                                                                style={{ borderRadius: "10px" }}
                                                                                placeholder="name"
                                                                                name="name"
                                                                                value={name}
                                                                                onChange={(event) => editModalRows(event, index)}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={2}>
                                                                            <Form.Control
                                                                                style={{ borderRadius: "10px" }}
                                                                                placeholder="Quantity"
                                                                                name="quantity"
                                                                                value={quantity}
                                                                                onChange={(event) => editModalRows(event, index)}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={2}>
                                                                            <Form.Select
                                                                                aria-label="Unit"
                                                                                style={{ borderRadius: "10px" }}
                                                                                name="unit"
                                                                                value={unit}
                                                                                onChange={(event) => editModalRows(event, index)}
                                                                            >
                                                                                <option>Unit</option>
                                                                                <option value="Ton">Ton</option>
                                                                                <option value="KG">KG</option>
                                                                                <option value="Quintal">Quintal</option>
                                                                            </Form.Select>
                                                                        </Col>
                                                                    </Row>
                                                                );
                                                            })}
                                                        </Form>
                                                    }
                                                    <button
                                                        className="px-4 py-1 mt-4 mb-5" style={{
                                                            border: "none",
                                                            borderRadius: "20px",
                                                            backgroundColor: "rgba(52, 168, 83, 1)",
                                                            color: "#fff",
                                                        }} onClick={handleEditCall}>Submit
                                                    </button>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                            : null}
                                    </React.Fragment>)}
                                </React.Fragment>))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit"
                                disabled={previous != '' && previous != 'null' && previous != undefined ? false : true}
                                onClick={() => previousbuttonCLick()}>Previous</Button>
                            <Button className='quotebtncss mt-2' style={{ marginLeft: '10px' }} type="submit"
                                disabled={next1 != '' && next1 != 'null' && next1 != undefined ? false : true}
                                onClick={() => nextbuttonCLick()}>Next</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSchedule
