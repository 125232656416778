import React, {useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import {Button, Col, Form, Modal, Row,} from "react-bootstrap";
import "./ContractorOrders.css";
import {getProjectList} from "../../redux/project";
import {useDispatch} from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {
 createContractorOrder,
 getContractorOrderList,
 postTruckingOrder,
 truckingCompanyList
} from "../../redux/contractorOrder";
import {toast} from "react-toastify";

const ContractorOrders = () => {
 const [searchText, setSearchText] = useState("");
 const [searchName, setSearchName] = useState("");
 const [sortColumn, setSortColumn] = useState("");
 const [sortDirection, setSortDirection] = useState("asc");
 const [expandedRow, setExpandedRow] = useState(null);
 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);
 const [showQR, setShowQR] = useState(false);
 const handleQRClose = () => setShowQR(false);
 const [modalQR, setModalQR] = useState("");
 const [supplierList, setSupplierList] = useState([]);
 const [projectList, setProjectList] = useState([]);
 const dispatch = useDispatch();
 const [supplier, setSupplier] = useState("");
 const [projectName, setProjectName] = useState("");
 const [contractorList, setContractorList] = useState([])
 const [contractorOrderList, setContractorOrderList] = useState([])
 const [contractorId, setContractId] = useState("")
 const [deliveryDate, setDeliveryDate] = useState("")
 const [productId, setProductId] = useState("")
 const [unit, setUnit] = useState("")
 const [quantity, setQuantity] = useState([])
 const [truckingList, setTruckingList] = useState([])
 const [trucking, setTrucking] = useState([])
 const [truckingDeliveryDate, setTruckingDeliveryDate] = useState("")
 const [truckingDeliveryTime, setTruckingDeliveryTime] = useState("")
 const [truckingPickup, setTruckingPickup] = useState("")
 const [truckingDelivery, setTruckingDelivery] = useState("")
 const [truckingUnit, setTruckingUnit] = useState("")
 const [allTruckingCompany, setallTruckingCompany] = useState(false)

 const handleTruckingOrder = () => {
  const createTruckingBody = {
   "order": modalQR?.id,
   "delivery_time": truckingDeliveryTime,
   "delivery_date": truckingDeliveryDate,
   "pickup_location": truckingPickup,
   "delivery_location": truckingDelivery,
   "ask_all_companies": allTruckingCompany,
   "unit": truckingUnit,
   "trucking_company": trucking[0]?.id,
   // freight_price: 10
  }

  setShowQR(false)
  dispatch(postTruckingOrder(createTruckingBody))
   .then((res) => {
    

    toast.success("Order Placed with Trucker Successfully", {
     position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true, closeOnClick: true,
    })
    GetContractorOrderList()
   })
 }

 const handleNewOrder = () => {
  const createOrderBody = {
   "project": projectName,
   "supplier": supplier,
   "contract": contractorId,
   "delivery_date": deliveryDate,
   "order_items": [{product: productId, unit: unit, quantity: quantity}],
  }
  dispatch(createContractorOrder(createOrderBody))
   .then((res) => {
    toast.success("Order Created Successfully", {
     position: toast.POSITION.TOP_RIGHT, autoClose: 2000, hideProgressBar: true, closeOnClick: true,
    })
    GetContractorOrderList()
   })
  setShow(false)
  setProjectName("")
  setSupplier("")
  setDeliveryDate("")
  setContractorList([])
  setProductId("")
  setUnit("")
  setQuantity("")

 }
 const GetContractorOrderList = () => {
  dispatch(getContractorOrderList())
   .then((result) => {
    setContractorOrderList(result.payload);
   })
   .catch(console.log);
 }

 const data = [{
  id: 1,
  name: "John Doe",
  details: "Lorem ipsum dolor sit amet",
  Supplier: "Amit",
  category: "Category 1",
  Quote_Date: "07/26/2022 -11:00 AM",
 }, {
  id: 2, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 3, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 4, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 5, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 6, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 7, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 8, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 9, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 10, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 11, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 12, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 13, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 14, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 }, {
  id: 15, name: "John Doe", details: "Lorem ipsum dolor sit amet", Supplier: "Amit", category: "Category 1",
 }, {
  id: 16, name: "Jane Smith", details: "Consectetur adipiscing elit", category: "Category 2",
 },];

 const handleSupplierList = async () => {
  try {
   const response = await fetch(`https://cold-hat-40370.botics.co/contracts/contract-info?project_id=${projectName}`, {
    method: "GET", headers: {
     'Content-Type': 'application/json', 'Authorization': `token ${localStorage.getItem('token')}`
    },
   })
   const resData = await response.json();
   setSupplierList(resData?.data?.map((item) => ({id: item?.id, name: item?.username})))
  } catch (error) {
   console.log(error)
  }
 }
 const handleContractList = async () => {
  try {
   const response = await fetch(`https://cold-hat-40370.botics.co/contracts/contract-info?project_id=${projectName}&supplier_id=${supplier}`, {
    method: "GET", headers: {
     'Content-Type': 'application/json', 'Authorization': `token ${localStorage.getItem('token')}`
    },
   })
   const resData = await response.json();
   setContractorList(resData.data.products)
   setContractId(resData.data.contract_id)
  } catch (error) {
   console.log(error)
  }
 }

 const handleSearch = (e) => {
  setSearchText(e.target.value);
 };

 const handleDropdownChange = (e) => {
  setSearchName(e.target.value);
 };

 const handleSort = (column) => {
  if (sortColumn === column) {
   setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  } else {
   setSortColumn(column);
   setSortDirection("asc");
  }
 };

 const handleExpand = (id) => {
  if (expandedRow === id) {
   setExpandedRow(null);
  } else {
   setExpandedRow(id);
  }
 };

 useEffect(() => {
  handleSupplierList()
 }, [projectName])

 useEffect(() => {
  if (supplier && projectName) {
   handleContractList()
  }
 }, [supplier, projectName])

 useEffect(() => {
  GetContractorOrderList()

  dispatch(truckingCompanyList())
   .then((result) => {
    setTruckingList(result.payload.data);
   })
   .catch((error) => {
    console.log(error);
   })
  dispatch(getProjectList())
   .then((result) => {
    setProjectList(result.payload.data);
   })
   .catch((error) => {
    console.log(error);
   });

 }, []);


 return (<>
  <div style={{display: "flex"}}>
   <Sidebar/>
   <div className="dashboardtable">
    <div className="d-flex justify-content-between">
     <h3>Orders</h3>
     <button
      className=" px-3 "
      onClick={() => setShow(true)}
      style={{
       color: "#fff", width: "200px", backgroundColor: "#FF731D", border: "none", borderRadius: "30px",
      }}
     >
      CREATE ORDER
     </button>
    </div>

    <hr></hr>
    <Row className="mb-3">
     <Col className="col-search" lg={2}>
      <label className="input-container">
       <Form.Control
        type="text"
        className="search-form"
        value={searchText}
        onChange={handleSearch}
        placeholder="Search..."
       />
       <FontAwesomeIcon className="fa-search" icon={faMagnifyingGlass}/>
      </label>
     </Col>

     <Col lg={2}>
      <Form.Select value={searchName} onChange={handleDropdownChange}>
       <option value="">All</option>
       {data.map((item) => (<option key={item.id} value={item.name}>
        {item.name}
       </option>))}
      </Form.Select>
     </Col>
    </Row>
    <Modal show={showQR} onHide={handleQRClose} centered size="xl">
     <Modal.Header
      closeButton
      style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
     >
      <Modal.Title className="px-5 py-2">
       Place Order to Trucking companies
      </Modal.Title>
     </Modal.Header>
     <Modal.Body className="modal-body-QR">
      <table
       className="modal-table my-4"
       style={{border: "1px solid rgba(186, 186, 186, 1)"}}
      >
       <thead>
       <tr>
        <th
         className="text-center py-2"
         style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        >
         Order Id
        </th>
        <th
         className="text-center py-2"
         style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        >
         Project
        </th>
        <th
         className="text-center py-2"
         style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        >
         Products
        </th>
        <th
         className="text-center py-2"
         style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        >
         Quantity
        </th>
        <th
         className="text-center py-2"
         style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        >
         Supplier
        </th>
       </tr>
       </thead>
       <tbody>
       <td className="text-center py-3 px-3">{modalQR?.id}</td>
       <td className="text-center py-3 px-3">{modalQR?.project?.id}</td>
       <td className="text-center py-3 px-3">{modalQR !== "" && modalQR?.order_items[0]?.product?.name}</td>
       <td className="text-center py-3 px-3">{modalQR !== "" && modalQR?.order_items[0]?.quantity}</td>
       <td className="text-center py-3 px-3">{modalQR?.supplier?.username}</td>
       </tbody>
      </table>
      <h5>
       <b>Trucking Company</b>
      </h5>
      <Row className="mt-2">
       <Col lg={5}>
        <Row>
         <Col lg={5}>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Label className="m-0 p-0" htmlFor="inputPassword">
            <b>Unit</b>
           </Form.Label>
           <Form.Select
            value={truckingUnit}
            onChange={(e) => setTruckingUnit(e.target.value)}
           >
            <option value="">Select</option>
            <option value="ton">Ton</option>
            <option value="kg">Kg</option>
           </Form.Select>
          </Form.Group>
         </Col>
        </Row>
        <Row>
         <Form.Group className=" mx-1" controlId="formBasicName">
          <Form.Label className="m-0 p-0" htmlFor="inputPassword">
           <b>Company</b>
          </Form.Label>
          <Multiselect
           displayValue="key"
           onKeyPressFn={function noRefCheck() {
           }}
           onRemove={function noRefCheck() {
           }}
           onSearch={function noRefCheck() {
           }}
           onSelect={(e) => setTrucking(e)}
           options={truckingList.map((item) => ({
            id: item.id, key: item.username,
           }))}
           showCheckbox
          />
         </Form.Group>
        </Row>
        <Row>
         <Form.Group className="mx-2" controlId="formBasicCheckbox">
          <input type="checkbox" className="form-input-check"
                 onClick={() => setallTruckingCompany(false)}/>
          <label htmlFor="check" className="form-input-check mx-2">
           Ask for all trucking Co. for quotation
          </label>
         </Form.Group>
        </Row>
        <Row>
         <Form.Group className="mt-3" controlId="formBasicName">
          <Form.Label className="text-start m-0 p-0">
           <b>Pickup Location</b>
          </Form.Label>
          <Form.Control
           type="text"
           value={truckingPickup}
           onChange={(e) => setTruckingPickup(e.target.value)}
           as="textarea"
           rows={4}
          />
         </Form.Group>
        </Row>
       </Col>
       <Col lg={{offset: 1, span: 6}}>
        <Row>
         <Col lg={6}>
          <Form.Label className="m-0 p-0" htmlFor="inputPassword">
           <b>Delivery Date</b>
          </Form.Label>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Control type="date" placeholder="Enter Price"
                         min={new Date().toISOString().split('T')[0]}
                         value={truckingDeliveryDate}
                         onChange={(e) => setTruckingDeliveryDate(e.target.value)}/>
          </Form.Group>
         </Col>
         <Col lg={6}>
          <Form.Label className="m-0 p-0" htmlFor="inputPassword">
           <b>Delivery Time</b>
          </Form.Label>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Control type="time" placeholder="Enter Price"
                         value={truckingDeliveryTime}
                         onChange={(e) => setTruckingDeliveryTime(e.target.value)}/>
          </Form.Group>
         </Col>
        </Row>
        <Row>
         <Form.Group className="mt-3" controlId="formBasicName">
          <Form.Label className="text-start m-0 p-0">
           <b>Delivery Location</b>
          </Form.Label>
          <Form.Control
           type="text"
           value={truckingDelivery}
           onChange={(e) => setTruckingDelivery(e.target.value)}
           as="textarea"
           rows={4}
          />
         </Form.Group>
        </Row>
       </Col>
      </Row>
      <div className="d-flex">
       <Button
        className="px-4 mt-3 mb-5"
        style={{
         border: "none", borderRadius: "20px", backgroundColor: "rgba(52, 168, 83, 1)", color: "#fff",
        }}
        variant="secondary"
        onClick={handleTruckingOrder}
       >
        SUBMIT
       </Button>
       <Button
        className="px-5 mx-3 mt-3 mb-5"
        onClick={handleQRClose}
        style={{
         border: "none", borderRadius: "20px", backgroundColor: "rgba(255, 115, 29, 1)", color: "#fff",
        }}
        variant="secondary"
       >
        CANCEL
       </Button>
       {/* </Col> */}
      </div>
     </Modal.Body>
    </Modal>
    <Modal show={show} onHide={handleClose} centered size="xl">
     <Modal.Header
      closeButton
      style={{color: "#fff", backgroundColor: "rgba(23, 70, 162, 1)"}}
     >
      <Modal.Title className="px-5 py-2">Create New Order</Modal.Title>
     </Modal.Header>
     <Modal.Body style={{backgroundColor: "#fff"}}>
      <Row className="mt-2">
       <Col lg={6}>
        <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
         <b>Project name</b>
        </Form.Label>
        <Form.Select
         value={projectName}
         onChange={(e) => setProjectName(e.target.value)}
        >
         <option value="">Select Project</option>
         {projectList.map((item) => (<option key={item.id} value={item.id}>
          {item.name}
         </option>))}
        </Form.Select>
       </Col>
       <Col lg={6}>
        <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
         <b>Supplier</b>
        </Form.Label>
        <Form.Select
         value={supplier}
         onChange={(e) => setSupplier(e.target.value)}
        >
         <option value="">Select Supplier</option>
         {supplierList?.map((item) => (<option key={item?.name} value={item?.id}>
          {item?.name}
         </option>))}
        </Form.Select>
       </Col>
      </Row>
      <Row className="mt-2">
       <Col lg={6}>
        <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
         <b>Contract Information</b>
        </Form.Label>
        <Col lg={12} className="p-3" style={{border: "1px solid #CED4DA"}}>
         {contractorList?.map((item) => {
          return (<div className="d-flex" key={item?.products?.id}>
           <div className="row d-flex">
            <Col lg={4}>
             <Form.Label className="m-0 p-0"
                         htmlFor="inputPassword5">
              <b className="ms-2">Products</b>
             </Form.Label>
             <Form.Group className=" mx-1" controlId="formBasicName">
              <Form.Control type="text" value={item.name} readOnly
                            style={{border: "none"}}/>
             </Form.Group>
            </Col>
            <Col lg={4}>
             <Form.Label className="m-0 p-0"
                         htmlFor="inputPassword5">
              <b className="ms-2">Quantity</b>
             </Form.Label>
             <Form.Group className=" mx-1" controlId="formBasicName">
              <Form.Control type="text" value={item.quantity}
                            readOnly style={{border: "none"}}/>
             </Form.Group>
            </Col>
            <Col lg={3}>
             <Form.Label className="m-0 p-0"
                         htmlFor="inputPassword5">
              <b className="ms-2">Unit</b>
             </Form.Label>
             <Form.Group className=" mx-1" controlId="formBasicName">
              <Form.Control type="text" value={item.unit} readOnly
                            style={{border: "none"}}/>
             </Form.Group>
            </Col>
           </div>
          </div>)
         })}
        </Col>
       </Col>
       <Col lg={6}>
        <Row>
         <Col lg={4}>
          <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
           <b>Products</b>
          </Form.Label>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Select
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
           >
            <option value="">Product</option>
            {contractorList.map((item) => (<option key={item.name} value={item.id}>
             {item.name}
            </option>))}
           </Form.Select>

          </Form.Group>
         </Col>
         <Col lg={4}>
          <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
           <b>Quantity</b>
          </Form.Label>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Control type="number" value={quantity}
                         onChange={(e) => setQuantity(e.target.value)}/>
          </Form.Group>
         </Col>
         <Col lg={4}>
          <Form.Label className="m-0 p-0" htmlFor="inputPassword5">
           <b>Unit</b>
          </Form.Label>
          <Form.Group className=" mx-1" controlId="formBasicName">
           <Form.Select
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
           >
            <option value="">Unit</option>
            {contractorList.map((item) => (<option key={item.unit} value={item.unit}>
             {item.unit}
            </option>))}
           </Form.Select>
          </Form.Group>
         </Col>

        </Row>

       </Col>
      </Row>
      <Row>
       <Row>
        <Col lg={3}>
         <Form.Label className="m-0 p-0" htmlFor="inputPassword">
          <b>Delivery Date</b>
         </Form.Label>
         <Form.Group className=" mx-1" controlId="formBasicName">
          <Form.Control type="date" min={new Date().toISOString().split('T')[0]}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                        placeholder="Enter Price"/>
         </Form.Group>
        </Col>
        <Col lg={3}>
         <Form.Label className="m-0 p-0" htmlFor="inputPassword">
          <b>Delivery Time</b>
         </Form.Label>
         <Form.Group className=" mx-1" controlId="formBasicName">
          <Form.Control type="time" placeholder="nter Price"/>
         </Form.Group>
        </Col>
       </Row>
      </Row>
      <div className="d-flex">
       <Button
        className="px-4 mt-3 mb-5"
        style={{
         border: "none", borderRadius: "20px", backgroundColor: "rgba(52, 168, 83, 1)", color: "#fff",
        }}
        variant="secondary"
        onClick={handleNewOrder}
       >
        SEND ORDER
       </Button>

       <Button
        className="px-5 mx-3 mt-3 mb-5"
        style={{
         border: "none", borderRadius: "20px", backgroundColor: "rgba(255, 115, 29, 1)", color: "#fff",
        }}
        variant="secondary"
        onClick={handleClose}
       >
        CANCEL
       </Button>
      </div>
     </Modal.Body>
    </Modal>
    <div className="table-container">
     <table
      id="data-table"
      style={{border: "1px solid rgba(186, 186, 186, 1)"}}
     >
      <thead>
      <tr>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Order Id{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Project Id{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Project Name{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Supplier{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Products
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Quote Price{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th
        style={{border: "1px solid rgba(186, 186, 186, 1)"}}
        onClick={() => handleSort("name")}
       >
        Quote Date{" "}
        {sortColumn === "name" && <span>{sortDirection}</span>}
       </th>
       <th colSpan={2} style={{border: "1px solid rgba(186, 186, 186, 1)"}}>Action</th>
      </tr>
      </thead>
      <tbody>
      {contractorOrderList.map((item) => (<React.Fragment key={item.id}>
       <tr onClick={() => handleExpand(item.id)}>
        <td>
         <div
          className="d-flex justify-content-between"
          style={{color: "rgba(35, 104, 238, 1)"}}
         >
          <span className="ms-2">{item.id}</span>
          <span className="expand-trigger me-5">
          {expandedRow === item.id ? "^" : "˅"}
         </span>
         </div>
        </td>
        <td>{item.project.id}</td>
        <td>{item.project.name}</td>
        <td>{item.supplier.username}</td>
        <td>{item.order_items[0].product.name}</td>
        <td>{item.order_items[0].product.price / item.order_items[0].product.quantity * item.order_items[0].quantity}</td>
        <td>{item.delivery_date}</td>
        <td colSpan={2}>
         {expandedRow === item.id || (<>
          <button
           className="px-3"
           style={{
            border: item.status === "ORDER_PLACED" ? "none" : "1px solid #34A853",
            width: 'max-content',
            cursor: "pointer",
            backgroundColor: item.status === "ORDER_PLACED" ? "white" : "#34A853",
            color: item.status === "ORDER_PLACED" ? "#34A853" : "#fff",
            borderRadius: "30px",
           }}
           onClick={() => {
            if (item.status !== "ORDER_PLACED") {
             setShowQR(true);
             setModalQR(item);
            }
           }}
          >
           {item.status === "ORDER_PLACED" ? "Order Placed" : "Place Order"}
          </button>
          <Button className='chatcss' type="submit"  href="/chat">Chat</Button>
          {/* <button
           className="ms-2 px-3"
           style={{
            border: "none", cursor: "pointer", backgroundColor: "#2F80ED", color: "#fff", borderRadius: "30px",
           }}
          >
           Chat
          </button> */}
         </>)}
        </td>
       </tr>
       {expandedRow === item.id && (<React.Fragment
        className="m-0 p-0"
        style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
       >
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>Project Id</td>

         <td colSpan={2}>
          <b>{item.id}</b>
         </td>

         <td colSpan={2}>
          <b>Project Description</b>
         </td>

         <td>
          <b>Products</b>
         </td>

         <td>
          <b>Quote</b>
         </td>
         <td></td>
         <td>
          {/* <button
           className="ms-5 px-3 mt-3"
           style={{
            border: "none", cursor: "pointer", backgroundColor: "#2F80ED", color: "#fff", borderRadius: "30px",
           }}
          >
           Chat
          </button> */}
           <Button className='chatcss' type="submit"  href="/chat">Chat</Button>
         </td>
        </tr>

        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>Project Name</td>

         <td colSpan={2}>
          <b>{item.project.name}</b>
         </td>

         <td rowSpan={2} colSpan={2}>

         </td>

         <td>
          <b>{item.order_items[0].product.name}- {item.order_items[0].product.quantity} {item.order_items[0].product.unit}</b>{" "}
         </td>

         <td>
          <b>{item.order_items[0].product.price}{" "}{item.order_items[0].product.currency}</b>
         </td>
         <td></td>
         <td></td>
        </tr>

        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>Supplier</td>

         <td>
          <b>{item.supplier.username}</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>

        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>Quote Date</td>

         <td colSpan={2}>
          <b>{item.created_at.slice(0, 10)}</b>
         </td>
         <td colSpan={2}>
          <b>Term for Suppliers</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>

        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>Shipment Date</td>

         <td colSpan={2}>
          <b>{item.delivery_date}</b>
         </td>
         <td rowSpan={2} colSpan={2}>

         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>Truck Detail:</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>Company</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>Pickup Date</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>Drop off Date</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>Price</b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr
         style={{backgroundColor: "rgba(245, 245, 245, 1)"}}
        >
         <td>
          <b>BQL</b>
         </td>
         <td>
          <b></b>
         </td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
        <tr colSpan={2} style={{backgroundColor: "rgba(245, 245, 245, 1)"}}>
         <button
          className="ms-3 px-3 my-3"
          style={{
           border: item.status === "ORDER_PLACED" ? "none" : "1px solid #34A853",
           width: 'max-content',
           cursor: "pointer",
           backgroundColor: item.status === "ORDER_PLACED" ? "white" : "#34A853",
           color: item.status === "ORDER_PLACED" ? "#34A853" : "#fff",
           borderRadius: "30px",
          }}
          onClick={() => {
           if (item.status !== "ORDER_PLACED") {
            setShowQR(true);
            setModalQR(item);
           }
          }}
         >
          {item.status === "ORDER_PLACED" ? "Order Placed" : "Place Order"}
         </button>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
        </tr>
       </React.Fragment>)}
      </React.Fragment>))}
      </tbody>
     </table>
    </div>
   </div>
  </div>
 </>);
};

export default ContractorOrders;
