import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Container, Image, Modal, Button, Card, Form } from 'react-bootstrap';
import Logo from '../../assets/Images/logo.png'
import tick from '../../assets/Images/tick.svg'
import dot from '../../assets/Images/dot.svg'
import Arrow from '../../assets/Images/Arrow.svg'
import lock from '../../assets/Images/lock.svg'
import user from '../../assets/Images/user.svg'
import notification from '../../assets/Images/notification.svg'
import './Header.css'
import '../../style.css'
import { useSelector, useDispatch } from "react-redux"
import { notificationList, togglenotification } from '../../redux/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Header = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalItems, setmodalItems] = useState('');

  const [showmaincontainer, setShowmaincontainer] = useState(true);
  const [setting, setSetting] = useState(false);
  // const isAuthenticated = useSelector(selectIsAuthenticated);
  const [readitem, setreaditem] = useState(true);
  const isAuthenticated = localStorage.getItem('isAuthenticated');//useSelector(selectIsAuthenticated);
  const role = localStorage.getItem('role');
  useEffect(() => {

  }, [isAuthenticated])

  useEffect(() => {
    dispatch(notificationList())
      .then((result) => {

        const data = result.payload.results;
        const filteredItems = data.filter(item => item.is_read != readitem);
        setmodalItems(data);

      })
      .catch((errordata) => {

      });
  }, [])

  const containerClose = () => {
    setShowmaincontainer(false);
    setSetting(true);
  }


  const backbutton = () => {
    setShowmaincontainer(true);
    setSetting(false);
  }

  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  const notificationItem = [
    { id: 1, title: 'Daily' },
    { id: 1, title: 'Weekly' },
    { id: 1, title: 'Monthly' }
  ]


  const [isChecked, setIsChecked] = useState(false);

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
    const body = {
      toggle: !isChecked
    }

    dispatch(togglenotification(body))
      .then((result) => {

      })
      .catch((errordata) => {

      });



  };
  const [marked, setMarked] = useState('');
  const handleCheckboxChange = (itemid) => {
    setMarked(itemid);
  };

  const onClickMarkread = (itemid) => {
    //https://cold-hat-40370.botics.co//notification/mark-read/36

    let config = {
      method: "PUT",
      url: `https://cold-hat-40370.botics.co//notification/mark-read/${itemid}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${localStorage.getItem('token')}`
      },
    }

    axios
      .request(config)
      .then(response => {

        dispatch(notificationList())
          .then((result) => {
            setmodalItems([])
            const data = result.payload.results;
            // const filteredItems = data.filter(item => item.is_read != readitem);
            setmodalItems(data);
          })
          .catch((errordata) => {

          });

        // toast.success('Notification marked as read', {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 2000,
        //   hideProgressBar: true,
        // });

        setShow(false)

      })
      .catch(error => {

      })

  }

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="headercss">
        <Container>
          <ToastContainer />
          <Navbar.Brand className="navtext" href="#home"><Image variant="top" src={Logo} style={{ width: '60px' }} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {role != 'supplier' && role != 'contractor' && role != 'trucking' ? <>
              <Nav className="mx-auto">
                <Nav.Link className="mx-3 navtext" href="#home">Home</Nav.Link>
                <Nav.Link className="mx-3 navtext" href="#home">About Us</Nav.Link>
                <Nav.Link className="mx-3 navtext" href="#home">FAQ</Nav.Link>

              </Nav> </> : <></>}

            {isAuthenticated && role === 'supplier' && role != '' && role != 'undefined' ? <>
              <Nav className="mx-auto">

                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Dashboard" onClick={() => navigate("/quotationDashboard")}>Dashboard</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#quotes" onClick={() => navigate("/quotaiondetails")}>Quotes</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#schedules" onClick={() => navigate("/schedulesupplier")} >Schedules</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#invoices">Invoices</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#bols">BOLs</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Orders" onClick={() => navigate("/ordersdashboard")}>Orders</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#contracts" onClick={() => navigate("/contractdashboard")}>Contracts</Nav.Link>
              </Nav> </> : <>
            </>}

            {isAuthenticated && role === 'contractor' && role != '' && role != 'undefined' ? <>
              <Nav className="mx-auto">

                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Dashboard" onClick={() => navigate("/contractorDashboard")}>Dashboard</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#quotes" onClick={() => navigate("/contractorquotaiondashboard")}>Quotes</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Orders" onClick={() => navigate("/contractororders")}>Orders</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#invoices" onClick={() => navigate("/projects")}>Projects</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#schedules" onClick={() => navigate("/schedulecontractor")}>Schedules</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#contracts" onClick={() => navigate("/ContractorContract")}>Contracts</Nav.Link>
              </Nav> </> : <>
            </>}

            {isAuthenticated && role === 'trucking' && role != '' && role != 'undefined' ? <>
              <Nav className="mx-auto">
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Dashboard" onClick={() => navigate("/truckingdashboard")}>Dashboard</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#Orders" onClick={() => navigate("/truckingcompanyorder")}>Orders</Nav.Link>
                <Nav.Link activeclassname="active" className="mx-3 navtext1" href="#schedules">Schedule</Nav.Link>

              </Nav> </> : <>
            </>}

            {isAuthenticated && role && role != 'null' && role != 'undefined' && <div className="App p-4">
              <div className="notificationcss">
                <Image src={notification} alt="Image" onClick={modalShow} className='image' />
                {modalItems.length > 0 && <span className="e-badge e-badge-info e-badge-notification e-badge-overlap bagecount" >{modalItems.length}</span>}
              </div>
              <Modal show={show} onHide={modalClose} className="modalnotification">
                <Modal.Body className="modalcss">
                  {showmaincontainer ?
                    <div className="modalcontainer " >
                      <div className="modalHader">
                        <p className="notificationtext">Notifications</p>
                        {/* <p className="markread " onClick={()=>onClickMarkread()}><Image src={tick} alt="Image" className="markimage" />Mark as read</p> */}
                        <p className="markread " ><Image src={tick} alt="Image" className="markimage" />Mark as read</p>
                      </div>
                      {modalItems.length > 0 && modalItems.map((item) => (<>
                        <span className={item.is_read === true ? "clicked" : "hoverdata"} onClick={() => onClickMarkread(item.id)}>
                          <span className="lielement">
                            <Image src={dot} alt="Image" className='markimage' />
                            {/* <Form.Check
                            className='markimage'
                              type="checkbox"
                              id={`checkbox-${item.id}`}
                              onChange={() => handleCheckboxChange(item.id)}
                            /> */}
                            <div className="lielementText" >
                              <span >{item.message}</span>
                              <span style={{ fontSize: 12 }}>{item.created}</span>
                            </div>
                            <Image src={lock} alt="Image" className='userimage' />
                          </span>
                        </span>
                      </>))}
                      <div className="modalHader" style={{ marginLeft: "5%", marginTop: "5%" }}>
                        <p className="settings"> View all Notifications</p>
                        <p className="settings" onClick={containerClose}>Notification Settings</p>
                      </div>
                    </div> : null}
                  {setting ?
                    <div >
                      <Modal.Header closeButton>
                        <Image src={Arrow} alt="Image" style={{ width: '15px', cursor: 'pointer' }} onClick={backbutton} />
                        <Modal.Title className="notificationHeader">  Notification Settings </Modal.Title>
                      </Modal.Header>

                      <span className="notsetting">
                        <span className="notsettingText">
                          <span >App Notifications</span>
                        </span>
                        <Form.Check
                          type="switch"
                          id="toggle-switch"
                          checked={isChecked}
                          onChange={handleToggleChange}
                        />
                      </span>
                      <span className="notsetting">
                        <span className="notsettingText">
                          <span >Email Notifications</span>
                        </span>
                        <Form.Check
                          type="switch"
                          id="toggle-switch"
                        />
                      </span>
                      {/* <span className="notsetting">
                        <span className="notsettingText">
                          <span >Set Notification frequency</span>
                        </span>
                        <select name="cars" id="cars" className="selectcss">
                          {notificationItem.map((item) => (<>
                            <option value={item.id}>{item.title}</option>
                          </>))}
                        </select>
                      </span> */}
                      <div className="modalHader" style={{ marginTop: "18%" }}>
                        <button type="button" className="backbtn" onClick={backbutton}>Back</button>
                        <button type="button" className="donebtn" onClick={modalClose}>Done</button>
                      </div>
                    </div> : null}
                </Modal.Body>
              </Modal>
            </div>}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
